import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoX1 from "../assets/images/logo_x1.png";
import SnackBlack from "../assets/images/snack_black.png";
import SnackYellow from "../assets/images/snack_yellow.png";
import CloudFooter from "../assets/images/cloud_footer.png";
import Emo from "../assets/images/emo.png";
import EmoTitle from "../assets/images/emo_title.png";
import {
  Box,
  Button,
  Container,
  Grid,
  Rating,
  Typography,
  styled,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

const CardReview = styled(Grid)(({ theme }) => ({
  border: "4px solid #ED57F0",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "1",
  margin: "24px 0",
  backgroundColor: "#FFF",
  padding: "12px 0",
  width: "100%",
  height: "100%",
  // [theme.breakpoints.up("xs")]: {
  //     width: "80%",
  // },
  // [theme.breakpoints.up("sm")]: {
  //     width: "30%",
  // },
}));
const NameProd = styled("p")(({ theme }) => ({
  color: "#56331F",
  margin: "0",
  [theme.breakpoints.up("xs")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "32px",
  },
}));

const Text20px = styled("p")(({ theme }) => ({
  color: "#ED57F0",
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
}));

const Text24px = styled("p")(({ theme }) => ({
  color: "#B36E29",
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
  },
  textAlign: "center",
}));

export default function Review({name,text,rate}:{name:any,text:any,rate:any}) {
  return (
   
      <CardReview container spacing={0}>
        <Grid item xs={12} display={`flex`} justifyContent={`center`}>
          <NameProd sx={{ textAlign: "center" }}>{name}</NameProd>
        </Grid>
        <Grid item xs={12} display={`flex`} justifyContent={`center`}>
          <Text20px>Thailand,</Text20px>
        </Grid>
        <Grid item xs={12} display={`flex`} justifyContent={`center`}>
          <Text24px>{text}</Text24px>
        </Grid>
        <Grid item xs={12} display={`flex`} justifyContent={`center`}>
          <Rating name="simple-controlled" value={rate} readOnly />
        </Grid>
      </CardReview>
    
  );
}
