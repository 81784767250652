import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  faTachometerAlt,
  faChartLine,
  faFileAlt,
  faBox,
  faBriefcase,
  faCogs,
  faWarehouse,
  faUsersCog,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/images/logo_x1.png";
import { styled } from "@mui/material";

const Logo = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.up("xs")]: {
    width: "100px",
    height: "auto",
  },
  [theme.breakpoints.up("sm")]: {
    width: "160px",
    height: "auto",
  },
}));

const NavbarContainer = styled("div")<any>(({ theme, isOpen }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 50,
  backgroundColor: "#fff",
  color: "#000",
  display: "flex",
  flexDirection: "column",
  borderRight: "1px solid #b1b1b1",
  width: "16rem", // 64 * 0.25 rem
  minHeight: "100vh",
  transform: isOpen ? "translateX(0)" : "translateX(-100%)",
  transition: "transform 0.3s ease-in-out",
  [theme.breakpoints.up("md")]: {
    position: "relative",
    transform: "translateX(0)",
  },
}));

const NavButton = styled("button")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  background: "none",
  border: "none",
  cursor: "pointer",
  color: "#000",
  fontSize: "1.5rem",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const NavLink = styled("a")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  color: "#000",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "#1f8cf1",
    color: "#fff",
    transition: "background-color 0.1s",
  },
}));

const ToggleButton = styled("button")(({ theme }) => ({
  display: "block",
  padding: theme.spacing(2),
  backgroundColor: "#1f8cf1",

  color: "#000",
  fontSize: "1.875rem", // 3xl
  fontWeight: "bold",
  border: "none",
  cursor: "pointer",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {!isOpen && <ToggleButton onClick={toggleNavbar}>{">"}</ToggleButton>}
      <NavbarContainer isOpen={isOpen}>
        {isOpen && (
          <NavButton onClick={toggleNavbar}>
            <FontAwesomeIcon icon={faTimes} />
          </NavButton>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
          }}
        >
          <Logo src={logo} />
        </div>
        <nav>
          <NavLink href="/admin">
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.875 0H14.625C14.3266 0 14.0405 0.117115 13.8295 0.325582C13.6185 0.534048 13.5 0.816789 13.5 1.11161V20H18V1.11161C18 0.816789 17.8815 0.534048 17.6705 0.325582C17.4595 0.117115 17.1734 0 16.875 0ZM10.125 6.66963H7.875C7.57663 6.66963 7.29048 6.78675 7.0795 6.99521C6.86853 7.20368 6.75 7.48642 6.75 7.78124V20H11.25V7.78124C11.25 7.48642 11.1315 7.20368 10.9205 6.99521C10.7095 6.78675 10.4234 6.66963 10.125 6.66963ZM3.375 13.3393H1.125C0.826631 13.3393 0.540483 13.4564 0.329505 13.6648C0.118526 13.8733 0 14.1561 0 14.4509V20H4.5V14.4509C4.5 14.1561 4.38147 13.8733 4.1705 13.6648C3.95952 13.4564 3.67337 13.3393 3.375 13.3393Z"
                fill="#333"
              />
            </svg>
            <span style={{ marginLeft: "1.25rem" }}>Dashboard</span>
          </NavLink>
          <NavLink href="/admin/order">
            <svg
              width="20"
              height="28"
              viewBox="0 0 20 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7.5 5.66663H15V7.33329H7.5V5.66663Z" fill="#333" />
              <path d="M7.5 9H15V10.6667H7.5V9Z" fill="#333" />
              <path d="M7.5 12.3334H15V14H7.5V12.3334Z" fill="#333" />
              <path d="M7.5 15.6666H15V17.3333H7.5V15.6666Z" fill="#333" />
              <path d="M7.5 19H15V20.6667H7.5V19Z" fill="#333" />
              <path
                d="M4.16675 5.66663H5.83341V7.33329H4.16675V5.66663Z"
                fill="#333"
              />
              <path d="M4.16675 9H5.83341V10.6667H4.16675V9Z" fill="#333" />
              <path
                d="M4.16675 12.3334H5.83341V14H4.16675V12.3334Z"
                fill="#333"
              />
              <path
                d="M4.16675 15.6666H5.83341V17.3333H4.16675V15.6666Z"
                fill="#333"
              />
              <path d="M4.16675 19H5.83341V20.6667H4.16675V19Z" fill="#333" />
              <path
                d="M18.3333 0.666626H1.66667C1.22464 0.666626 0.800716 0.842221 0.488155 1.15478C0.175595 1.46734 0 1.89127 0 2.33329V25.6666C0 26.1087 0.175595 26.5326 0.488155 26.8451C0.800716 27.1577 1.22464 27.3333 1.66667 27.3333H18.3333C18.7754 27.3333 19.1993 27.1577 19.5118 26.8451C19.8244 26.5326 20 26.1087 20 25.6666V2.33329C20 1.89127 19.8244 1.46734 19.5118 1.15478C19.1993 0.842221 18.7754 0.666626 18.3333 0.666626ZM18.3333 25.6666H1.66667V2.33329H18.3333V25.6666Z"
                fill="#333"
              />
            </svg>
            <span style={{ marginLeft: "1rem" }}>รายการสั่งซื้อ</span>
          </NavLink>
          {/* <NavLink href="/parcel">
            <svg
              width="26"
              height="26"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.725 6.80762L15.5975 1.43062C14.665 0.940618 13.335 0.940618 12.4025 1.43062L2.275 6.80762C1.4025 7.26012 0.875 8.15762 0.875 9.12012L0.875 18.8701C0.875 19.8326 1.4025 20.7301 2.275 21.1826L12.4025 26.5596C13.335 27.0496 14.665 27.0496 15.5975 26.5596L25.725 21.1826C26.5975 20.7301 27.125 19.8326 27.125 18.8701V9.12012C27.125 8.15762 26.5975 7.26012 25.725 6.80762ZM14 15.0801L3.92 9.12262L8.7175 6.55762L18.08 11.6651L14 15.0801ZM13.86 2.87762C13.94 2.83512 14.06 2.83512 14.14 2.87762L18.9175 5.44262L14 8.81012L9.0825 5.44262L13.86 2.87762ZM2.9175 18.6251C2.875 18.5826 2.875 18.5426 2.875 18.5001V10.2226L7.875 13.1326V18.9301L2.9175 18.6251ZM4.4825 20.4426L9.625 20.7901C9.7725 20.7971 9.8975 20.9101 9.8975 21.0601L10 25.1301C9.955 25.1026 9.91 25.0751 9.8825 25.0501L4.4825 22.1201C4.1975 21.9626 4.125 21.6176 4.4825 20.4426ZM18.4 25.0201C18.37 25.0451 18.32 25.0726 18.275 25.1026L18.3975 21.0626C18.4 20.9101 18.525 20.7971 18.6725 20.7901L23.815 20.4426C24.1825 21.6176 24.11 21.9626 23.815 22.1201L18.4 25.0201ZM21.125 18.9301V13.1326L26.125 10.2226V18.5001C26.125 18.5426 26.125 18.5826 26.0825 18.6251L21.125 18.9301Z"
                fill="white"
              />
            </svg>
            <span style={{ marginLeft: "1rem" }}>พัสดุ</span>
          </NavLink> */}
          <NavLink href="/admin/product">
            <svg
              width="26"
              height="26"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 27L25.7448 20.9218C26.2012 20.6849 26.4294 20.5679 26.5956 20.3932C26.7427 20.2398 26.8541 20.0558 26.922 19.8544C27 19.6247 27 19.3691 27 18.8548V7.54781M14 27L2.25522 20.9218C1.79878 20.6849 1.57056 20.5679 1.40444 20.3932C1.25765 20.2395 1.14624 20.0556 1.078 19.8544C1 19.6247 1 19.3676 1 18.8519V7.54781M14 27V13.9091M27 7.54781L14 13.9091M27 7.54781L15.0544 1.36564C14.6688 1.1663 14.4767 1.06519 14.273 1.02619C14.0927 0.991269 13.9073 0.991269 13.727 1.02619C13.5248 1.06519 13.3312 1.1663 12.9441 1.36564L1 7.54781M1 7.54781L14 13.9091"
                stroke="#333"
                stroke-width="1.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span style={{ marginLeft: "1rem" }}>สินค้า</span>
          </NavLink>
          <NavLink href="/admin/account">
            <svg
              width="22"
              height="22"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 10C8.67392 10 7.40215 9.47322 6.46447 8.53553C5.52678 7.59785 5 6.32608 5 5C5 3.67392 5.52678 2.40215 6.46447 1.46447C7.40215 0.526784 8.67392 0 10 0C11.3261 0 12.5979 0.526784 13.5355 1.46447C14.4732 2.40215 15 3.67392 15 5C15 6.32608 14.4732 7.59785 13.5355 8.53553C12.5979 9.47322 11.3261 10 10 10ZM10 13.75C13.9825 13.75 17.5562 14.4637 20 17.5788V20H0V17.5788C2.44375 14.4625 6.0175 13.75 10 13.75Z"
                fill="#333"
              />
            </svg>
            <span style={{ marginLeft: "1rem" }}>บัญชีผู้ใช้งาน</span>
          </NavLink>
          <NavLink href="/admin/live">
            <svg
              width="22"
              height="22"
              viewBox="0 0 576 512"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"
                fill="#333"
              />
            </svg>
            <span style={{ marginLeft: "1rem" }}>LIVE</span>
          </NavLink>
          <NavLink href="/admin/report">
            <svg
              fill="#000000"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 350.488 350.487"
            >
              <path
                d="M345.348,2.566h-5.134H10.274h-5.14H0v345.354h350.488V2.566H345.348z M67.951,19.305v43.367H21.873V19.305H67.951z
			 M67.951,84.544v43.964H21.873V84.544H67.951z M67.951,150.369v46.225H21.873v-46.225H67.951z M67.951,218.451v40.809H21.873
			v-40.809H67.951z M67.951,281.129v44.916H21.873v-44.916H67.951z M328.607,19.305v43.367H89.811V19.305H328.607z M328.607,84.544
			v43.964H89.811V84.544H328.607z M328.607,150.369v46.225H89.811v-46.225H328.607z M328.607,218.451v40.809H89.811v-40.809H328.607
			z M328.607,281.129v44.916H89.811v-44.916H328.607z"
              />
            </svg>

            <span style={{ marginLeft: "1rem" }}>REPORT</span>
          </NavLink>
        </nav>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
