import Product from "../assets/images/ProductDetail.jpg";
import { useNavigate, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LogoX1 from "../assets/images/logo_x1.png";
import SnackBlack from "../assets/images/snack_black.png";
import SnackYellow from "../assets/images/snack_yellow.png";
import Emo from "../assets/images/emo.png";
import EmoTitle from "../assets/images/emo_title.png";
import {
  Box,
  Button,
  Container,
  Grid,
  Rating,
  Typography,
  styled,
} from "@mui/material";
import cup from "../assets/images/cup.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import snack from "../assets/images/snack_black90deg.png";
import snack2 from "../assets/images/snack2.png";
import bean from "../assets/images/smallbean.png";
import beanlong from "../assets/images/beanbig.png";
import star from "../assets/images/star.png";
import ProductAbout from "../assets/images/detailproduct.jpg";
import facebook from "../assets/images/facebook.png";
import tiktk from "../assets/images/tiktok.png";
import ig from "../assets/images/ig.png";
import youtube from "../assets/images/youtube.png";
import tree from "../assets/images/tree.png";
import Langmai from "../assets/images/langmai.png";
import { gauvainAPI } from "../api/gauvain";
import shopDetail from "../assets/images/shopdetail.jpg";
import shopDetail1 from "../assets/images/ProductDetail5.jpg";
import shopDetail2 from "../assets/images/ProductDetail3.jpg";
import shopDetail3 from "../assets/images/shopdetailbanana.jpg";
import shopDetail4 from "../assets/images/shopdetailbanana1.jpg";
import shopDetail5 from "../assets/images/shopdetailbanana2.jpg";
import shopDetail6 from "../assets/images/shopdetailbanana3.jpg";
import shopDetail7 from "../assets/images/shopdetailbanana4.jpg";
import shopDetail8 from "../assets/images/ProductDetail1.jpg";
import shopDetail9 from "../assets/images/ProductDetail2.jpg";
import shopDetailX from "../assets/images/ProductDetail4.jpg";
import shopDetail11 from "../assets/images/ProductDetail7.jpg";

import Video1 from "../assets/videoshop/vdo1.mp4";
import Video2 from "../assets/videoshop/vdo2.mp4";
import Video3 from "../assets/videoshop/vdo3.mp4";
import Video4 from "../assets/videoshop/vdo4.mp4";
import Video5 from "../assets/videoshop/vdo5.mp4";
import Video6 from "../assets/videoshop/vdo6.mp4";
import Video7 from "../assets/videoshop/vdo7.mp4";
import Video8 from "../assets/videoshop/vdo8.mp4";

type Props = {};
const BuyButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "35%",
  left: "35%",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    padding: "0",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
    padding: "6px 34px",
  },
  border: "1px solid #B36E29",
  color: "#FFF",
  backgroundColor: "#FFB600",
  borderRadius: "24px",
  width: "30%",
}));
const Text64px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "56px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "64px",
  },
  fontWeight: "500",
  padding: "12px 0",
}));
const Imgitem = styled("img")(() => ({
  width: "30%",
}));
const Imgtext = styled("img")(() => ({
  width: "50%",
}));
const ImgIcon = styled("img")(({ theme }) => ({
  backgroundColor: "#FFF",
  borderRadius: "50%",
  width: "100%",
  cursor: "pointer",
}));
const ItemContainer = styled(Grid)(() => ({
  width: "80%",
  margin: "0 auto",
  justifyContent: "space-around",
}));
const ShopContainer = styled(Grid)(() => ({
  width: "100%",
  position: "relative",
  padding: "32px 0",
}));
const AboutContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  position: "relative",
  margin: "0 auto",
  [theme.breakpoints.up("xs")]: {
    flexDirection: "column-reverse",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));
const Text90px = styled("p")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "50px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "60px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "90px",
  },
  fontWeight: "500",
  padding: "12px 0",
  color: "#FF9700",
  textAlign: "center",
}));
const Card = styled(Grid)(({ theme }) => ({
  border: "1px solid #DADADA",
  borderRadius: "12px",
  justifyContent: "center",
  position: "relative",
  alignItems: "center",
  overflow: "hidden",
  zIndex: "1",
  margin: "24px 0",
  [theme.breakpoints.up("xs")]: {
    height: "338px",
    width: "247px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "365px",
    width: "247px",
  },
  [theme.breakpoints.up("md")]: {
    height: "465px",
    width: "287px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "565px",
    width: "387px",
  },
  [theme.breakpoints.up("xl")]: {
    height: "665px",
    width: "487px",
  },
}));
const ProdImg = styled("img")(() => ({
  width: "80%",
  zIndex: "1",
}));
const Beanimg = styled("img")(() => ({
  width: "18%",
  position: "absolute",
  left: "0",
  top: "50%",
  zIndex: "0",
}));
const Beanimg3 = styled("img")(() => ({
  width: "15%",
  position: "absolute",
  left: "0",
  top: "15%",
  zIndex: "0",
}));
const Beanimg1 = styled("img")(() => ({
  width: "29%",
  position: "absolute",
  right: "0",
  top: "-3%",
  rotate: "80deg",
}));
const Beanimg2 = styled("img")(() => ({
  width: "19%",
  position: "absolute",
  right: "-2%",
  top: "29%",
  rotate: "100deg",
}));
const Beanimglong = styled("img")(() => ({
  width: "35%",
  position: "absolute",
  right: "-5%",
  top: "60%",
  rotate: "265deg",
}));
const StarBottom = styled("img")(() => ({
  width: "5%",
  position: "absolute",
  left: "5%",
  bottom: "5%",
  zIndex: "0",
}));
const StarTop = styled("img")(() => ({
  width: "5%",
  position: "absolute",
  top: "0",
  zIndex: "0",
  right: "8%",
}));
const NameProd = styled("p")(({ theme }) => ({
  color: "#56331F",
  margin: "0",
  [theme.breakpoints.up("xs")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "32px",
  },
}));
const Text24px = styled("p")(({ theme }) => ({
  color: "#B36E29",
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
  },
  textAlign: "center",
}));
const ProductAboutImg = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "30%",
  },
}));
const AboutUs = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "70%",
  },
  alignItems: "center",
  padding: "24px 0",
}));
const Social = styled("img")((theme) => ({
  width: "5%",
  marginTop: "6px",
}));
// const TextProd = styled("h1")(({ theme }) => ({
//   [theme.breakpoints.up("xs")]: {
//     fontSize: "15px",
//     left: "15%",
//     bottom: "-10%",
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "30px",
//     left: "18%",
//     bottom: "-12%",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "40px",

//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "50px",
//     left: "15%",

//   },
//   fontWeight: "700",
//   position: 'absolute',
//   color: "#454545",
//   rotate: "8deg",
// }));

const TextProd = styled("h1")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    left: "15%",
    top: "55%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
    left: "15%",
    top: "46%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "50px",
  },
  fontWeight: "700",
  position: "absolute",
  color: "#454545",
  rotate: "8deg",
}));

const TextProd2 = styled("h1")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    right: "8%",
    top: "55%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
    right: "8%",
    top: "45%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "50px",
  },
  fontWeight: "700",
  position: "absolute",
  color: "#454545",
  rotate: "343deg",
}));
const Cup = styled("img")((theme) => ({
  width: "50%",
  zIndex: "1",
}));
const TreeLeft = styled("img")((theme) => ({
  width: "30%",
  zIndex: "2",
  position: "absolute",
  left: "-15%",
  bottom: "-5%",
  rotate: "45deg",
}));
const TreeRigth = styled("img")((theme) => ({
  width: "30%",
  zIndex: "2",
  position: "absolute",
  right: "-12%",
  bottom: "11%",
  rotate: "320deg",
}));
const Lungmai = styled("img")((theme) => ({
  width: "25%",
  zIndex: "2",
  position: "absolute",
  right: "-5%",
  bottom: "-9%",
}));
const H1 = styled("h1")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("xs")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "32px",
  },
}));
const ReviewVideoContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "#FFB600",
  justifyContent: "space-around",
  padding: "8% 0",
}));
const ReviewVideoCard = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "82%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "22%",
  },
}));

function Shop({}: Props) {
  const navigate = useNavigate();
  const [productList, setProductList] = useState<any>([]);
  useEffect(() => {
    (async () => {
      try {
        const api = new gauvainAPI();
        const allProductsTest: any = await api.getAllProducts();

        if (
          allProductsTest.data.status_code !== undefined &&
          allProductsTest.data.status_code === 200
        ) {
          const temp_productList: any = [];

          await Promise.all(
            allProductsTest.data.products.map((product: any) => {
              let temp_product = product;
              temp_product.quantity = 0;

              temp_productList.push(temp_product);
            })
          );

          setProductList(temp_productList);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Grid sx={{ overflow: "hidden" }}>
      <Grid container>
        <img src={Product} alt="product" style={{ width: "100%" }} />
      </Grid>

 

      <ShopContainer className="BGPaper">
        <Text90px
          className="Franchise"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          More to Discover
        </Text90px>
        <ItemContainer container>
          {productList.length > 0 &&
            productList.map((product: any, index: number) => {
              return (
                <Card
                  className="card"
                  container
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  key={index}
                >
                  <ProdImg
                    src={product.image.replace(
                      `{api_path}`,
                      process.env.REACT_APP_API_URL
                    )}
                  />
                  <NameProd>{product.name}</NameProd>
                  <Button
                    variant="contained"
                    sx={{
                      width: "80%",
                      margin: "0 auto",
                      borderRadius: "24px",
                      color: "#FFF",
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/checkout");
                    }}
                  >
                    สั่งซื้อ
                  </Button>
                  <Beanimg
                    src={bean}
                    className="Beanimg"
                    sx={{ transition: "0.5s", opacity: "0" }}
                  />
                  <Beanimg1
                    src={bean}
                    className="Beanimg"
                    sx={{ transition: "0.5s", opacity: "0" }}
                  />
                  <Beanimglong
                    src={beanlong}
                    className="Beanimg"
                    sx={{ transition: "0.5s", opacity: "0" }}
                  />
                  <Beanimg2
                    src={bean}
                    className="Beanimg"
                    sx={{ transition: "0.5s", opacity: "0" }}
                  />
                  <Beanimg3
                    src={bean}
                    className="Beanimg"
                    sx={{ transition: "0.5s", opacity: "0" }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      height: "50%",
                      width: "100%",
                      backgroundColor:
                        product.background_color !== undefined &&
                        product.background_color !== null
                          ? product.background_color
                          : "#8a5834",
                      zIndex: "-1",
                    }}
                  ></div>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "0",
                      height: "50%",
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                      zIndex: "-1",
                    }}
                  ></div>
                </Card>
              );
            })}
          {/* <Card container className='Card' data-aos="fade-left" data-aos-duration="1000">
            <ProdImg src={snack} />
            <NameProd >รสกาแฟเอสเปรสโซ่</NameProd>
            <Button variant="contained" onClick={() => navigate('/productdetail/1')} sx={{ width: "80%", margin: "0 auto", borderRadius: "24px", color: "#FFF" }}>
              สั่งซื้อ
            </Button>
            <Beanimg className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
            <Beanimg1 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
            <Beanimglong className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={beanlong} />
            <Beanimg2 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
            <Beanimg3 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />

            <div style={{
              position: "absolute",
              top: "0",
              height: "50%",
              width: "100%",
              backgroundColor: "#FFE448",
              zIndex: "-1"
            }}></div>

            <div style={{
              position: "absolute",
              bottom: "0",
              height: "50%",
              width: "100%",
              backgroundColor: "#FFFFFF",
              zIndex: "-1"

            }}></div>
          </Card>
          <Card container className='Card' data-aos="fade-left" data-aos-duration="1000">
            <ProdImg src={snack2} />
            <NameProd >รสช็อกโกแลตกล้วยหอม</NameProd>
            <Button variant="contained" onClick={() => navigate('/productdetail/2')} sx={{ width: "80%", margin: "0 auto", borderRadius: "24px", color: "#FFF" }}>
              สั่งซื้อ
            </Button>
            <Beanimg className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
            <Beanimg1 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
            <Beanimglong className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={beanlong} />
            <Beanimg2 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
            <Beanimg3 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />

            <div style={{
              position: "absolute",
              top: "0",
              height: "50%",
              width: "100%",
              backgroundColor: "#FFE448",
              zIndex: "-1"
            }}></div>

            <div style={{
              position: "absolute",
              bottom: "0",
              height: "50%",
              width: "100%",
              backgroundColor: "#FFFFFF",
              zIndex: "-1"

            }}></div>
          </Card> */}
        </ItemContainer>
        <StarBottom className="rotatestar" src={star} />
        <StarTop className="rotatestar" src={star} />
      </ShopContainer>

      {/* vdo */}

      <ReviewVideoContainer container>
        <ReviewVideoCard data-aos="fade-up" data-aos-duration="1300">
          {/* <ReviewVideoImg src={reviewvideo} /> */}
          <div style={{ width: "100%", position: "relative", height: "730px" }}>
            <iframe
              src="https://www.youtube.com/embed/SYfuSnwKhsc"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
          <p style={{ textAlign: "center" }}>FOR A GOOD TIME FOLLOW</p>
          <H1
            style={{
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
            }}
          >
            @GAUVAIN
          </H1>
        </ReviewVideoCard>

        <ReviewVideoCard
          sx={{ marginTop: "10%", display: { xs: "block", sm: "block" } }}
          data-aos="fade-down"
          data-aos-duration="1300"
        >
          {/* <video controls style={{ width: "100%" }}>
                        <source src={Video2} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
          <div style={{ width: "100%", position: "relative", height: "730px" }}>
            <iframe
              src="https://www.youtube.com/embed/DNJF6WSr-rk"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </ReviewVideoCard>
        <ReviewVideoCard
          sx={{ display: { xs: "block", sm: "block" } }}
          data-aos="fade-up"
          data-aos-duration="1300"
        >
          {/* <ReviewVideoImg src={reviewvideo} /> */}
          {/* <video controls style={{ width: "100%" }}>
                        <source src={Video3} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
          <div style={{ width: "100%", position: "relative", height: "730px" }}>
            <iframe
              src="https://www.youtube.com/embed/-l8DnmTXlUA"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </ReviewVideoCard>

        <ReviewVideoCard
          sx={{ display: { xs: "block", sm: "block" } }}
          data-aos="fade-down"
          data-aos-duration="1300"
        >
          <p style={{ textAlign: "center" }}>FOR A GOOD TIME FOLLOW</p>
          <H1
            style={{
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
            }}
          >
            @GAUVAIN
          </H1>
          {/* <ReviewVideoImg src={reviewvideo} /> */}
          {/* <video controls style={{ width: "100%" }}>
                        <source src={Video4} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
          <div style={{ width: "100%", position: "relative", height: "730px" }}>
            <iframe
              src="https://www.youtube.com/embed/6XEqpHigUAg"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </ReviewVideoCard>
      </ReviewVideoContainer>

      <ReviewVideoContainer container>
        <ReviewVideoCard data-aos="fade-up" data-aos-duration="1300">
          {/* <ReviewVideoImg src={reviewvideo} /> */}
          {/* <video controls style={{ width: "100%" }}>
                        <source src={Video5} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
          <div style={{ width: "100%", position: "relative", height: "730px" }}>
            <iframe
              src="https://www.youtube.com/embed/gN7wKKI_pIo"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
          <p style={{ textAlign: "center" }}>FOR A GOOD TIME FOLLOW</p>
          <H1
            style={{
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
            }}
          >
            @GAUVAIN
          </H1>
        </ReviewVideoCard>
        <ReviewVideoCard
          sx={{ marginTop: "10%", display: { xs: "block", sm: "block" } }}
          data-aos="fade-down"
          data-aos-duration="1300"
        >
          {/* <ReviewVideoImg src={reviewvideo} /> */}
          {/* <video controls style={{ width: "100%" }}>
                        <source src={Video6} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}

          <div style={{ width: "100%", position: "relative", height: "730px" }}>
            <iframe
              src="https://www.youtube.com/embed/ZoUbYK2PNJk"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>

          {/* <ReviewVideoImg
                        controls
                        playIcon={<PlayArrowIcon />}
                        url='https://www.tiktok.com/@gauvain.thailand/video/7348626999654583554?_r=1&_t=8iAk82sTjwQ'
                        width={'100%'}
                        height={"100%"}
                    /> */}
        </ReviewVideoCard>
        <ReviewVideoCard
          sx={{ display: { xs: "block", sm: "block" } }}
          data-aos="fade-up"
          data-aos-duration="1300"
        >
          {/* <ReviewVideoImg src={reviewvideo} /> */}
          {/* <video controls style={{ width: "100%" }}>
                        <source src={Video7} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
          <div style={{ width: "100%", position: "relative", height: "730px" }}>
            <iframe
              src="https://www.youtube.com/embed/VzKCfOYog70"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </ReviewVideoCard>

        <ReviewVideoCard
          sx={{ display: { xs: "block", sm: "block" } }}
          data-aos="fade-down"
          data-aos-duration="1300"
        >
          <p style={{ textAlign: "center" }}>FOR A GOOD TIME FOLLOW</p>
          <H1
            style={{
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
            }}
          >
            @GAUVAIN
          </H1>
          {/* <ReviewVideoImg src={reviewvideo} /> */}
          {/* <video controls style={{ width: "100%" }}>
                        <source src={Video8} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
          <div style={{ width: "100%", position: "relative", height: "730px" }}>
            <iframe
              src="https://www.youtube.com/embed/4xLIOuag9WM"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </ReviewVideoCard>
      </ReviewVideoContainer>

      <Grid container>
        <img src={shopDetail} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail1} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail2} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail3} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail4} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail5} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail6} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail7} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>

      {/* youtube */}
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFE757",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingBottom: "56.25%",
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/qhCzHIIbAAM"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div style={{ background: "#afdeee", overflow: "hidden" }}>
        <div style={{ position: "relative", zIndex: 1 }}>
          <div className="cloud-x2">
            <div className="cloud2"></div>
          </div>
          <div className="cloud-x1">
            <div className="cloud1"></div>
          </div>
        </div>
        <div
          style={{ position: "relative", maxWidth: "1240px", margin: "0 auto" }}
        >
          <Box
            sx={{
              background: "#afdeee",
              margin: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              maxWidth: "1440px",
            }}
          >
            <Imgtext
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ zIndex: "99" }}
              src={LogoX1}
            ></Imgtext>
            <Text64px
              className="Franchise"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              หอม อร่อย ทานง่าย
            </Text64px>
            <Text64px
              className="Franchise"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              อิ่มนานขึ้น หุ่นลีนกระชับ
            </Text64px>
            <Text64px
              className="Franchise"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <Imgtext src={EmoTitle}></Imgtext>
            </Text64px>
            <Imgitem
              data-aos="fade-up-right"
              data-aos-duration="1000"
              src={SnackBlack}
            ></Imgitem>
            <Imgitem
              data-aos="fade-up"
              data-aos-duration="1000"
              src={Emo}
              className="dukdug"
            ></Imgitem>
            <Imgitem
              data-aos="fade-up-left"
              data-aos-duration="1000"
              src={SnackYellow}
            ></Imgitem>
            <TextProd data-aos="fade-up-left" data-aos-duration="1000">
              รสกาแฟ <br />
              เอสเปรสโซ่
            </TextProd>
            <TextProd2 data-aos="fade-up-right" data-aos-duration="1000">
              รสช็อกโกแลต <br /> กล้วยหอม
            </TextProd2>
          </Box>
        </div>
        <Grid
          data-aos="fade-up"
          data-aos-duration="1000"
          sx={{ position: "relative", height: { xs: "100px", sm: "200px" } }}
        >
          <BuyButton variant="contained">สั่งซื้อ</BuyButton>
        </Grid>
        <Grid container justifyContent={"center"} sx={{ position: "relative" }}>
          <Cup src={cup} data-aos="fade-up" data-aos-duration="4000" />
          <Grid
            sx={{
              position: "absolute",
              bottom: "0",
              backgroundColor: "#231f20",
              width: "100%",
              height: "21%",
              zIndex: "0",
            }}
          ></Grid>
          <TreeLeft
            src={tree}
            data-aos="fade-up-right"
            data-aos-duration="1000"
          />
          <TreeRigth
            src={tree}
            data-aos="fade-up-left"
            data-aos-duration="1000"
          />
          <Lungmai
            src={Langmai}
            data-aos="fade-up-left"
            data-aos-duration="1000"
          />
        </Grid>
      </div>
      <Grid container>
        <img src={shopDetail8} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail9} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetailX} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      <Grid container>
        <img src={shopDetail11} alt="shopDetail" style={{ width: "100%" }} />
      </Grid>
      {/* <AboutContainer container>
        <AboutUs container className='BGPaper'>
          <Container>
            <Text90px sx={{ color: "#FE89B5", margin: "0" }} className='Franchise' data-aos="fade-up" data-aos-duration="1000">
              ABOUT US
            </Text90px>
           
            <NameProd sx={{ color: "#FFB600" }}>
              CONTACT US
            </NameProd>
            <Grid container gap={"1%"}>
              <Social src={facebook} />
              <Social src={tiktk} />
              <Social src={ig} />
              <Social src={youtube} />
            </Grid>
          </Container>
        </AboutUs>
        <ProductAboutImg src={ProductAbout} data-aos="fade-left" data-aos-duration="1000" />
      </AboutContainer> */}

      <AboutContainer container>
        <AboutUs container className="BGPaper">
          <Container>
            <Text90px
              sx={{ color: "#FE89B5", margin: "0" }}
              className="Franchise"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              ABOUT US
            </Text90px>
            <Text24px
              data-aos="fade-up"
              sx={{ textAlign: "left" }}
              data-aos-duration="1000"
            >
              Gauvain เครื่องดื่มโปรตีนถั่วลันเตาสีทองจากประเทศอเมริกา
              ที่มาพร้อมคุณสมบัติพิเศษมากมาย
              มีรายงานผลการศึกษาวิจัยถึงองค์ประกอบทางเคมีของถั่วลันเตา
              พบว่ามีสารสำคัญที่ออกฤทธิ์หลายชนิด อาทิเช่น Phytoestrogen,
              Zeaxanthin, lutein, lecithin, cabalamin, coumestrol และ purine
              เป็นต้น
            </Text24px>
            <Text24px
              data-aos="fade-up"
              sx={{ textAlign: "left" }}
              data-aos-duration="1000"
            >
              นอกจากนี้ถั่วลันเตายังมีคุณค่าทางโภชนาการดังนี้ มีพลังงาน
              คาร์โบไฮเดรต น้ำตาล เส้นไย ไขมัน โปรตีน วิตามินเอ เบต้าแคโรทีน
              ลูทีน ซีแซนทีน วิตามินบี1 วิตามินบี2 วิตามินบี3 วิตามินบี6
              วิตามินบี9 วิตามินซี วิตามินอี วิตามินเค ธาตุแคลเซียม ธาตุเหล็ก
              แมกนีเซียม แมงกานีส ฟอสฟอรัส โพแทสเซียม โซเดียม สังกะสี
              โปรตีนสูงช่วยให้คุณดูแลสร้างภูมิคุ้มกัน เส้นใยอาหารสูง
              ขับถ่ายคล่อง ไขมันต่ำ โซเดียมต่ำ และมีประสิทธิภาพผลลัพธ์ที่สูง . .
              .
            </Text24px>
            <Link to="/aboutus" style={{ textDecoration: "none" }}>
              <Text24px
                data-aos="fade-up"
                data-aos-duration="1000"
                sx={{ color: "#FE89B5", textAlign: "left" }}
              >
                อ่านเพิ่มเติม
              </Text24px>
            </Link>
            <NameProd sx={{ color: "#FFB600" }}>CONTACT US</NameProd>
            <Grid container gap={"1%"}>
              {/* <Link to={`https://www.facebook.com/profile.php?id=61553343980477&locale=th_TH`} style={{ width: "100%", height: `100%` }}>
                          <Social src={facebook}  style={{ width: "5%", marginTop: '6px' }} />
                      </Link>
                      
                      <Social src={tiktk} />
                      <Social src={ig} />
                      <Social src={youtube} /> */}
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61553343980477&locale=th_TH"
                style={{ width: "14%", margin: "0 3px" }}
              >
                <ImgIcon src={facebook} />
              </a>
              <a
                target="_blank"
                href="https://www.tiktok.com/@gauvain_thailand?_t=8lsHoFgrqs5&_r=1"
                style={{ width: "14%", margin: "0 3px" }}
              >
                <ImgIcon src={tiktk} />
              </a>
              <a
                target="_blank"
                href="https://rb.gy/boug2q"
                style={{ width: "14%", margin: "0 3px" }}
              >
                <ImgIcon src={ig} />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@Gauvain_official"
                style={{ width: "14%", margin: "0 3px" }}
              >
                <ImgIcon src={youtube} />
              </a>
            </Grid>
          </Container>
        </AboutUs>
        <ProductAboutImg
          src={ProductAbout}
          data-aos="fade-left"
          data-aos-duration="1000"
        />
      </AboutContainer>
    </Grid>
  );
}

export default Shop;
