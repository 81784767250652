import React, { useState } from "react";
import {
  Box,
  Button,
  styled,
  TextField,
  Grid,
  Typography,
} from "@mui/material";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../../../api/gauvain";
import { useAdmin } from "../context/AdminContextProvider";

const MainContainer = styled("main")(({ theme }) => ({
  width: "90%",
  margin: "0 auto",
  padding: "16px",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "8px",
  },
}));

export default function ProductDetail({
  item,
  back,
}: {
  item: any;
  back: any;
}) {
  const cookies = new Cookies();
  const api = new gauvainAPI();
  const token = cookies.get("access_token");
  const [input, setInput] = useState(item);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState("");

  const { fetchOrders } = useAdmin();

  const [open, setOpen] = useState(false);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleChange = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const [input2, setInput2] = useState({ description: item?.description });

  const handleChange2 = (value: any) => {
    setInput2({ ...input2, description: value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      if (selectedImage) {
        formData.append("file", selectedImage);
      }

      formData.append("name", input.name);
      formData.append("price", input.price);
      formData.append("description", input2.description);
      formData.append("background_color", input.background_color);
      formData.append("product_code", input.product_code);

      await api.updateProducts(token, item.id, formData);
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เรียบร้อยแล้ว",
        confirmButtonText: `ตกลง`,
        icon: `success`,
      });

      // แนป Product มาตอนยิงเรียกข้อมูลออเดอร์ด้วย
      fetchOrders();
      // setInput(item);
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เกิดข้อผิดพลาด",
        confirmButtonText: `ตกลง`,
        icon: `error`,
      });
    }
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "blockquote",
    "code-block",
    "link",
    "image",
    "video",
    "list",
    "bullet",
    "indent",
    "align",
    "direction",
    "clean",
  ];

  return (
    <MainContainer>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="outlined"
            onClick={back}
            sx={{
              fontSize: "1rem",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#1565c0",
                color:"white"
              },
            }}
          >
            Back
          </Button>
          <Typography variant="h6" sx={{ color: "#333", fontWeight: "bold" }}>
            ตัวอย่างสินค้า และ สีพื้นหลัง
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            justifyContent="center"
            padding={2}
            bgcolor={input.background_color}
            sx={{
              borderRadius: 2,
              boxShadow: 3,
              minHeight: 200,
              border: "1px solid #d1d1d1",
              transition: "box-shadow 0.3s, transform 0.3s",
              "&:hover": {
                boxShadow: 6,
                transform: "scale(1.02)",
              },
            }}
          >
            <img
              src={
                preview ||
                item?.image_url?.replace(
                  `{api_path}`,
                  process.env.REACT_APP_API_URL
                )
              }
              style={{ maxHeight: "281px", borderRadius: "8px" }}
              alt={item.name}
            />
          </Box>

          <Box
            sx={{
              border: "1px solid #d1d1d1",
              padding: 3,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} display={'flex'} gap={2}>
                <TextField
                  label="ชื่อสินค้า"
                  variant="standard"
                  value={input.name}
                  onChange={handleChange}
                  name="name"
                  fullWidth
                  sx={{
                    fontSize: "1rem",
                    "& .MuiInputBase-root": {
                      transition: "border-color 0.3s",
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "2px solid #2979ff",
                      },
                    },
                  }}
                />
                 <TextField
                  label="รหัสสินค้า"
                  variant="standard"
                  value={input.product_code}
                  onChange={handleChange}
                  name="product_code"
                  fullWidth
                  sx={{
                    fontSize: "1rem",
                    "& .MuiInputBase-root": {
                      transition: "border-color 0.3s",
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "2px solid #2979ff",
                      },
                    },
                  }}
                />
              </Grid>
          
              <Box
                sx={{
                  padding: 2,

                  width: "100%",
                  marginY: 2,
                }}
              >
                <ReactQuill
                  value={input2.description}
                  onChange={handleChange2}
                  theme="snow"
                  formats={formats}
                  style={{
                    height: "320px",
                    width: "100%",

                    backgroundColor: "#fff",
                  }}
                />
              </Box>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="ราคาสินค้า"
                  variant="standard"
                  value={input.price}
                  onChange={handleChange}
                  name="price"
                  fullWidth
                  sx={{
                    fontSize: "1rem",
                    "& .MuiInputBase-root": {
                      transition: "border-color 0.3s",
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "2px solid #2979ff",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="สีพื้นหลัง"
                  variant="standard"
                  value={input.background_color}
                  onChange={handleChange}
                  name="background_color"
                  fullWidth
                  sx={{
                    fontSize: "1rem",
                    "& .MuiInputBase-root": {
                      transition: "border-color 0.3s",
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "2px solid #2979ff",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <label
                  style={{
                    background: "#d1d1d1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px",
                    color: "#444",
                    borderRadius: "8px",
                    cursor: "pointer",
                    marginTop: "8px",
                    transition: "background-color 0.3s",
                    border: "1px dashed #8a8a8a",
                  }}
                  htmlFor="upload-photo"
                >
                  เปลี่ยนรูปสินค้า
                </label>
                <input
                  accept="image/*"
                  id="upload-photo"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </Grid>
            </Grid>

            <Box marginTop={4} display="flex" justifyContent="flex-end" gap={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  setInput(item);
                  setPreview("");
                  setSelectedImage(null);
                }}
                sx={{
                  fontSize: "1rem",
                  borderRadius: 2,
                  transition: "color 0.3s, border-color 0.3s",
                  "&:hover": {
                    color: "#ff1744",
                    borderColor: "#ff1744",
                  },
                }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                  fontSize: "1rem",
                  borderRadius: 2,
                  boxShadow: 2,
                  transition: "background-color 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    backgroundColor: "#1c54b2",
                    boxShadow: 4,
                  },
                }}
              >
                ยืนยัน
              </Button>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  ยืนยันการแก้ใขข้อมูล ?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    หากคุณต้องการยืนยันการแก้ใขกดปุ่ม "ยืนยัน"
                    หากไม่ต้องการกดปุ่ม "ยกเลิก" .
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    variant="outlined"
                    color="error"
                    sx={{ fontSize: "1rem", transition: "color 0.3s" }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    onClick={() => {
                      handleSubmit();
                      handleClose();
                    }}
                    variant="contained"
                    sx={{
                      fontSize: "1rem",
                      boxShadow: 2,
                      bgcolor: "#2979ff",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        backgroundColor: "#1565c0",
                        boxShadow: 4,
                      },
                    }}
                  >
                    ยืนยัน
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
}
