import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import TemplateExcelDashboard from "./TemplateDashboard";
import { useAdmin } from "../context/AdminContextProvider";
import TemplateExcelOrder from "./TemplateExcelOrder";

export default function ReportDashboard() {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const getCurrentQuarter = () => {
    const currentMonth = new Date().getMonth() + 1;
    return Math.ceil(currentMonth / 3);
  };

  const [startDate, setStartDate] = useState(
    currentMonth == 1 ? 12 : currentMonth - 1
  );
  const [endDate, setEndDate] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [year2, setYear2] = useState(currentYear);

  const [selectedQuarter, setSelectedQuarter] =
    useState<number>(getCurrentQuarter);

  const [select, setSelect] = useState("date");

  const [finalData, setFinalData] = useState<any[]>([]);

  const handleStartDateChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setStartDate(event.target.value as any);
  };

  const handleEndDateChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setEndDate(event.target.value as any);
  };

  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as any);
  };
  const handleYearChange2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear2(event.target.value as any);
  };

  const years = Array.from(new Array(30), (val, index) =>
    (new Date().getFullYear() - index).toString()
  );

  const handleQuarterChange = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedQuarter(event.target.value as number);
  };
  const handleChangeSelect = (value: string) => setSelect(value);

  const {
    fetchDataByQuarter,
    saleDataQuarter,
    fetchDataByBetween,
    saleDataBetween,
    fetchDataByMonth,
    saleDataMonth,
    fetchData,
    saleData,
  } = useAdmin();

  const handleSubmit = async () => {
    let data;
    if (select === "date") {
      data = await fetchDataByMonth(startDate, year);
    } else if (select === "month") {
      data = await fetchDataByBetween(startDate, endDate, year,year2);
    } else if (select === "quarter") {
      data = await fetchDataByQuarter(selectedQuarter, year);
    } else if (select === "year") {
      data = await fetchData(year);
    }

    setFinalData(data);
  };

  return (
    <main>
      <Box display={"flex"} flexDirection={"column"} gap={2} paddingRight={4}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: 0,
            "& > *": {
              m: 1,
            },
          }}
        >
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button
              onClick={() => handleChangeSelect("date")}
              sx={{
                fontSize: "1rem",
                transition: "background-color 0.3s",
                backgroundColor: `${select == "date" && "#1565c0"}`,
                color: `${select == "date" && "white"}`,
                "&:hover": {
                  backgroundColor: "#1565c0",
                  color: "white",
                },
              }}
            >
              วัน
            </Button>
            <Button
              onClick={() => handleChangeSelect("month")}
              sx={{
                fontSize: "1rem",
                transition: "background-color 0.3s",
                backgroundColor: `${select == "month" && "#1565c0"}`,
                color: `${select == "month" && "white"}`,
                "&:hover": {
                  backgroundColor: "#1565c0",
                  color: "white",
                },
              }}
            >
              เดือน
            </Button>
            <Button
              onClick={() => handleChangeSelect("quarter")}
              sx={{
                fontSize: "1rem",
                transition: "background-color 0.3s",
                backgroundColor: `${select == "quarter" && "#1565c0"}`,
                color: `${select == "quarter" && "white"}`,
                "&:hover": {
                  backgroundColor: "#1565c0",
                  color: "white",
                },
              }}
            >
              ไตรมาส
            </Button>
            <Button
              onClick={() => handleChangeSelect("year")}
              sx={{
                fontSize: "1rem",
                transition: "background-color 0.3s",
                backgroundColor: `${select == "year" && "#1565c0"}`,
                color: `${select == "year" && "white"}`,
                "&:hover": {
                  backgroundColor: "#1565c0",
                  color: "white",
                },
              }}
            >
              ปี
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="end"
          paddingRight={1}
        >
          {(select == "month" || select == "date") && (
            <FormControl variant="outlined" size="small">
              <InputLabel>Start Month</InputLabel>
              <Select
                value={startDate}
                onChange={(e: any) => handleStartDateChange(e)}
                label="Start Date"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {[...Array(12)].map((_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {new Date(0, index)?.toLocaleString("th-TH", {
                      month: "long",
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {select == "month" && (
            <FormControl variant="outlined" size="small">
              <InputLabel>Start Year</InputLabel>
              <Select
                value={year2}
                onChange={(e: any) => handleYearChange2(e)}
                label="startYear"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {select == "month" && (
            <FormControl variant="outlined" size="small">
              <InputLabel>End Month</InputLabel>
              <Select
                value={endDate}
                onChange={(e: any) => handleEndDateChange(e)}
                label="End Date"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {[...Array(12)].map((_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {new Date(0, index)?.toLocaleString("th-TH", {
                      month: "long",
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {select == "quarter" && (
            <FormControl variant="outlined" size="small">
              <InputLabel>Quarter</InputLabel>
              <Select
                labelId="quarter-select-label"
                value={selectedQuarter}
                onChange={(e: any) => handleQuarterChange(e)}
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
              >
                {Array.from({ length: 4 }, (_, index) => index + 1).map(
                  (quarter) => (
                    <MenuItem key={quarter} value={quarter}>
                      {`ไตรมาสที่ ${quarter}`}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}

          <FormControl variant="outlined" size="small">
            <InputLabel>Year</InputLabel>
            <Select
              value={year}
              onChange={(e: any) => handleYearChange(e)}
              label="Year"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display={"flex"} justifyContent={"end"} paddingRight={1}>
          <Button variant="outlined" onClick={handleSubmit}>
            ยืนยัน
          </Button>
        </Box>
      </Box>
      {finalData && <TemplateExcelDashboard data={finalData} />}
    </main>
  );
}
