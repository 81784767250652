import React, { useEffect, useState } from "react";
import {

  styled,

} from "@mui/material";

import LineChartDashBoard from "./LineChartDashBoard";

const MainContainer = styled("main")({
  width: "100%",
  padding: "16px",
  boxSizing: "border-box",

});



const Dashboard: React.FC = () => {


  return (
    <MainContainer>
     <LineChartDashBoard />
    </MainContainer>
  );
};

export default Dashboard;
