import { InputAdornment, IconButton, Input } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useEffect, useState } from "react";

const QuantityButton = (props:any) => {
    const [quantity, setQuantity] = useState<number>(0);
    
    useEffect(() => {
        if(props.quantity !== undefined)
        {
            setQuantity(props.quantity);
        }
    }, [props.quantity]);

    return (
        <Input
            name='quantity'
            type='text'
            startAdornment={
                <InputAdornment position="start">
                    <IconButton
                        onClick={props.handleMinusQuantity}
                        sx={
                            {
                                backgroundColor: 'white',
                                "&:hover": {
                                    backgroundColor: '#f2f2f2'
                                }
                            }
                        }
                    >
                        <RemoveOutlinedIcon sx={
                            {
                                color: '#FFB600'
                            }
                        } />
                    </IconButton>
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        onClick={props.handlePlusQuantity}
                        sx={
                            {
                                backgroundColor: 'white',
                                "&:hover": {
                                    backgroundColor: '#f2f2f2'
                                }
                            }
                        }
                    >
                        <AddOutlinedIcon sx={
                            {
                                color: '#FFB600'
                            }
                        } />
                    </IconButton>
                </InputAdornment>
            }
            value={quantity}
            inputProps={
                {
                    style: {
                        textAlign: 'center',
                        color: 'white',
                        fontSize: 20
                    }
                }
            }
            size='small'
            disableUnderline={true}
            sx={
                {
                    ...props.sx
                }
            }
        />
    )
}

export default QuantityButton;