import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Index from "./pages/index";
import { ParallaxProvider } from "react-scroll-parallax";
import Shop from "./pages/Shop";
import News from "./pages/News";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Checkout from "./pages/Checkout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ProductDetail from "./pages/ProductDetail";
import Admin from "./components/admin/Admin";
import Order from "./components/admin/Order";
import Dashboard from "./components/admin/component/Dashboard";
import Products from "./components/admin/Products";
import Account from "./components/admin/Account";
import CheckoutForm from "./components/payment/CheckoutForm";
import Return from "./components/payment/Return";
import { PaymentContextProvider } from "./context/PaymentContextProvider";
import LiveWeb from "./components/admin/Live";
import Report from "./components/admin/Report";

const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  {
    element: (
      <PaymentContextProvider>
        <App />
      </PaymentContextProvider>
    ),

    children: [
      { path: "/", element: <Index /> },
      { path: "/shop", element: <Shop /> },
      { path: "/news", element: <News /> },
      { path: "/aboutus", element: <About /> },
      { path: "/contactus", element: <Contact /> },
      { path: "/checkout", element: <Checkout /> },
      { path: "/productdetail/:id", element: <ProductDetail /> },
      { path: "/checkout/payment", element: <CheckoutForm /> },
      { path: "return", element: <Return /> },
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "order", element: <Order /> },
      { path: "product", element: <Products /> },
      { path: "account", element: <Account /> },
      { path: "live", element: <LiveWeb /> },
      { path: "report", element: <Report /> },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
    <ParallaxProvider>
      <RouterProvider router={router} />
    </ParallaxProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
