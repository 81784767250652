import React, { ChangeEvent, useState } from "react";
import { Box, Button, styled,Typography } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../../../api/gauvain";
import { useAdmin } from "../context/AdminContextProvider";

const MainContainer = styled("main")({
  width: "90%",
  margin: "0 auto",
  padding: "16px",
  "@media (max-width: 600px)": {
    width: "100%",
    padding: "8px",
  },
});

const DivText = styled("div")({
  display: "flex",
  gap: "8px",
  flexWrap: "wrap",
  alignItems: "center",
  "@media (max-width: 600px)": {
    gap: "4px",
  },
});

const Ptext = styled("p")({
  color: "#555e5e",
  margin: 0,
});

const InputText = styled("input")({
  outline: "none",
  border: "none",
  borderBottom: "1px solid #000",
  padding: "8px 0",
  fontSize: "16px",
  transition: "border-color 0.3s",
  "&:focus": {
    borderBottom: "2px solid #3f51b5",
  },
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
});

export default function OrderDetail({ item, back }: { item: any; back: any }) {
  const { fetchOrders, allOrders } = useAdmin();

  const [track, setTrack] = useState(item?.track || "");
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const order = JSON.parse(item?.order);
  const product = allOrders?.products;

  const cookies = new Cookies();
  const api = new gauvainAPI();
  const token = cookies.get("access_token");

  const total = order.reduce((acc: any, i: any) => (acc += i.total), 0);

  const handleDelete = () => {};

  const handleSubmitTrack = async () => {
    try {
      const data = [{ id: item.id, track: track }];
      await api.updateOrder(token, data);
      fetchOrders();
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เรียบร้อยแล้ว",
        confirmButtonText: `ตกลง`,
        icon: `success`,
      });
      setIsEdit(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MainContainer>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button variant="outlined" onClick={back} sx={{boxShadow:1}}>
        Back
      </Button>
      <Tooltip title="Delete">
        <IconButton onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"คุณต้องการลบออเดอร์นี้ ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          หากคุณกดยืนยันแล้วออเดอร์นี้จะถูกลบออกจากระบบฐานข้อมูลไม่สามารถดึงกลับมาได้
          หากคุณยังต้องการลบออกกรุณากดปุ่ม "ยืนยันการลบ"
          หากไม่ต้องการลบกดปุ่ม "ยกเลิก".
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          ยกเลิก
        </Button>
        <Button onClick={handleClose} variant="outlined" color="error">
          ยืนยันการลบ
        </Button>
      </DialogActions>
    </Dialog>
    <Box sx={{ border: "1px solid #e0e0e0", padding: "16px", borderRadius: "8px", backgroundColor: "#fff" ,marginTop:4 ,boxShadow:1}}>
      <Typography variant="h5" sx={{ marginBottom: "16px", fontWeight: 600, color: "#235aad" }}>
        รายละเอียดคำสั่งซื้อ
      </Typography>
      <DivText>
        <Typography sx={{ fontWeight: 600 }}>ชื่อ :</Typography>
        <Ptext>{item?.name}</Ptext>
      </DivText>
      <DivText>
        <Typography sx={{ fontWeight: 600 }}>Tel. :</Typography>
        <Ptext>{item?.phone}</Ptext>
      </DivText>
      <DivText>
        <Typography sx={{ fontWeight: 600 }}>ที่อยู่จัดส่ง :</Typography>
        <Ptext>{item?.address}</Ptext>
      </DivText>
      <DivText>
        <Typography sx={{ fontWeight: 600 }}>การจ่ายเงิน :</Typography>
        <Ptext>{item?.status_payment ? "จ่ายแล้ว" : "ยังไม่จ่าย"}</Ptext>
      </DivText>
      <DivText>
        <Typography sx={{ fontWeight: 600 }}>เลขอ้างอิงการจ่ายเงิน :</Typography>
        <Ptext>{item?.intent_id}</Ptext>
      </DivText>
      <DivText>
        <Typography sx={{ fontWeight: 600 }}>isCOD :</Typography>
        <Ptext>{item?.isCOD==1 ? "เก็บเงินปลายทาง": ""}</Ptext>
      </DivText>
      <DivText>
        <Typography sx={{ fontWeight: 600 }}>หมายเลขพัสดุ {"(Track)"} :</Typography>
        <InputText
          disabled={!isEdit}
          value={track}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setTrack(e.target.value)}
        />
        {!isEdit && track && (
          <Button variant="contained" onClick={() => setIsEdit(true)}>
            แก้ใข
          </Button>
        )}
        {!isEdit && !track && (
          <Button variant="contained" onClick={() => setIsEdit(true)}>
            เพิ่ม
          </Button>
        )}
        {isEdit && (
          <>
            <Button variant="contained" onClick={handleSubmitTrack}>
              ยืนยัน
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setIsEdit(false);
                setTrack(item?.track || "");
              }}
            >
              ยกเลิก
            </Button>
          </>
        )}
      </DivText>
      {product?.map((pd: any) => {
        const orderItem = order.find((item: any) => item.product_id === pd.id);
        const finalOrder = orderItem ? { quantity: orderItem.quantity, total: orderItem.total } : { quantity: 0, total: 0 };

        return (
          <DivText key={pd.id}>
            <Typography sx={{ fontWeight: 600 }}>{pd?.name} :</Typography>
            <Ptext sx={{ color: "red" }}>{finalOrder.quantity} ชิ้น</Ptext>
          </DivText>
        );
      })}
      <DivText sx={{ justifyContent: "space-between", marginTop: "16px" }}>
        <Typography variant="h5" sx={{ fontWeight: 600, color: "#235aad" }}>
          รวม
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 600, color: "red" }}>
          {total?.toLocaleString()} บาท
        </Typography>
      </DivText>
    </Box>
  </MainContainer>
  );
}
