import React from "react";
import { styled } from "@mui/system";
import Navbar from "./component/Navbar";
import { AdminProvider } from "./context/AdminContextProvider";
import ProtechRoute from "./protechRoute";
import Dashboard from "./component/Dashboard";
import { Outlet, useLocation } from "react-router-dom";


const MainContainer = styled('main')({
  display: 'flex',
  width: '100%',
  height: '100vh',
});

const ContentContainer = styled('div')({
  flex: 1,
  overflow: 'auto',
});

export default function Admin() {
  return (
    <AdminProvider>
      <ProtechRoute>
        <MainContainer>
          <Navbar />
          <ContentContainer>
          <Outlet></Outlet>
          </ContentContainer>
        </MainContainer>
      </ProtechRoute>
    </AdminProvider>
  );
}
