import { Box, Button, Divider, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { IconButton } from "@mui/material";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { gauvainAPI } from "../../api/gauvain";
import Swal from "sweetalert2";
import Popup from "./Popup";

const initailInput = { text: "", rate: 5 };

export default function InsertReview({fetchReview}:{fetchReview:any}) {
  const [input, setInput] = useState(initailInput);

  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleChange = (e: any) =>
    setInput({ ...input, [e.target.name]: e.target.value });

  const handleClickOpen = () => {
    if (!cookies.get("access_token")) {
      return navigate("/login");
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInput(initailInput);
  };

  const handleSubmit = async () => {
    try {
      const api = new gauvainAPI();

      const response = await api.createReview(
        cookies.get("access_token"),
        input
      );

      if (response.data?.message == "quota_exceeded") {
    
        return Swal.fire({
          title: `แจ้งเตือน`,
          text: "กรุณาสั่งซื้อสินค้าก่อนรีวิว",
          confirmButtonText: `ตกลง`,
          icon: `info`,
        });
      }

      setOpen(false);
      setInput(initailInput);
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เรียบร้อยแล้ว",
        confirmButtonText: `ตกลง`,
        icon: `success`,
      });
      fetchReview()

      
    } catch (err) {
      console.log(err);
    }
  };

  const isTextValid = input.text.length > 0;
  return (
    <Box display={"flex"} justifyContent={"end"} paddingRight={8}>
      {/* <Button
        size="large"
        variant="contained"
        sx={{ color: "white" }}
        onClick={handleClickOpen}
      >
        เขียนรีวิว
      </Button> */}
      <Popup handleClickOpen={handleClickOpen}/>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: 24,
            borderRadius: 16,
            boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.2)",
            maxWidth: "600px",
            width: "100%",
            // background: "linear-gradient(135deg, #f0f0f0 0%, #e0e0e0 100%)",
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              padding: 0,
              fontFamily: "'Roboto', sans-serif",
              fontWeight: 600,
              fontSize: "1.5rem",
            }}
          >
            <StarIcon color="primary" style={{ fontSize: 32 }} />
            {"เขียนรีวิว"}
          </DialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ fontSize: 28 }} />
          </IconButton>
        </Box>
        <Divider style={{ marginBottom: 24 }} />
        <DialogContent>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant="h6"
              style={{
                marginBottom: 24,
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 500,
              }}
            >
              ให้ดาว
            </Typography>
          </motion.div>
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
          >
            <Rating
              size="large"
              value={input.rate}
              name="rate"
              onChange={handleChange}
              precision={0.5}
              style={{ marginBottom: 32 }}
            />
          </motion.div>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <TextField
              placeholder="ข้อความ"
              value={input.text}
              name="text"
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              error={!isTextValid}
              helperText={!isTextValid ? "กรุณาใส่ข้อความ" : ""}
              style={{
                backgroundColor: "#fafafa",
                borderRadius: 12,
                border: "1px solid #d0d0d0",
              }}
            />
          </motion.div>
        </DialogContent>
        <Divider style={{ marginBottom: 24, marginTop: 24 }} />
        <DialogActions style={{ padding: 16 }}>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
              style={{
                borderRadius: 12,
                padding: "10px 20px",
                borderColor: "#9e9e9e",
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 500,
              }}
            >
              ยกเลิก
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={!isTextValid}
              autoFocus
              style={{
                borderRadius: 12,
                padding: "10px 20px",
                background: "linear-gradient(45deg, #3f51b5 30%, #5c6bc0 90%)",
                color: "#fff",
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 500,
              }}
            >
              ส่งรีวิว
            </Button>
          </motion.div>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
