import { Button, Grid, Typography, styled } from '@mui/material'
import React, { useEffect } from 'react'
import bg from '../../assets/images/bgCoffee.png'
import snack from '../../assets/images/1ae80692ddc074140d03145f549f8657.png'
import logoText from '../../assets/images/LogoPNG.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom'
type Props = {}
const Text64px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px",

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "35px",

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "44px",

    },
    [theme.breakpoints.up("xl")]: {
        fontSize: "60px",

    },
    fontWeight: "500",
    padding: "12px 0",
    zIndex: "0",
    position: "absolute",
    rotate: "40deg",
    top: "8%",
    right: "25%"
}));
const BuyButton = styled(Button)(({ theme }) => ({
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px",
        padding: "3px 5px",
        width: "30%"

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
        padding: "6px 32px",
        width: "25%"

    },
    border: "1px solid #B36E29",
    color: "#FFF",
    backgroundColor: "#FFB600",
    borderRadius: "24px",

}));
const Snack = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "50%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "38%",

    },

}));
const Logo = styled("img")(({ theme }) => ({
    position: "absolute",
    right: "0",
    top: "0",
    width: "25%",

}));

function BannerDetailCoffee({ }: Props) {
    useEffect(() => {
        AOS.init()
    }, [])
    const navigate = useNavigate()

    return (
        <Grid container sx={
            {
                width: "100%",
                backgroundImage: `url(${bg})`,
                position: "relative",
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "center",
                padding: "5% 0"
            }}>
            <Logo src={logoText}  />
            <Grid container flexDirection={"column"} gap={"12px"} alignItems={"center"}>
                <Snack src={snack} className='dukdug' data-aos="fade-up-right" data-aos-duration="1500" />
                <Typography variant='h6' sx={{ color: "#FFF", textAlign: "center", display: { xs: "block", sm: "none" } }}>
                    รสกาแฟ <br />เอสเปรสโซ่
                </Typography>
                <BuyButton onClick={()=> navigate("/checkout")}  variant="contained" data-aos="fade-up-right" data-aos-duration="1500">
                    สั่งซื้อสินค้า
                </BuyButton>
            </Grid>
            <Text64px sx={{ color: "#FFF", textAlign: "center", display: { xs: "none", sm: "block" } }}>
                รสกาแฟ <br />เอสเปรสโซ่
            </Text64px>
        </Grid>
    )
}

export default BannerDetailCoffee