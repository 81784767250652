import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Grid,
  Typography,
} from "@mui/material";
import { useAdmin } from "./context/AdminContextProvider";
import OrderDetail from "./component/OrderDetail";
import AddExpress from "./component/AddExpress";
import CreateExpress from "./component/CreateExpress";

const MainContainer = styled("main")(({ theme }) => ({
  width: "98%",
  padding: theme.spacing(2),
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    width: "100%",
  },
}));

const StyledTableContainer: any = styled(TableContainer)(({ theme }) => ({
  overflowX: "auto",
  minWidth: 700,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down("sm")]: {
    minWidth: "100%",
  },
}));

const StyledTable = styled(Table)({
  minWidth: 650,
});

export default function Order() {
  const { fetchOrders, allOrders } = useAdmin();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [togglePage, setTogglePage] = useState(false);
  const [itemDetail, setItemDetail] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageOpen = () => setTogglePage(true);
  const handlePageClose = () => setTogglePage(false);

  const displayedRows = allOrders?.orders?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <MainContainer>
      {!togglePage && (
        <>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'}>
            <Box
              display="flex"
              gap={2}
              paddingY={2}
              marginY={2}
              justifyContent="center"
              alignItems="center"
              sx={{
                width : 260,

                height: 32,
                fontSize: "1.8rem",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Typography>ตารางคำสั่งซื้อ</Typography>
            </Box>
            <Box>
              <Box display={"flex"} justifyContent={"end"} gap={2}>
                <Button variant="contained" onClick={handleClickOpen}>เลือกใช้ขนส่ง</Button>
                <Button variant="outlined" onClick={handleClickOpen2}>เพิ่มขนส่ง</Button>
              </Box>
            </Box>
          </Box>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead sx={{ bgcolor: "#d1d1d1" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                    width={100}
                  >
                    ชื่อผู้สั่งซื้อ
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    สร้างเมื่อ
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                    width={60}
                  >
                    จ่ายเงิน
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    Track
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    รายละเอียด
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedRows?.map((order: any, index: number) => (
                  <TableRow
                    key={order.id}
                    sx={{
                      bgcolor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                      "&:hover": { bgcolor: "#f1f1f1" },
                    }}
                  >
                    <TableCell sx={{ textAlign: "center" }}>
                      {order.id}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {order.name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {new Date(order.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          paddingX: 2,
                          paddingY: 0.6,
                          width: 104,
                          borderRadius: 16,

                          boxShadow: 1,
                          bgcolor: order.status_payment
                            ? "rgba(63, 185, 104, 0.3)" // Opaque green
                            : "rgba(220, 149, 25, 0.4)", // Semi-transparent red
                          color: order.status_payment
                            ? "#249c4c"
                            : order?.isCOD == 1
                            ? "#2560b3"
                            : "#c02626",
                        }}
                      >
                        <Box
                          sx={{
                            height: 10,
                            width: 10,
                            borderRadius: 100,
                            bgcolor: order.status_payment
                              ? "#249c4c"
                              : order?.isCOD == 1
                              ? "#2560b3"
                              : "#c02626",
                          }}
                        ></Box>
                        {order.status_payment
                          ? "จ่ายแล้ว"
                          : order?.isCOD
                          ? "เก็บปลายทาง"
                          : "ไม่ระบุ"}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                            paddingX: 2,
                            paddingY: 0.6,
                            width: 144,
                            borderRadius: 16,

                            boxShadow: 1,

                            bgcolor: order.track
                              ? "rgba(33, 93, 171, 0.5)" // Opaque green
                              : "rgba(92, 85, 85, 0.2)", // Semi-transparent red
                            color: order.track ? "#2560b3" : "#544a4a",
                          }}
                        >
                          <Box
                            sx={{
                              height: 10,
                              width: 10,
                              borderRadius: 100,
                              bgcolor: order.track ? "#2560b3" : "#544a4a",
                            }}
                          ></Box>
                          {order.track ? order.track : "ยังไม่จัดส่ง"}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            paddingX: 2,
                            paddingY: 1,
                            cursor: "pointer",
                            width: 84,
                            borderRadius: 16,
                            bgcolor: "#235aad",
                            color: "white",
                            boxShadow: 1,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handlePageOpen();
                            setItemDetail(order);
                          }}
                        >
                          แสดง
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allOrders?.orders?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledTableContainer>
        </>
      )}
      {togglePage && <OrderDetail item={itemDetail} back={handlePageClose} />}
      <AddExpress handleClose={handleClose} open={open}/>
      <CreateExpress handleClose={handleClose2} open={open2}/>
    </MainContainer>
  );
}
