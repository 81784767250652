import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box } from "@mui/material";

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

interface ProductData {
  quantity: number;
  total: number;
}

interface DataPoint {
  year: number;
  month: number;
  day: number;
  total: number;
  products: {
    [key: string]: ProductData;
  };
}

interface LineChartProps {
  data: DataPoint[];
}

const LineChartMonth: React.FC<LineChartProps> = ({ data }) => {
  // Prepare data for chart
  const labels = data.map(d => `${d.year}-${d.month < 10 ? `0${d.month}` : d.month}-${d.day < 10 ? `0${d.day}` : d.day}`);
  
  // Define product names
  const productNames: { [key: string]: string } = {
    '1': 'รสช็อกโกแลตกล้วยหอม',
    '2': 'รสกาแฟเอสเปรซโซ'
  };

  const productColors: { [key: string]: string } = {
    '1': '#FFD700', // Yellow
    '2': '#8B4513'  // Brown
  };

  const productLabels = Object.values(productNames);

  const datasets = Object.keys(productNames).map(productId => {
    return {
      label: productNames[productId],
      data: data.map(d => d.products[productId]?.total || 0),
      fill: false,
      borderColor:  productColors[productId],
      tension: 0.1,
      pointRadius: 5,
    };
  });

  const chartData = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Important for controlling height
    plugins: {
      legend: {
        position: "top" as const,
        align: "start" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetLabel = context.dataset.label || '';
            const total = context.raw;
            const dayData = data[context.dataIndex];
            // Get productId from the label
            const productId = Object.keys(productNames).find(key => productNames[key] === datasetLabel);
            const quantity = productId ? dayData.products[productId]?.quantity || 0 : 0;
            return `${datasetLabel}: ${quantity} ชิ้น, ${total.toLocaleString()} บาท`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          color: "#333",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Sales (Baht)",
          color: "#333",
        },
        ticks: {
          callback: (value: any) => value.toLocaleString(),
        },
      },
    },
  };

  return (
    <div  style={{ minWidth: "500px", minHeight: "420px",maxHeight:"500px",overflowX:"auto" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineChartMonth;
