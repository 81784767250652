import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BannerDetailBanana from '../components/bannerDetail/BannerDetailBanana';
import BannerDetailCoffee from '../components/bannerDetail/BannerDetailCoffee';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import banana1 from '../assets/banana/S__285024331_0.jpg'
import banana2 from '../assets/banana/S__285024333_0.jpg'
import banana3 from '../assets/banana/S__285024334_0.jpg'
import banana4 from '../assets/banana/S__285024335_0.jpg'
import banana5 from '../assets/banana/S__285024336_0.jpg'
import banana6 from '../assets/banana/S__285024337_0.jpg'
import banana7 from '../assets/banana/S__285024338_0.jpg'
import banana8 from '../assets/banana/S__285024339_0.jpg'
import banana9 from '../assets/banana/S__285024340_0.jpg'
import banana10 from '../assets/banana/S__285024341_0.jpg'
import coffee1 from '../assets/coffee/S__285024343_0.jpg'
import coffee2 from '../assets/coffee/S__285024345_0.jpg'
import coffee3 from '../assets/coffee/S__285024346_0.jpg'
import coffee4 from '../assets/coffee/S__285024347_0.jpg'
import coffee5 from '../assets/coffee/S__285024348_0.jpg'
import coffee6 from '../assets/coffee/S__285024349_0.jpg'
import coffee7 from '../assets/coffee/S__285024350_0.jpg'
import coffee8 from '../assets/coffee/S__285024351_0.jpg'
import coffee9 from '../assets/coffee/S__285024352_0.jpg'
import coffee10 from '../assets/coffee/S__285024353_0.jpg'
import LogoX1 from "../assets/images/logo_x1.png";
import SnackBlack from "../assets/images/snack_black.png";
import SnackYellow from "../assets/images/snack_yellow.png";
import Emo from "../assets/images/emo.png";
import EmoTitle from "../assets/images/emo_title.png";
import cup from '../assets/images/cup.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import tree from '../assets/images/tree.png'
import Langmai from '../assets/images/langmai.png'
import shopDetail8 from '../assets/images/ProductDetail1.jpg'
import shopDetail9 from '../assets/images/ProductDetail2.jpg'
import shopDetail10 from '../assets/images/S__285024421_0.jpg'
import shopDetail11 from '../assets/images/S__285024441.jpg'
import shopDetail12 from '../assets/images/S__285024443.jpg'
import shopDetail13 from '../assets/images/S__285024422.jpg'
import shopDetail14 from '../assets/images/ProductDetail7.jpg'
import shopDetail15 from '../assets/images/ProductDetail6.jpg'
import shopDetail16 from '../assets/images/S__285024442.jpg'
import shopDetail17 from '../assets/images/S__285024419_0.jpg'



interface Image {
  IMG: string;
}
const BuyButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "35%",
  left: "35%",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    padding: "0",

  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
    padding: "6px 34px",

  },
  border: "1px solid #B36E29",
  color: "#FFF",
  backgroundColor: "#FFB600",
  borderRadius: "24px",
  width: "30%"
}));
const Text64px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "24px",

  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",

  },
  [theme.breakpoints.up("md")]: {
    fontSize: "34px",

  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "56px",

  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "64px",

  },
  fontWeight: "500",
  padding: "12px 0"
}));
const Imgitem = styled("img")(() => ({
  width: "30%"
}));
const Imgtext = styled("img")(() => ({
  width: "50%"
}));
const TextProd = styled("h1")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    left: "15%",
    bottom: "-10%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
    left: "18%",
    bottom: "-12%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",

  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "50px",
    left: "15%",

  },
  fontWeight: "700",
  position: 'absolute',
  color: "#454545",
  rotate: "8deg",
}));
const TextProd2 = styled("h1")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    right: "8%",
    top: "55%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
    right: "8%",
    top: "45%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px"

  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "50px"
  },
  fontWeight: "700",
  position: 'absolute',
  color: "#454545",
  rotate: "343deg",
}));
const Cup = styled("img")(() => ({
  width: "50%",
  zIndex: "1",

}));
const TreeLeft = styled("img")(() => ({
  width: "30%",
  zIndex: "2",
  position: "absolute",
  left: "-15%",
  bottom: "-5%",
  rotate: "45deg"
}));
const TreeRigth = styled("img")(() => ({
  width: "30%",
  zIndex: "2",
  position: "absolute",
  right: "-12%",
  bottom: "11%",
  rotate: "320deg"
}));
const Lungmai = styled("img")(() => ({
  width: "25%",
  zIndex: "2",
  position: "absolute",
  right: "-5%",
  bottom: "-9%",
}));
function ProductDetail() {
  const [imgOnPage, setImgOnPage] = useState<Image[]>([]);
  const [imgSecOnPage, setImgSecOnPage] = useState<Image[]>([]);
  const { id } = useParams();
  const bananaImg = [
    {
      IMG: banana1
    },
    {
      IMG: banana2
    },
    {
      IMG: banana3
    },
    {
      IMG: banana4
    },
    {
      IMG: banana5
    },
    {
      IMG: banana6
    },
    {
      IMG: banana7
    },
    {
      IMG: banana8
    },
    {
      IMG: banana9
    },
    {
      IMG: banana10
    },
  ];
  const coffeeImg = [
    {
      IMG: coffee1
    },
    {
      IMG: coffee2
    },
    {
      IMG: coffee3
    },
    {
      IMG: coffee4
    },
    {
      IMG: coffee5
    },
    {
      IMG: coffee6
    },
    {
      IMG: coffee7
    },
    {
      IMG: coffee8
    },
    {
      IMG: coffee9
    },
    {
      IMG: coffee10
    },
  ];
  const bananaImgSec = [
    {
      IMG: shopDetail8
    },
    {
      IMG: shopDetail9
    },
    {
      IMG: shopDetail10
    },
    {
      IMG: shopDetail11
    },
    {
      IMG: shopDetail12
    },
    {
      IMG: shopDetail13
    },
    {
      IMG: shopDetail14
    },
    {
      IMG: shopDetail15
    }
  ];
  const coffeeImgSec = [
    {
      IMG: shopDetail8
    },
    {
      IMG: shopDetail9
    },
    {
      IMG: shopDetail17
    },
    {
      IMG: shopDetail16
    },
    {
      IMG: shopDetail12
    },
    {
      IMG: shopDetail13
    },
    {
      IMG: shopDetail14
    },
    {
      IMG: shopDetail15
    }
  ];
  useEffect(() => {
    id === '1' ? setImgOnPage(bananaImg) : setImgOnPage(coffeeImg);
    id === '1' ? setImgSecOnPage(bananaImgSec) : setImgSecOnPage(coffeeImgSec);
    AOS.init()
  }, [])
  return (
    <div>
      <Grid>
        {id && parseInt(id) === 1 ? (<BannerDetailBanana />) : (<BannerDetailCoffee />)}
      </Grid>
      <Grid container>
        {
          imgOnPage.map((IMGS) => (
            <img src={IMGS.IMG} alt={IMGS.IMG} width={"100%"} />
          ))
        }
      </Grid>
      <div style={{ background: '#afdeee', overflow: 'hidden' }}>
        <div style={{ position: 'relative', zIndex: 1 }}>
          <div className="cloud-x2">
            <div className="cloud2"></div>
          </div>
          <div className="cloud-x1">
            <div className="cloud1"></div>
          </div>
        </div>
        <div style={{ position: 'relative', maxWidth: "1240px", margin: "0 auto" }}>
          <Box sx={{
            background: '#afdeee',
            margin: 'auto',
            left: 0,
            right: 0,
            textAlign: 'center',
            maxWidth: "1440px"
          }}>
            <Imgtext data-aos="zoom-in" data-aos-duration="2000" style={{ zIndex: "99" }} src={LogoX1}></Imgtext>
            <Text64px className='Franchise' data-aos="zoom-in" data-aos-duration="1000" >หอม อร่อย ทานง่าย</Text64px>
            <Text64px className='Franchise' data-aos="zoom-in" data-aos-duration="1000" >อิ่มนานขึ้น หุ่นลีนกระชับ</Text64px>
            <Text64px className='Franchise' data-aos="zoom-in" data-aos-duration="1000" >
              <Imgtext src={EmoTitle} ></Imgtext>
            </Text64px>
            <Imgitem data-aos="fade-up-right" data-aos-duration="1000" src={SnackBlack} ></Imgitem>
            <Imgitem data-aos="fade-up" data-aos-duration="1000" src={Emo} className='dukdug' ></Imgitem>
            <Imgitem data-aos="fade-up-left" data-aos-duration="1000" src={SnackYellow}></Imgitem>
            <TextProd data-aos="fade-up-left" data-aos-duration="1000">
              รสกาแฟ <br />
              เอสเปรสโซ่
            </TextProd>
            <TextProd2 data-aos="fade-up-right" data-aos-duration="1000">
              รสช็อกโกแลต <br /> กล้วยหอม
            </TextProd2>
          </Box>
        </div>
        <Grid data-aos="fade-up" data-aos-duration="1000" sx={{ position: "relative", height: { xs: "100px", sm: "200px" } }}>
          <BuyButton variant="contained">
            สั่งซื้อ
          </BuyButton>
        </Grid>
        <Grid container justifyContent={"center"} sx={{ position: "relative" }}>
          <Cup src={cup} data-aos="fade-up" data-aos-duration="4000" />
          <Grid sx={{ position: "absolute", bottom: "0", backgroundColor: "#231f20", width: "100%", height: "21%", zIndex: "0" }}>
          </Grid>
          <TreeLeft src={tree} data-aos="fade-up-right" data-aos-duration="1000" />
          <TreeRigth src={tree} data-aos="fade-up-left" data-aos-duration="1000" />
          <Lungmai src={Langmai} data-aos="fade-up-left" data-aos-duration="1000" />
        </Grid>
      </div>
      <Grid container>
        {
          imgSecOnPage.map((IMGS) => (
            <img src={IMGS.IMG} alt={IMGS.IMG} width={"100%"} />
          ))
        }
      </Grid>
      <Grid>
        {id && parseInt(id) === 2 ? (<BannerDetailBanana />) : (<BannerDetailCoffee />)}
      </Grid>
    </div>

  )
}

export default ProductDetail