import { useEffect, useState, FormEvent } from "react";
import Cookies from "universal-cookie";
import Swal from 'sweetalert2';
import { gauvainAPI } from "../../api/gauvain";

// MUI
import { Box, Divider, Modal, Typography, Grid, TextField, Button } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 1200,
    backgroundColor: 'white',
    borderRadius: 2,
    p: 3,
    maxHeight: '90dvh',
    overflow: 'hidden',
    overflowY: 'scroll'
};

const ModalAddAddress = (props:any) => {
    const cookies = new Cookies(null, { path: '/' });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        setIsOpen(props.isOpenModal);
    }, [props.isOpenModal]);

    const handleClose = () => {
        props.setIsOpenModal(false);
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const data = new FormData(event.currentTarget);

            const name = data.get(`name`);
            const phone = data.get(`phone`);
            const address = data.get(`address`);
            const alley = data.get(`alley`);
            const road = data.get(`road`);
            const subdistrict = data.get(`subdistrict`);
            const district = data.get(`district`);
            const province = data.get(`province`);
            const zipcode = data.get(`zipcode`);
            
            const api = new gauvainAPI();
            const addAddress = await api.addAddress(cookies.get(`access_token`), {
                name,
                address,
                alley,
                road,
                subdistrict,
                district,
                province,
                zipcode
            }, String(phone));

            if(addAddress.data.status_code !== undefined && addAddress.data.status_code === 200)
            {
                Swal.fire({
                    title: `แจ้งเตือน`,
                    text: "เพิ่มที่อยู่ใหม่เรียบร้อยแล้ว",
                    confirmButtonText: `ตกลง`,
                    icon: `success`
                }).then(() => {
                    handleClose();
                    props.setIsOpenModalAddress(true);
                });
            }
            else
            {
                Swal.fire({
                    title: `แจ้งเตือน`,
                    text: "เพิ่มที่อยู่ใหม่ไม่สำเร็จเนื่องจากเกิดข้อผิดพลาดไม่ทราบสาเหตุ",
                    confirmButtonText: `ตกลง`,
                    icon: `error`
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={
                {
                    ...style,
                    color: 'black'
                }
            } component={`form`} onSubmit={handleSubmit}>
                <Box display={`flex`}>
                    <Box width={`100%`} height={`100%`} display={`flex`} justifyContent={`flex-start`} alignItems={`center`} mb={1}>
                        <Typography variant="h5">
                            จัดการที่อยู่
                        </Typography>
                    </Box>
                    <Box width={`100%`} height={`100%`} display={`flex`} justifyContent={`flex-end`} alignItems={`center`} mb={1}>
                        <Button sx={
                            {
                                backgroundColor: `#40baab`,
                                color: `white`,
                                px: 3,
                                '&:hover': {
                                    backgroundColor: `#2e867c`
                                }
                            }
                        } onClick={() => {
                            handleClose();
                            props.setIsOpenModalAddress(true);
                        }}>
                            เลือกที่อยู่ที่มีอยู่แล้ว
                        </Button>
                    </Box>
                </Box>
                <Divider />
                <Grid container mt={2} spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            required
                            fullWidth
                            id="name"
                            label="ชื่อ-นามสกุล"
                            name="name"
                            autoComplete="name"
                            type='text'
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            required
                            fullWidth
                            id="phone"
                            label="เบอร์โทรศัพท์มือถือ"
                            name="phone"
                            autoComplete="phone"
                            type='text'
                            inputProps={{ maxLength: 12 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            required
                            fullWidth
                            id="address"
                            label="ที่อยู่"
                            name="address"
                            autoComplete="address"
                            type='text'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            required
                            fullWidth
                            id="alley"
                            label="ตรอก/ซอย"
                            name="alley"
                            autoComplete="alley"
                            type='text'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            required
                            fullWidth
                            id="road"
                            label="ถนน"
                            name="road"
                            autoComplete="road"
                            type='text'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            required
                            fullWidth
                            id="subdistrict"
                            label="แขวง/ตำบล"
                            name="subdistrict"
                            autoComplete="subdistrict"
                            type='text'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            required
                            fullWidth
                            id="district"
                            label="เขต/อำเภอ"
                            name="district"
                            autoComplete="district"
                            type='text'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            required
                            fullWidth
                            id="province"
                            label="จังหวัด"
                            name="province"
                            autoComplete="province"
                            type='text'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                            required
                            fullWidth
                            id="zipcode"
                            label="รหัสไปรษณีย์"
                            name="zipcode"
                            autoComplete="zipcode"
                            type='text'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={
                                {
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: "#FECE08",
                                    ':hover': {
                                        backgroundColor: "#000",
                                        color: `white`
                                    }
                                }
                            }
                        >
                            เพิ่มที่อยู่ใหม่
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default ModalAddAddress;