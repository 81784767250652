import React, { useEffect, useState } from "react";
import { Box, Button, styled } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Grid,
} from "@mui/material";
import { useAdmin } from "./context/AdminContextProvider";
import AccountDetail from "./component/AccountDetail";

const MainContainer = styled("main")({
  width: "98%",
  padding: "16px",
  margin: "0 auto",
  boxSizing: "border-box",

  "@media (max-width: 600px)": {
    padding: "8px",
    width: "100%",
  },
});

const StyledTableContainer: any = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  marginTop: theme.spacing(2),
  overflowX: "auto",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
  "& thead th": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  "& tbody tr:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& tbody tr:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

export default function Account() {
  const { fetchAllMembers, allMembers } = useAdmin();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [togglePage, setTogglePagge] = useState(false);
  const [itemDetail, setItemDetail] = useState([]);

  const handlePageOpen = () => setTogglePagge(true);
  const handlePageClose = () => setTogglePagge(false);

  useEffect(() => {
    fetchAllMembers();
  }, []);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedRows = allMembers?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <MainContainer>
      {!togglePage && (
        <>
          <Box
            display="flex"
            gap={2}
            paddingY={2}
            marginY={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              maxWidth: 260,
              height: 32,
              fontSize: "1.8rem",
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <Typography>ตารางผู้ใช้งาน</Typography>
          </Box>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead sx={{ bgcolor: "#d1d1d1" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                    width={160}
                  >
                    ชื่อ
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                    width={100}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    สร้างเมื่อ
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    รายละเอียด
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedRows?.map((member: any, index: number) => (
                  <TableRow
                    key={member.id}
                    sx={{
                      bgcolor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                      "&:hover": { bgcolor: "#f1f1f1" },
                    }}
                  >
                    <TableCell sx={{ textAlign: "center" }}>
                      {member.id}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {member.name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {member.email}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {member.role}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {new Date(member.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            paddingX: 2,
                            paddingY: 1,
                            cursor: "pointer",
                            width: 84,
                            borderRadius: 16,
                            bgcolor: "#235aad",
                            color: "white",
                            boxShadow: 1,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handlePageOpen();
                            setItemDetail(member);
                          }}
                        >
                          แสดง
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allMembers?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledTableContainer>
        </>
      )}
      {togglePage && <AccountDetail item={itemDetail} back={handlePageClose} />}
    </MainContainer>
  );
}
