"use client";
import { Button, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { gauvainAPI } from "../../api/gauvain";
import Cookies from "universal-cookie";
import axios from "axios";

// Define the possible statuses
type PaymentStatus = "open" | "complete" | null;

// Define the shape of the response data
interface ResponseData {
  status: PaymentStatus;
  customer_email?: string;
  paymentId?: string;
}

const Return: React.FC = () => {
  const [status, setStatus] = useState<PaymentStatus>(null);
  const [paymentId, setPaymentId] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const cookies = new Cookies();
  const api = new gauvainAPI();

  const getStatus = async () => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get("session_id");
      const orderId = urlParams.get("orderId");

      if (!sessionId || !orderId) {
        throw new Error("Invalid session or order ID.");
      }

      const data = { sessionId, orderId };
      const response = await api.getStatus(cookies.get("access_token"), data);

   
      const refRes: any = response;
      const responseData: ResponseData = refRes.data;

      setStatus(responseData.status);
      if (responseData.paymentId) {
        setPaymentId(responseData.paymentId);
      }
    } catch (err) {
      console.error(err);
      setError(
        "An error occurred while retrieving payment status. Please try again."
      );
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  if (error) {
    return (
      <Box
        sx={{
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          px: 2,
          width: "80vw",
          margin: "auto",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {

    return (
      <Box
        id="success"
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          px: 2,
          width: "80vw",
          margin: "auto",
        }}
      >
        <Typography variant="h6" component="p" gutterBottom>
          ขอบคุณที่ไว้วางใจเลือกใช้เครื่องดื่มโปรตีนของเรา
          เรามุ่งมั่นที่จะมอบผลิตภัณฑ์ที่มีคุณภาพสูงเพื่อช่วยเสริมสร้างสุขภาพและพลังงานให้กับคุณในทุกวัน
          หากคุณมีคำถามหรือข้อเสนอแนะเพิ่มเติม โปรดอย่าลังเลที่จะติดต่อเรา
          เราพร้อมที่จะสนับสนุนคุณในทุกก้าวของการดูแลสุขภาพ
          ขอบคุณที่ให้เราเป็นส่วนหนึ่งของการดูแลสุขภาพที่ดีของคุณ!
          <Box padding={2}>
            <Typography variant="subtitle1" sx={{ color: "#b1b1b1" }}>
              กรุณาเก็บรหัสอ้างอิงนี้ไว้
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#1e88e5" }}>
              {paymentId}
            </Typography>
          </Box>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography component="span">
            คำสั่งซื้อของคุณเสร็จเรียบร้อยแล้ว เราจะนำจัดส่งให้ท่านภายใน 1 - 2
            วัน
          </Typography>
          <Typography component="span">
            กรุณาเช็คอีเมล
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexDirection: "column",
        px: 2,
        width: "80vw",
        margin: "auto",
      }}
    >
      <Typography variant="body1">กำลังตรวจสอบสถานะการชำระเงิน...</Typography>
    </Box>
  );
};

export default Return;
