"use client";

import React, { useState, useContext, createContext, ReactNode } from "react";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../api/gauvain";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// Define the context value interface with proper types
interface PaymentContextValue {
  fnCreateOrder: (data: any, isCOD: any) => Promise<void>;
  orderId: number | null;
  fnCreateTransaction: () => Promise<any>;
  handleTotalPrice:any
  totalPrice:any
  handleTotalItem:any
  item:any
}

const PaymentContext = createContext<PaymentContextValue | undefined>(
  undefined
);

interface PaymentContextProviderProps {
  children: ReactNode;
}

const PaymentContextProvider: React.FC<PaymentContextProviderProps> = ({
  children,
}) => {
  const cookies = new Cookies();
  
  const token = cookies.get("access_token");
  const navigate = useNavigate();

  const [orderId, setOrderId] = useState<number | null>(null);

  const [totalPrice,setTotalPrice] = useState()
  const [item,setItem] = useState()

  const handleTotalPrice = (total:any) => setTotalPrice(total)
  const handleTotalItem = (item:any) => setItem(item)

  const fnCreateOrder = async (data: any, isCOD: any): Promise<void> => {
    try {
      const api = new gauvainAPI();
      if (isCOD) {
        data.unshift(totalPrice)
        const response = await api.createOrderIsCOD(token, data);
        await api.createQuota(token)
        Swal.fire({
          title: `แจ้งเตือน`,
          text: "เรียบร้อยแล้ว กรุณาเช็คอีเมล",
          confirmButtonText: `ตกลง`,
          icon: `success`,
         
        });
        
        navigate("/");
      } else {
        data.unshift(totalPrice)
        const response = await api.createOrder(token, data);
        const newOrderId = response.data?.result?.insertId;
        if (newOrderId) {
          setOrderId(newOrderId);
          await api.createQuota(token)
          navigate("/checkout/payment");
        } else {
          throw new Error("Failed to create order. No Order ID returned.");
        }
      }
    } catch (err) {
      console.error("Error creating order:", err);
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "กรุณาเพิ่มที่อยู่ก้อนสั่งซื้อสินค้า",
        confirmButtonText: `ตกลง`,
        icon: `info`,
       
      });
    }
  };

  const fnCreateTransaction = async (): Promise<any> => {
    if (!orderId) {
      throw new Error("Order ID is required to create a transaction.");
    }
    try {
      const api = new gauvainAPI();
      const response = await api.createPayment(token, { orderId });
      return response;
    } catch (err) {
      console.error("Error creating transaction:", err);
      // Handle errors with notifications or retries as needed
      throw err; // Re-throw error to be handled by the caller
    }
  };

  return (
    <PaymentContext.Provider
      value={{ fnCreateOrder, orderId, fnCreateTransaction,handleTotalPrice ,totalPrice,handleTotalItem ,item}}
    >
      {children}
    </PaymentContext.Provider>
  );
};

// Custom hook to use the PaymentContext
const usePayment = (): PaymentContextValue => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error("usePayment must be used within a PaymentContextProvider");
  }
  return context;
};

export { PaymentContextProvider, usePayment };
