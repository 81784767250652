import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  styled,
  FormHelperText,
  Grid,
} from "@mui/material";
import LineChart from "./LineChart";
import LineChartMonth from "./LineChartMonth";
import { useAdmin } from "../context/AdminContextProvider";

const FlexContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "64px",
  paddingTop: "40px",
  "@media (min-width: 768px)": {
    flexDirection: "column",
  },
});

const TotalSalesText = styled(Typography)({
  fontSize: "1rem",
  textAlign: "center",
  "@media (min-width: 768px)": {
    fontSize: "0.875rem",
  },
});

const CustomSelect = styled(Select)<{ error?: boolean }>(({ error }) => ({
  borderBottom: `2px solid ${error ? "red" : "transparent"}`,
  "& .MuiSelect-icon": {
    color: error ? "red" : "inherit",
  },
}));

const thaiMonths = [
  "มกราคม",  // January
  "กุมภาพันธ์", // February
  "มีนาคม",    // March
  "เมษายน",    // April
  "พฤษภาคม",   // May
  "มิถุนายน",   // June
  "กรกฎาคม",   // July
  "สิงหาคม",    // August
  "กันยายน",    // September
  "ตุลาคม",     // October
  "พฤศจิกายน",  // November
  "ธันวาคม"     // December
];




export default function LineChartDashBoard() {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const getCurrentQuarter = () => {
    const currentMonth = new Date().getMonth() + 1;
    return Math.ceil(currentMonth / 3);
  };

  const {
    fetchDataByMonth,
    fetchData,
    saleData,
    saleDataMonth,
    fetchDataByQuarter,
    saleDataQuarter,
    fetchDataByBetween,
    saleDataBetween,
  } = useAdmin();

  const [selectedMonth, setSelectedMonth] = useState<number>(currentMonth);
  const [startMonth, setStartMonth] = useState<number>(
    currentMonth == 1 ? 12 : currentMonth - 1
  );
  const [endMonth, setEndMonth] = useState<number>(currentMonth);

  const [selectedYear, setSelectedYear] = useState<number>(currentYear);
  const [selectedYear2, setSelectedYear2] = useState<number>(currentYear);
  const [selectedYear3, setSelectedYear3] = useState<number>(currentYear);
  const [selectedYear4, setSelectedYear4] = useState<number>(currentYear);
  const [selectedYear5, setSelectedYear5] = useState<number>(currentYear);

  const [selectedQuarter, setSelectedQuarter] =
    useState<number>(getCurrentQuarter);
  const [hasError, setHasError] = useState<boolean>(false);

  const years = Array.from({ length: 16 }, (_, index) => currentYear - index);

  useEffect(() => {
    fetchData(selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    fetchDataByMonth(selectedMonth, selectedYear2);
  }, [selectedMonth, selectedYear2]);

  useEffect(() => {
    fetchDataByQuarter(selectedQuarter, selectedYear3);
  }, [selectedQuarter, selectedYear3]);

  useEffect(() => {
    fetchDataByBetween(startMonth, endMonth, selectedYear4,selectedYear5);
  }, [startMonth, endMonth, selectedYear4,selectedYear5]);

  const handleYearChange = (
    event: React.ChangeEvent<{ value: any }>,
    setYear: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setYear(event.target.value as number);
  };

  const handleMonthChange = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedMonth(event.target.value as number);
    setHasError(false);
  };

  const handleStartMonthChange = (event: React.ChangeEvent<{ value: any }>) => {
    setStartMonth(event.target.value as number);
  };

  const handleEndtMonthChange = (event: React.ChangeEvent<{ value: any }>) => {
    setEndMonth(event.target.value as number);
  };

  const handleQuarterChange = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedQuarter(event.target.value as number);
  };

  const calculateTotal = (data: any[], key: string) => {
    return data?.reduce((acc: number, item: any) => acc + (item[key] ?? 0), 0);
  };

  const calculateTotalQuantity = (data: any[], key: string) => {
    return data?.reduce(
      (acc: number, item: any) =>
        acc + (item[key]?.["1"]?.quantity + item[key]?.["2"]?.quantity ?? 0),
      0
    );
  };

  const totalYear = calculateTotal(saleData, "total");
  const totalMonth = calculateTotal(saleDataMonth, "total");
  const totalQuarter = calculateTotal(saleDataQuarter, "total");
  const totalBetween = calculateTotal(saleDataBetween, "total");

  const totalQuantityBetween = calculateTotalQuantity(
    saleDataBetween,
    "products"
  );
  const totalQuantity = calculateTotalQuantity(saleData, "products");
  const totalQuantityMonth = calculateTotalQuantity(saleDataMonth, "products");
  const totalQuantityQuarter = calculateTotalQuantity(
    saleDataQuarter,
    "products"
  );

  return (
    <FlexContainer>
      <Box>
        <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
          >
            ยอดขายปี
          </Typography>
          <FormControl>
            <Select
              labelId="year-select-label"
              value={selectedYear}
              onChange={(e: any) => handleYearChange(e, setSelectedYear)}
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          gap={2}
          paddingTop={2}
          paddingBottom={2}
          marginTop={2}
          marginBottom={2}
          sx={{
            border: "1px solid gray",
            maxWidth: "340px",
            justifyContent: "center",
            borderRadius: "12px",
          }}
        >
          {totalYear === 0 ? (
            <Typography sx={{ fontSize: { xs: "0.975rem", sm: "1.2rem" } }}>
              ในปี {selectedYear} ไม่มียอดขาย
            </Typography>
          ) : (
            <>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                ยอดรวมทั้งหมด
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                {totalYear?.toLocaleString()} บาท
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                {totalQuantity} ชิ้น
              </Typography>
            </>
          )}
        </Box>
        {saleData && (
          <Box sx={{ minHeight: "440px", overflow: "auto" }}>
            <LineChart data={saleData} />
          </Box>
        )}
      </Box>

      <Box>
        <Box display="flex" gap={2} alignItems="center">
          <Typography
            variant="h5"
            sx={{ fontSize: { xs: "0.975rem", sm: "1.2rem" } }}
          >
            ยอดขายเดือน
          </Typography>
          <FormControl
            variant="outlined"
            sx={{ minWidth: 120 }}
            error={hasError}
          >
            <CustomSelect
              labelId="month-select-label"
              value={selectedMonth}
              onChange={(e: any) => handleMonthChange(e)}
              error={hasError}
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
            >
              {thaiMonths.map((m, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {m}
                    </MenuItem>
                  ))}
            </CustomSelect>
            {hasError && <FormHelperText>กรุณาเลือกเดือน</FormHelperText>}
          </FormControl>
          <FormControl>
            <Select
              labelId="year-select-label"
              value={selectedYear2}
              onChange={(e: any) => handleYearChange(e, setSelectedYear2)}
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          display="flex"
          gap={2}
          paddingTop={2}
          paddingBottom={2}
          marginTop={2}
          marginBottom={2}
          sx={{
            border: "1px solid gray",
            maxWidth: "340px",
            justifyContent: "center",
            borderRadius: "12px",
          }}
        >
          {totalMonth === 0 ? (
            <TotalSalesText>ในเดือนนี้ไม่มียอดขาย</TotalSalesText>
          ) : (
            <>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                ยอดรวมทั้งหมด
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                {totalMonth?.toLocaleString()} บาท
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                {totalQuantityMonth} ชิ้น
              </Typography>
            </>
          )}
        </Box>

        {saleDataMonth && (
          <Box sx={{ minHeight: "440px", overflow: "auto" }}>
            <LineChartMonth data={saleDataMonth} />
          </Box>
        )}
      </Box>
      <Box>
        <Box display="flex" gap={2} alignItems="center">
          <Grid container display={"flex"} spacing={2} alignItems={"center"}>
            <Grid
              item
           
            >
              <Typography
                variant="h5"
                sx={{ fontSize: { xs: "0.975rem", sm: "1.2rem" } }}
              >
                ยอดขายเดือน
              </Typography>
            </Grid>
            <Grid item >
              <FormControl
                variant="outlined"
                sx={{ minWidth: 120, marginRight:2 }}
                error={hasError}
              >
                <CustomSelect
                  labelId="month-select-label"
                  value={startMonth}
                  onChange={(e: any) => handleStartMonthChange(e)}
                  error={hasError}
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                >
                  {thaiMonths.map((m, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {m}
                    </MenuItem>
                  ))}
                </CustomSelect>
                {hasError && <FormHelperText>กรุณาเลือกเดือน</FormHelperText>}
              </FormControl>

              <FormControl>
                <Select
                  labelId="year-select-label"
                  value={selectedYear5}
                  onChange={(e: any) => handleYearChange(e, setSelectedYear5)}
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }}}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography>to</Typography>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                sx={{ minWidth: 120 }}
                error={hasError}
              >
                <CustomSelect
                  labelId="month-select-label"
                  value={endMonth}
                  onChange={(e: any) => handleEndtMonthChange(e)}
                  error={hasError}
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } , marginRight:2}}
                >
                  {thaiMonths.map((m, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {m}
                    </MenuItem>
                  ))}
                </CustomSelect>
                {hasError && <FormHelperText>กรุณาเลือกเดือน</FormHelperText>}
              </FormControl>
              <FormControl>
                <Select
                  labelId="year-select-label"
                  value={selectedYear4}
                  onChange={(e: any) => handleYearChange(e, setSelectedYear4)}
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box
          display="flex"
          gap={2}
          paddingTop={2}
          paddingBottom={2}
          marginTop={2}
          marginBottom={2}
          sx={{
            border: "1px solid gray",
            maxWidth: "340px",
            justifyContent: "center",
            borderRadius: "12px",
          }}
        >
          {totalBetween === 0 ? (
            <TotalSalesText>ในเดือนนี้ไม่มียอดขาย</TotalSalesText>
          ) : (
            <>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                ยอดรวมทั้งหมด
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                {totalBetween?.toLocaleString()} บาท
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                {totalQuantityBetween} ชิ้น
              </Typography>
            </>
          )}
        </Box>

        {saleDataBetween && (
          <Box sx={{ minHeight: "440px", overflow: "auto" }}>
            <LineChart data={saleDataBetween} />
          </Box>
        )}
      </Box>

      <Box>
        <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={{ fontSize: { xs: "0.975rem", sm: "1.2rem" } }}
          >
            ยอดขายไตรมาส
          </Typography>
          <FormControl>
            <Select
              labelId="quarter-select-label"
              value={selectedQuarter}
              onChange={(e: any) => handleQuarterChange(e)}
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
            >
              {Array.from({ length: 4 }, (_, index) => index + 1).map(
                (quarter) => (
                  <MenuItem key={quarter} value={quarter}>
                    {`ไตรมาสที่ ${quarter}`}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>

          <FormControl>
            <Select
              labelId="year-select-label"
              value={selectedYear3}
              onChange={(e: any) => handleYearChange(e, setSelectedYear3)}
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          gap={2}
          paddingTop={2}
          paddingBottom={2}
          marginTop={2}
          marginBottom={2}
          sx={{
            border: "1px solid gray",
            maxWidth: "340px",
            justifyContent: "center",
            borderRadius: "12px",
          }}
        >
          {totalQuarter === 0 ? (
            <TotalSalesText>
              ในไตรมาสที่ {selectedQuarter} ไม่มียอดขาย
            </TotalSalesText>
          ) : (
            <>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                ยอดรวมทั้งหมด
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                {totalQuarter?.toLocaleString()} บาท
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.775rem", sm: "1rem" } }}>
                {totalQuantityQuarter} ชิ้น
              </Typography>
            </>
          )}
        </Box>
        {saleDataQuarter && (
          <Box sx={{ minHeight: "440px", overflow: "auto" }}>
            <LineChart data={saleDataQuarter} />
          </Box>
        )}
      </Box>
    </FlexContainer>
  );
}
