import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Emo from "../assets/images/emo.png";

import toast, { Toaster } from 'react-hot-toast';
import { gauvainAPI } from '../api/gauvain';
import Swal from 'sweetalert2';

type Props = {}
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Gauvain
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const defaultTheme = createTheme();

function Register({ }: Props) {
    const navigate = useNavigate();

    const [processing, setProcessing] = React.useState<boolean>(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const name:any = data.get('name');
        const password:any = data.get('password');
        const confirmPassword:any = data.get('confirmpassword');
        const email:any = data.get('email');

        if(name === undefined || name === '')
        {
            return toast.error('กรุณากรอกชื่อของคุณ');
        }
        else if(email === undefined || email === '')
        {
            return toast.error('กรุณากรอกอีเมลล์');
        }
        else if(password === undefined || password === '')
        {
            return toast.error('กรุณากรอกรหัสผ่าน');
        }
        else if(password.length < 8)
        {
            return toast.error('รหัสผ่านจำเป็นต้องมีมากกว่าหรือเท่ากับ 8 ตัวอักษร');
        }
        else if(confirmPassword === undefined || confirmPassword === '')
        {
            return toast.error('กรุณากรอกยืนยันรหัสผ่าน');
        }
        else if(password !== confirmPassword)
        {
            return toast.error('รหัสผ่านของคุณไม่ตรงกัน');
        }
        else if(!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            return toast.error('อีเมลล์ของคุณไม่ถูกต้อง');
        }
        
        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        //     name: data.get('name'),
        //     confirmpassword: data.get('confirmpassword')
        // });

        try {
            setProcessing(true);
            const api = new gauvainAPI();
            const register = await api.register({
                name: name,
                password: password,
                email: email
            });
            
            if(register.data.status_code === 200)
            {
                Swal.fire({
                    title: `แจ้งเตือน`,
                    text: "สมัครสมาชิกเรียบร้อยแล้ว",
                    confirmButtonText: `ตกลง`,
                    icon: `success`,
                  });
                // toast.success('สมัครสมาชิกเรียบร้อยแล้ว');

                setTimeout(()=>{

                    navigate('/login');
                },2500)
            }
            else
            {
                setProcessing(false);
                return toast.error(register.data.message !== undefined && register.data.message !== null ? register.data.message : "เกิดข้อผิดพลาดไม่ทราบสาเหตุ")
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Toaster />
            <Container component="main" maxWidth="xs" >
                <img src={Emo} alt="emo" style={{ width: "20%", position: "absolute", right: "0", top: "5%" }} />
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Typography component="h1" variant="h5">
                    Sign Up
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        type='email'
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmpassword"
                        label="confirmPassword"
                        type="password"
                        id="confirmpassword"
                        autoComplete="current-confirmpassword"
                    />
                    <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "#FECE08", ':hover': {
                                backgroundColor: "#000"
                            }
                        }}
                        disabled={processing}
                    >
                        Sign Up
                    </Button>
                    <Grid container>
                    <Grid item>
                        <Link href="/login" variant="body2">
                        {"have an account? Sign In"}
                        </Link>
                    </Grid>
                    </Grid>
                </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    )
}

export default Register