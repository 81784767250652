import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/logo_x1.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Hamburger from 'hamburger-react';
import snack from '../../assets/images/snack_black90deg.png';
import bean from '../../assets/images/smallbean.png';
import beanlong from '../../assets/images/beanbig.png';
import { gauvainAPI } from '../../api/gauvain';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './css/Swiper.css';

// import required modules
import { Pagination } from 'swiper/modules';

type Props = {}
const NavbarComponent = styled(Grid)(({ theme }) => ({
    backgroundColor: "#FECE08",
    justifyContent: "space-around",
    padding: "24px 0",
    maxWidth: "1450px",
    margin: "0 auto",
    alignItems: "center"
}));
const Logo = styled("img")(({ theme }) => ({
    cursor: 'pointer',
    [theme.breakpoints.up("xs")]: {
        width: "140px",
        height: "40px"

    },
    [theme.breakpoints.up("sm")]: {
        width: "250px",
        height: "auto"

    },
}));
const LinkMenuList = styled(Grid)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.up("xs")]: {
        width: "130px",
    },
    [theme.breakpoints.up("sm")]: {
        width: "250px",

    },
    [theme.breakpoints.up("md")]: {
        width: "70%",

    },

}));
const LinkMenu = styled(Link)(({ theme }) => ({
    borderRadius: "12px",
    color: "#FFF",
    textDecoration: "none",
    fontWeight: "800",
    fontSize: "20px"
}));
const Menu = styled(Grid)(({ theme }) => ({

}));
const Prod = styled(Grid)(({ theme }) => ({
    backgroundColor: "#eeeded",
    justifyContent: "center",
    alignItems: "center",
}));
const LinkMenuListMobile = styled(Grid)(({ theme }) => ({
    flexDirection: "column"
}));
const LinkMenuMobile = styled(Link)(({ theme }) => ({
    color: "#FFF",
    textDecoration: "none",
    fontWeight: "800",
    padding: "12px 0",
    fontSize: "20px",
    marginLeft: "24px"
}));
const Card = styled(Grid)(({ theme }) => ({
    height: "230px",
    border: "1px solid #DADADA",
    borderRadius: "12px",
    width: "200px",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    overflow: "hidden",
    zIndex: "1",
    margin: "24px 0"
}));
const ProdImg = styled("img")(({ theme }) => ({
    width: "100px",
    zIndex: "1"
}));
const Beanimg = styled("img")(({ theme }) => ({
    width: "40px",
    position: "absolute",
    left: "0",
    top: "50%",
    zIndex: "0",

}));
const Beanimg3 = styled("img")(({ theme }) => ({
    width: "40px",
    position: "absolute",
    left: "0",
    top: "32px",
    zIndex: "0",

}));
const Beanimg1 = styled("img")(({ theme }) => ({
    width: "35%",
    position: "absolute",
    right: "0",
    top: "0",
    rotate: "80deg",

}));
const Beanimg2 = styled("img")(({ theme }) => ({
    width: "50px",
    position: "absolute",
    right: "-6px",
    top: "50px",
    rotate: "100deg",

}));
const Beanimglong = styled("img")(({ theme }) => ({
    width: "35%",
    position: "absolute",
    right: "12px",
    top: "50%",
    rotate: "265deg",

}));
const NameProd = styled("p")(({ theme }) => ({
    color: "#56331F",
    margin: "0",
    zIndex: 10
}));
function Navbar2({ }: Props) {
    const { pathname } = useLocation()
    const Navigater = useNavigate()
    const [isOpen, setOpen] = useState(false)
    const LinkLists = [
        {
            path: "/",
            pagename: "HOME"
        },
        {
            path: "/shop",
            pagename: "SHOP"
        },
        {
            path: "/news",
            pagename: "NEWS"
        },
        {
            path: "/aboutus",
            pagename: "ABOUT US"
        },
        {
            path: "/contactus",
            pagename: "CONTACT US"
        },
    ]
    const active = {
        color: "#FECE08",
        backgroundColor: "#FFF",
        borderRadius: "24px",
        padding: "6px 12px",
        display: { xs: "none", md: "block" }

    }
    const notactive = {
        color: "#FFF",
        display: { xs: "none", md: "block" }
    }

    const [productList, setProductList] = useState<any>([]);

    useEffect(() => {
        (async () => {
            try {
                const api = new gauvainAPI();
                const allProductsTest: any = await api.getAllProducts();

                if (allProductsTest.data.status_code !== undefined && allProductsTest.data.status_code === 200) {
                    const temp_productList: any = [];

                    await Promise.all(allProductsTest.data.products.map((product: any) => {
                        let temp_product = product;
                        temp_product.quantity = 0;

                        temp_productList.push(temp_product);
                    }));

                    setProductList(temp_productList);
                }
            } catch (err) {
                console.log(err);
            }
        })()
    }, []);

    return (
        <header style={{ backgroundColor: "#FECE08" }}>
            <NavbarComponent container>
                <Logo src={logo} onClick={() => {
                    Navigater("/")
                    setOpen(false)

                }} />
                <LinkMenuList container>
                    {
                        LinkLists.map((menu, idx) => (
                            <LinkMenu to={menu.path} key={idx} sx={pathname === menu.path ? active : notactive} >
                                {menu.pagename}
                            </LinkMenu>
                        ))
                    }
                    <Box sx={{ width: "4px", backgroundColor: "#FFF", height: "50%", display: { xs: "none", md: "block" } }}></Box>
                    <Link to={"/login"}>
                        <PersonIcon sx={{ color: "#FFF", fontSize: "32px" }} />
                    </Link>
                    <Link to={`/checkout`} onClick={() => setOpen(false)}>
                        <ShoppingCartIcon sx={{ color: "#FFF", fontSize: "32px" }} />
                    </Link>
                    <IconButton sx={{ display: { xs: "block", md: "none" } }}>
                        <Hamburger toggled={isOpen} toggle={setOpen} color='#FFF' />
                    </IconButton>
                </LinkMenuList>
            </NavbarComponent>
            {
                isOpen ? (
                    <Menu>
                        <Prod container className='BGPaper'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            // className="mySwiper"
                        >
                            {
                                productList.map((product:any, index:number) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <Card container className='fromtobutom'>
                                                <ProdImg src={product.image.replace(`{api_path}`, process.env.REACT_APP_API_URL)} />
                                                <NameProd>
                                                    {
                                                        product.name
                                                    }
                                                </NameProd>
                                                <Button variant="contained"
                                                    sx={{ width: "80%", margin: "0 auto", borderRadius: "24px", color: "#FFF" }}
                                                    onClick={() => {
                                                        Navigater('/shop');
                                                        setOpen(!isOpen)
                                                    }}
                                                >
                                                    สั่งซื้อ
                                                </Button>
                                                <Beanimg src={bean} />
                                                <Beanimg1 src={bean} />
                                                <Beanimglong src={beanlong} />
                                                <Beanimg2 src={bean} />
                                                <Beanimg3 src={bean} />

                                                <div style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    height: "50%",
                                                    width: "100%",
                                                    backgroundColor: product.background_color !== null ? product.background_color : "#8A5834",
                                                    zIndex: "-1"
                                                }}></div>

                                                <div style={{
                                                    position: "absolute",
                                                    bottom: "0",
                                                    height: "50%",
                                                    width: "100%",
                                                    backgroundColor: "#FFFFFF",
                                                    zIndex: "-1"

                                                }}></div>
                                            </Card>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                            
                        </Prod>
                        <LinkMenuListMobile container >
                            {
                                LinkLists.map((menu, idx) => (
                                    <LinkMenuMobile className='fromtoleft' to={menu.path} key={idx} onClick={() => setOpen(!isOpen)} >
                                        {menu.pagename}
                                    </LinkMenuMobile>
                                ))
                            }
                        </LinkMenuListMobile>
                    </Menu>
                ) : (<></>)
            }
        </header>
    )
}

export default Navbar2;