import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { usePayment } from "../../context/PaymentContextProvider";

// สร้าง theme ที่รองรับ responsive font sizes
let theme = createTheme();
theme = responsiveFontSizes(theme);

const TotalPrice = (props: any) => {
  const [productList, setProductList] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);

  const { handleTotalPrice } = usePayment();
  useEffect(() => {
    if (props.productList !== undefined) {
      setProductList(props.productList);
      calTotal(props.productList);
    }
  }, [props.productList]);

  function calculatePrice(totalQuantity: number): number {
    const prices = [555, 1100, 1620, 2120, 2600, 3060, 3500, 3920, 4320, 4700];
    if (totalQuantity >= 1 && totalQuantity <= 10) {
      return prices[totalQuantity - 1];
    } else if (totalQuantity > 10 && totalQuantity < 30) {
      return 4700 + (totalQuantity - 10) * 470;
    } else if (totalQuantity === 30) {
      return 13500;
    } else if (totalQuantity > 30 && totalQuantity < 61) {
      return 13500 + (totalQuantity - 30) * 450;
    } else {
      return 27000 + (totalQuantity - 60) * 450;
    }
  }

  const calTotal = async (data: any) => {
    let totalQuantity: number = 0;

    // รวมจำนวนชิ้นของสินค้าทั้งหมด
    data.forEach((pd: any) => {
      totalQuantity += pd.quantity;
    });

    let temp_total: number = 0;
    let price: number;

    // คำนวณราคาตามขั้นบันได
    price = calculatePrice(totalQuantity);

    let bc500 = 0;
    let cf500 = 0;

    const BC500 = "BC500";
    const CF500 = "CF500";

    data.forEach((pd: any) => {
      if (pd?.product_code == BC500) {
        bc500 = pd?.quantity;
      } else if (pd?.product_code == CF500) {
        cf500 = pd?.quantity;
      }
    });

    const DISCOUNTBC500 = data?.[0]?.discount;
    const DISCOUNTCF500 = data?.[1]?.discount;

    // discount
    const discountBC500 = (calculatePrice(bc500) * DISCOUNTBC500) / 100;
    const discountCF500 = (calculatePrice(cf500) * DISCOUNTCF500) / 100;

    temp_total = Math.ceil(price - discountBC500 - discountCF500);

    setTotal(temp_total);
    handleTotalPrice(temp_total);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const fontSize = isSmallScreen ? 20 : isMediumScreen ? 24 : 28;

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} lg={6} mb={4}>
        <Grid
          item
          bgcolor={"white"}
          color={"#333"}
          borderRadius={"16px"}
          boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.1)"}
          padding={4}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          {productList?.map((pd: any) => (
            <Box
              key={pd?.id}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              position="relative"
              sx={{
                borderBottom: "1px solid #f0f0f0",
                paddingBottom: 2,
                marginBottom: 2,
                "&:last-child": {
                  borderBottom: "none",
                  marginBottom: 0,
                  paddingBottom: 0,
                },
              }}
            >

              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    mb: 0.5,
                  }}
                >
                  {pd?.name}
                </Typography>

                {pd?.discount > 0 && (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    sx={{
                      animation: "flashSale 1.5s infinite",
                      "@keyframes flashSale": {
                        "0%": { transform: "scale(1)" },
                        "50%": { transform: "scale(1.05)" },
                        "100%": { transform: "scale(1)" },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#D32F2F",
                        fontSize: "20px",
                      }}
                    >
                      ฿ {Math.ceil((pd?.price * (100 - pd?.discount)) / 100)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#ff6d6d",
                        backgroundColor: "#fff5f5",
                        padding: "2px 6px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      -{pd?.discount}%
                    </Typography>
                  </Box>
                )}
                <Box display={"flex"} gap={2}>
                  <Box
                    sx={{
                      mt: 1,
                      textAlign: "center",

                      backgroundColor: "#FFD700",
                      color: "black",
                      padding: "4px 12px",
                      borderRadius: "8px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      zIndex: 1, // Ensure the badge stays on top
                    }}
                  >
                    Best Choice
                  </Box>
                  {pd?.discount > 0 && (
                    <Box
                      sx={{
                        // position: "absolute",
                        // top: "-10px",
                        // left: "-10px",
                        mt: 1,
                        textAlign: "center",

                        backgroundColor: "red",
                        color: "white",
                        padding: "4px 12px",
                        borderRadius: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        zIndex: 1, // Ensure the badge stays on top
                      }}
                    >
                      Flash Sale
                    </Box>
                  )}
                </Box>
              </Box>

              <Typography
                sx={{
                  textDecoration: pd?.discount > 0 ? "line-through" : "none",
                  color: pd?.discount > 0 ? "#b1b2b9" : "#333",
                  fontSize: "16px",
                  fontWeight: pd?.discount > 0 ? "normal" : "bold",
                }}
              >
                ฿ {pd?.price}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box
          sx={{
            background: "#FFFFFF", // White background
            border: "solid 1px rgba(200, 200, 200, 1)", // Subtle gray border
            height: 150,
            maxHeight: 150,
            mx: 0.5,
            mb: 2,
            borderRadius: {
              xs: 9,
              sm: 9,
              md: 6,
              lg: 6,
            },
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            transition: "all .3s",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          <Grid
            container
            sx={{
              height: `100%`,
            }}
          >
            <Grid item xs={5.75} sm={5.75} md={5.75} lg={5.75}>
              <Box
                display={`flex`}
                justifyContent={`center`}
                alignItems={`center`}
                sx={{
                  height: `100%`,
                }}
              >
                <Typography
                  fontSize={fontSize}
                  fontWeight={`bold`}
                  sx={{
                    color: "#333",
                  }}
                >
                  รวมทั้งสิ้น
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
              <Box
                display={`flex`}
                justifyContent={`center`}
                alignItems={`center`}
                sx={{
                  height: `100%`,
                }}
              >
                <Box
                  display={`flex`}
                  justifyContent={`center`}
                  alignItems={`center`}
                  sx={{
                    height: `85%`,
                  }}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: "#A78241" }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={5.75} sm={5.75} md={5.75} lg={5.75}>
              <Box
                display={`flex`}
                justifyContent={`center`}
                alignItems={`center`}
                sx={{
                  height: `100%`,
                }}
              >
                <Typography
                  fontSize={fontSize}
                  fontWeight={`bold`}
                  sx={{
                    color: "#D32F2F", // Red color for the total amount
                    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  ฿{" "}
                  {parseFloat(total).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TotalPrice;

{
}
{
  /* <Grid container mt={3} display={"flex"} justifyContent={"end"}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box
  </Grid>
    sx={{
      background:
        "linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255, 0));",
      border: "solid 1px rgba(255, 255, 255, 1)",
      height: 150,
      maxHeight: 150,
      mx: 0.5,
      mb: 2,
      borderRadius: {
        xs: 9,
        sm: 9,
        md: 6,
        lg: 6,
      },
      transition: "all .2s",
      "&:hover": {
        transform: "scale(1.02)",
      },
    }}
  >
    <Grid
      container
      sx={{
        height: '100%',
      }}
    >
      <Grid item xs={5.75} sm={5.75} md={5.75} lg={5.75}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            height: '100%',
          }}
        >
          <Typography
            fontSize={28}
            fontWeight={'bold'}
            sx={{
              color: "#A78241",
              textShadow:
                "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
            }}
          >
            รวมทั้งสิ้น
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            height: '100%',
          }}
        >
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              height: '85%',
            }}
          >
            <Divider orientation="vertical" flexItem color={"white"} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={5.75} sm={5.75} md={5.75} lg={5.75}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            height: '100%',
          }}
        >
          <Typography
            fontSize={28}
            fontWeight={'bold'}
            sx={{
              textShadow: "0px 2px 0px rgba(0, 0, 0, .5)",
            }}
          >
            ฿{" "}
            {parseFloat(total).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
</Grid>
</Grid> */
}

{
  /* <Grid item xs={12} sm={12} md={6} lg={6}>
<Box
  sx={{
    background:
      "linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255, 0));",
    border: "solid 1px rgba(255, 255, 255, 1)",
    height: 150,
    maxHeight: 150,
    mx: 0.5,
    mb: 2,
    borderRadius: {
      xs: 9,
      sm: 9,
      md: 6,
      lg: 6,
    },
    transition: "all .2s",
    "&:hover": {
      transform: "scale(1.02)",
    },
  }}
>
  <Grid
    container
    sx={{
      height: `100%`,
    }}
  >
    <Grid item xs={5.75} sm={5.75} md={5.75} lg={5.75}>

      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", flexDirection: "column", gap: "4px" }}
        >
          <Typography
            fontSize={{ xs: 16, sm: 18, md: 20, lg: 22, xl: 24 }}
            fontWeight="bold"
            sx={{
              color: "#A78241",
              textShadow:
                "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
            }}
          >
            {productList[0]?.name || "555"}
          </Typography>
          <Typography
            fontSize={{ xs: 16, sm: 18, md: 20, lg: 22, xl: 24 }}
            fontWeight="bold"
            sx={{
              color: "#A78241",
              textShadow:
                "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
            }}
          >
            {productList[1]?.name || "555"}
          </Typography>
        </Box>
      </ThemeProvider>
    </Grid>
    <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
      <Box
        display={`flex`}
        justifyContent={`center`}
        alignItems={`center`}
        sx={{
          height: `100%`,
        }}
      >
        <Box
          display={`flex`}
          justifyContent={`center`}
          alignItems={`center`}
          sx={{
            height: `85%`,
          }}
        >
          <Divider orientation="vertical" flexItem color={"white"} />
        </Box>
      </Box>
    </Grid>
    <Grid item xs={5.75} sm={5.75} md={5.75} lg={5.75}>
  
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", flexDirection: "column" }}
        >
          <Typography
            fontSize={{ xs: 18, sm: 20, md: 22, lg: 24, xl: 26 }}
            fontWeight="bold"
            sx={{
              textShadow: "0px 2px 0px rgba(0, 0, 0, .5)",
              textAlign: "center",
              width: "100%",
            }}
          >
            ฿{" "}
            {parseFloat(productList[0]?.price || 0).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </Typography>
          <Typography
           fontSize={{ xs: 18, sm: 20, md: 22, lg: 24, xl: 26 }}
            fontWeight="bold"
            sx={{
              textShadow: "0px 2px 0px rgba(0, 0, 0, .5)",
              textAlign: "center",
              width: "100%",
            }}
          >
            ฿{" "}
            {parseFloat(productList[1]?.price || 0).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </Typography>
        </Box>
      </ThemeProvider>
    </Grid>
  </Grid>
</Box>
</Grid>
<Grid item xs={12} sm={12} md={6} lg={6}>
<Box
  sx={{
    background:
      "linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255, 0));",
    border: "solid 1px rgba(255, 255, 255, 1)",
    height: 150,
    maxHeight: 150,
    mx: 0.5,
    mb: 2,
    borderRadius: {
      xs: 9,
      sm: 9,
      md: 6,
      lg: 6,
    },
    transition: "all .2s",
    "&:hover": {
      transform: "scale(1.02)",
    },
  }}
>
  <Grid
    container
    sx={{
      height: `100%`,
    }}
  >
    <Grid item xs={5.75} sm={5.75} md={5.75} lg={5.75}>
      <Box
        display={`flex`}
        justifyContent={`center`}
        alignItems={`center`}
        sx={{
          height: `100%`,
        }}
      >
        <Typography
          fontSize={28}
          fontWeight={`bold`}
          sx={{
            color: "#A78241",
            textShadow:
              "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
          }}
        >
          รวมทั้งสิ้น
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
      <Box
        display={`flex`}
        justifyContent={`center`}
        alignItems={`center`}
        sx={{
          height: `100%`,
        }}
      >
        <Box
          display={`flex`}
          justifyContent={`center`}
          alignItems={`center`}
          sx={{
            height: `85%`,
          }}
        >
          <Divider orientation="vertical" flexItem color={"white"} />
        </Box>
      </Box>
    </Grid>
    <Grid item xs={5.75} sm={5.75} md={5.75} lg={5.75}>
      <Box
        display={`flex`}
        justifyContent={`center`}
        alignItems={`center`}
        sx={{
          height: `100%`,
        }}
      >
        <Typography
          fontSize={28}
          fontWeight={`bold`}
          sx={{
            textShadow: "0px 2px 0px rgba(0, 0, 0, .5)",
          }}
        >
          ฿{" "}
          {parseFloat(total).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Box>
    </Grid>
  </Grid>
</Box>
</Grid>
</Grid> */
}
