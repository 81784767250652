import { ParallaxBanner } from "react-scroll-parallax";
import BannerImg from "../assets/images/banner.png";
import SnackBlack from "../assets/images/snack_black.png";
import BannerBg1 from "../assets/images/banner_bg1.png";
import BannerBg from "../assets/images/bg.png";
import bgtext from "../assets/images/Vector1.png";
import bgtext2 from "../assets/images/Vector.png";
import bigBean from "../assets/images/bigbean.png"
import beanfull from "../assets/images/beanbig.png"
import Car from "../assets/images/car.png";
import { Box, Button, Container, Grid, Typography, styled } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Containnerlayout = styled(Grid)(({ theme }) => ({
    maxWidth: "1440px",
    margin: "0 auto",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.up("xs")]: {
        justifyContent: "center",

    },
    [theme.breakpoints.up("sm")]: {
        justifyContent: "end",

    },

}));
const Beanimg1 = styled("img")(({ theme }) => ({
    width: "15%",
    position: "absolute",
    left: "0",
    top: "64%",
    rotate: "110deg",

}));
const Beanfull = styled("img")(({ theme }) => ({
    width: "60%",
    position: "absolute",
    right: "0",
    bottom: "-30%",
    rotate: "280deg",

}));
const Beanimg2 = styled("img")(({ theme }) => ({
    width: "30%",
    position: "absolute",
    top: "0",
    left: "-10%"
}));
const ButtonShop = styled(Button)(({ theme }) => ({
    width: "40%",
    borderRadius: "64px",
    [theme.breakpoints.up("xs")]: {
        fontSize: "8px",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px",

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px",

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px",

    },
    [theme.breakpoints.up("xl")]: {
        fontSize: "24px",

    },
    fontWeight: "700",
}));
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "26px",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "30px",

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "38px",

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "44px",

    },
    [theme.breakpoints.up("xl")]: {
        fontSize: "52px",

    },
    fontWeight: "500"
}));
const Text24px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "8px",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px",

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px",

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px",

    },
    [theme.breakpoints.up("xl")]: {
        fontSize: "24px",

    },
    fontWeight: "500"
}));

export default function Banner() {
    useEffect(() => {
        AOS.init()
    }, [])
    const navigate = useNavigate()
    return (
        <>
            <ParallaxBanner
                layers={[
                    {
                        image: BannerBg,
                        speed: -10,
                    },
                ]}
            >
                <Box sx={
                    {
                        height: {
                            lg: `100dvh`,
                            md: `100dvh`,
                            sm: `80dvh`,
                            xs: `55dvh`
                        }
                    }
                }>
                    <div style={{ position: 'relative', zIndex: 1 }}>
                        <div className="cloud-x2">
                            <div className="cloud2"></div>
                        </div>
                        <div className="cloud-x1">
                            <div className="cloud1"></div>
                        </div>
                    </div>
                    <Containnerlayout container>
                        <Grid container justifyContent={"center"} sx={
                            {
                                zIndex: "1",
                                position: "relative",
                                width: {
                                    lg: `70%`,
                                    md: `70%`,
                                    sm: `100%`,
                                    xs: `100%`
                                }
                            }
                        }>
                            <Grid container sx={{ width: "90%", position: "absolute", top: "5%" }}>
                                <img src={bgtext} alt="Banner" width={"100%"} />
                                <Grid sx={{ position: "absolute", bottom: "40%", width: "50%", left: "10%" }}>
                                    <Text40px sx={{ color: '#fff', textShadow: '3px 3px 0 #80582F, -1px -1px 0 #80582F, 1px -1px 0 #80582F, -1px 1px 0 #80582F, 3px 3px 0 #80582F' }}>
                                        Gauvain
                                    </Text40px>
                                    <Text24px>เครื่องดื่มโปรตีนถั่วลันเตาสีทอง</Text24px>
                                    <Text24px>ชงง่ายละลายไว อิ่มท้อง ทานง่าย ไฟเบอร์สูง</Text24px>
                                    <ButtonShop sx={{ mt: 1 }} variant="contained" onClick={() => navigate('/shop')}>
                                        <Typography fontWeight={`bold`} sx={{
                                            color: '#80582F',
                                            // WebkitTextStrokeColor: 'white',
                                            // WebkitTextStrokeWidth: '3px',
                                            // WebkitTextFillColor: '#80582F',
                                            textShadow: {
                                                xs: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
                                                sm: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
                                                md: '-2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff',
                                                lg: '-2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff'
                                            },
                                            fontSize: {
                                                xs: 14,
                                                sm: 14,
                                                md: 24,
                                                lg: 24
                                            }
                                        }}
                                        >
                                            SHOP
                                        </Typography>
                                    </ButtonShop>
                                </Grid>
                                <Grid sx={{ position: "absolute", right: "0", width: "45%" }}>
                                    <img src={SnackBlack} alt="Banner" className='dukdug' width={"100%"} />
                                    <Beanfull src={beanfull} />
                                    <Beanimg2 src={bigBean} />
                                </Grid>
                                <Beanimg1 src={bigBean} />
                            </Grid>
                            <img src={bgtext2} alt="Banner" style={{ width: "100%" }} />
                        </Grid>
                    </Containnerlayout>
                </Box>
            </ParallaxBanner>
            <Grid container p={2} sx={{ background: '#EDC26E', overflow: "hidden" }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Grid
                    xs={6}
                    sx={{ display: { xs: "none", md: "block" } }}
                    textAlign={'center'}
                    data-aos="fade-left"
                    data-aos-duration="2000"
                >
                    <img src={Car}></img>
                </Grid>
                <Grid xs={12} md={6} data-aos="fade-left">
                    <Typography variant="h6">
                        <b style={{ color: "#FFF" }}>ดูแลตัวเองในรูปแบบใหม่ “ Gauvain “</b>
                        เครื่องดื่มโปรตีนถั่วลันเตาสีทอง
                        ชงง่ายละลายไว อิ่มท้อง ทานง่าย ไฟเบอร์สูง
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

