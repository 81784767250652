import React, { useEffect, useState } from "react";
import { Box, Button, styled, TextField } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Grid,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../../../api/gauvain";
import { useAdmin } from "../context/AdminContextProvider";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const initialState = {
  name: "",
  price: "",
  description: "",
  background_color: "",
};
export default function AddProduct({
  handleClose,
  open,
}: {
  handleClose: any;
  open: any;
}) {
  const cookies = new Cookies();
  const api = new gauvainAPI();
  const token = cookies.get("access_token");

  const { fetchOrders } = useAdmin();

  const [input, setInput] = useState(initialState);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState("");

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleChange = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  //   console.log(selectedImage)

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      if (
        !selectedImage ||
        !input.name ||
        !input.description ||
        !input.price ||
        !input.background_color
      ) {
        return Swal.fire({
          title: `แจ้งเตือน`,
          text: "กรุณากรอกข้อมูลให้ครบถ้วน",
          confirmButtonText: `ตกลง`,
          icon: `info`,
        });
      }

      formData.append("file", selectedImage);
      formData.append("name", input.name);
      formData.append("price", input.price);
      formData.append("description", input.description);
      formData.append("background_color", input.background_color);

      await api.createProduct(token, formData);
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เรียบร้อยแล้ว",
        confirmButtonText: `ตกลง`,
        icon: `success`,
      });

      // แนป Product มาตอนยิงเรียกข้อมูลออเดอร์ด้วย
      fetchOrders();
      //   setInput(item)
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เกิดข้อผิดพลาด",
        confirmButtonText: `ตกลง`,
        icon: `error`,
      });
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontSize: "1.25rem",
          fontWeight: "bold",
          color: "#333",
        }}
        id="customized-dialog-title"
      >
        เพิ่มสินค้า
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography
          gutterBottom
          sx={{ fontSize: "1rem", fontWeight: "500", color: "#555" }}
        >
          ตัวอย่างสินค้าและสีพื้นหลัง
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="16px"
          bgcolor={input.background_color}
          minHeight={200}
          sx={{ borderRadius: "8px", boxShadow: 1 }}
        >
          <img
            src={preview}
            style={{ maxHeight: "281px", objectFit: "contain" }}
            alt="Product Preview"
          />
        </Box>
        <Box
          sx={{
            border: "1px solid #ddd",
            padding: "16px",
            borderRadius: "8px",
            marginTop: "16px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="ชื่อสินค้า"
                variant="standard"
                value={input.name}
                onChange={handleChange}
                name="name"
                fullWidth
                sx={{ marginBottom: "8px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="รายละเอียด"
                variant="standard"
                value={input.description}
                onChange={handleChange}
                name="description"
                fullWidth
                sx={{ marginBottom: "8px" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="ราคาสินค้า"
                variant="standard"
                value={input.price}
                onChange={handleChange}
                name="price"
                fullWidth
                sx={{ marginBottom: "8px" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="สีพื้นหลัง"
                variant="standard"
                value={input.background_color}
                onChange={handleChange}
                name="background_color"
                fullWidth
                sx={{ marginBottom: "8px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <label
                style={{
                  background: "#f5f5f5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px",
                  color: "#333",
                  borderRadius: "8px",
                  cursor: "pointer",
                  border: "1px dashed #ccc",
                }}
                htmlFor="upload-photo"
              >
                เพิ่มรูปสินค้า
              </label>
              <input
                accept="image/*"
                id="upload-photo"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button
          onClick={() => {
            setInput(initialState);
            setPreview("");
            setSelectedImage(null);
          }}
          variant="outlined"
          color="error"
          sx={{ minWidth: "100px", marginRight: "8px" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={() => {
            handleClose();
            handleSubmit();
          }}
          variant="contained"
          sx={{ minWidth: "100px", bgcolor: "#235aad", color: "white" }}
        >
          เพิ่มสินค้า
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
