"use client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Box, CircularProgress, Typography } from "@mui/material";
import { usePayment } from "../../context/PaymentContextProvider";
import CircularIndeterminate from "../admin/component/CircularIndeterminate";

// Load the Stripe object
const stripePromise = loadStripe(
  // "pk_test_51PnJM6F4XaFmhRNv9V8jBQ3IpkIQCSV4OMXjEYEOBFLzIrI9PEzX679GlnL1d50ZsOTJTTOr9i66LZMwdiJkFfUK00zto1ZU8Z"
  "pk_live_51PnJM6F4XaFmhRNvTXY6yCyISfhAN2ealiT1OW6tY5yfQK6ujAlqGeuMBCUDPjvyOCCzbAqGQnAwObpmoz9lLDxY00ZQ9ddpLl"
);

const CheckoutForm: React.FC = () => {
  const location = useLocation();
  const state = location.state as { [key: string]: any }; // Add appropriate type if needed
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { fnCreateTransaction } = usePayment();

  const payment = async () => {
    try {
      const response = await fnCreateTransaction();
      setClientSecret(response.data.clientSecret);
      
    } catch (err) {
      console.error(err);
      setError("Failed to initiate payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    payment();
  }, []);

  if (loading) {
    return <CircularIndeterminate />;
  }

  if (error) {
    return (
      <Box sx={{ padding: 4,display:'flex',alignItems:"center",justifyContent:'center', height:"60vh" }}>
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box id="checkout" sx={{ padding: 4, height: "100%" }}>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </Box>
  );
};

export default CheckoutForm;
