import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  styled,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContentText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../../../api/gauvain";

import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { useAdmin } from "../context/AdminContextProvider";

// Styled Components
const StyledTableContainer: any = styled(TableContainer)(({ theme }) => ({
  overflowX: "auto",
  minWidth: "500px",
  "@media (max-width: 600px)": {
    minWidth: "100%",
  },
}));

const StyledTable = styled(Table)({
  minWidth: 450,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiPaper-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(1),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: "80px",
  "&.active": {
    backgroundColor: "#235aad",
    color: "white",
    "&:hover": {
      backgroundColor: "#1e4b8a",
    },
  },
  "&.inactive": {
    borderColor: "#235aad",
    color: "#235aad",
    "&:hover": {
      backgroundColor: "rgba(35, 90, 173, 0.1)",
    },
  },
}));

export default function AddExpress({
  handleClose,
  open,
}: {
  handleClose: any;
  open: any;
}) {
  const cookies = new Cookies();
  const api = new gauvainAPI();
  const token = cookies.get("access_token");

  const { fetchShipping, shipping } = useAdmin();

  const updateShipping = async (id: number) => {
    try {
      await api.updateShipping(token, id);

      fetchShipping();
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เรียบร้อยแล้ว",
        confirmButtonText: `ตกลง`,
        icon: `success`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteShipping = async (id: number) => {
    try {
      await api.deleteShipping(token, id);

      fetchShipping();
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เรียบร้อยแล้ว",
        confirmButtonText: `ตกลง`,
        icon: `success`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchShipping();
  }, []);

  const [open2, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose2 = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontSize: "1.25rem",
          fontWeight: "bold",
          color: "#333",
          backgroundColor: "#f7f7f7",
        }}
      >
        เลือกใช้ขนส่ง
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography sx={{ fontSize: "0.700rem", color: "gray" }}>
          * กำลังใช้งาน {"(ใช้บริการของเจ้านี้อยู่)"}
        </Typography>
        <Typography sx={{ fontSize: "0.700rem", color: "gray" }}>
          * ไม่ได้ใช้งาน
        </Typography>

        <StyledTableContainer
          component={Paper}
          sx={{ boxShadow: "none", marginTop: 1 }}
        >
          <StyledTable>
            <TableHead sx={{ bgcolor: "#d1d1d1" }}>
              <TableRow>
                {["ชื่อขนส่ง", "Active", "เลือกใช้", "ลบออก"].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      wordWrap: "break-word",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {shipping?.map((el: any, index: number) => (
                <TableRow
                  key={el.id}
                  sx={{
                    bgcolor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                    "&:hover": { bgcolor: "#f1f1f1" },
                  }}
                >
                  <TableCell
                    sx={{ textAlign: "center", wordWrap: "break-word" }}
                  >
                    {el.name}
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", wordWrap: "break-word" }}
                  >
                    <Active is={el.active == 1} />
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", wordWrap: "break-word" }}
                  >
                    <Box
                      onClick={() => updateShipping(el.id)}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                        paddingX: 2,
                        paddingY: 0.6,
                        width: 54,
                        borderRadius: 16,

                        boxShadow: 1,
                        cursor: "pointer",

                        bgcolor: "rgba(25, 53, 178, 1)", // Opaque green // Semi-transparent red
                        color: "white",
                      }}
                    >
                      เลือก
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <DeleteIcon
                      sx={{ cursor: "pointer", color: "red" }}
                      onClick={handleClickOpen}
                    />
                    <Dialog
                      open={open2}
                      onClose={handleClose2}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"ต้องการลบ?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          หากต้องการลบคลิก Agree หากไม่ Disagree.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleClose2}
                          variant="outlined"
                          color="error"
                        >
                          Disagree
                        </Button>
                        <Button
                          onClick={() => {
                            deleteShipping(el.id);
                            handleClose2();
                          }}
                          variant="contained"
                          autoFocus
                        >
                          Agree
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            color: "#235aad",
            borderColor: "#235aad",
            "&:hover": {
              bgcolor: "rgba(35, 90, 173, 0.1)",
              borderColor: "#235aad",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

function Active({ is }: { is: boolean }) {
  const final = is ? "กำลังใช้งาน" : "ไม่ได้ใช้งาน";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          paddingX: 2,
          paddingY: 0.6,
          width: 102,
          borderRadius: 16,

          boxShadow: 1,

          bgcolor: is
            ? "rgba(52, 197, 107, 0.5)" // Opaque green
            : "rgba(92, 85, 85, 0.2)", // Semi-transparent red
          color: is ? "#249c4c" : "#544a4a",
        }}
      >
        <Box
          sx={{
            height: 10,
            width: 10,
            borderRadius: 100,
            bgcolor: is ? "#249c4c" : "#544a4a",
          }}
        ></Box>
        {final}
      </Box>
    </Box>
  );
}
