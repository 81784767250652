import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import live from "../assets/images/video1.png";
import AOS from "aos";
import "aos/dist/aos.css";
import chrismas from "../assets/images/christmas.jpg";
import detailproduct from "../assets/images/detailproduct.jpg";
import CloudFooter from "../assets/images/cloud_footer.png";
import Emo from "../assets/images/emo.png";
import EmoTitle from "../assets/images/emo_title.png";
import SnackBlack from "../assets/images/snack_black.png";
import LogoX1 from "../assets/images/logo_x1.png";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import SnackYellow from "../assets/images/snack_yellow.png";
import New1 from "../assets/News/S__285024436.jpg";
import New2 from "../assets/News/S__285024439.jpg";

import New3 from "../assets/News/S__285024437.jpg";
import New4 from "../assets/News/S__285024438.jpg";
import New5 from "../assets/News/S__285024440.jpg";
import { gauvainAPI } from "../api/gauvain";
import { Navigate, useNavigate } from "react-router-dom";

type Props = {};
const Text90px = styled("p")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "50px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "60px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "90px",
  },
  fontWeight: "500",
  padding: "12px 0",
  color: "#FF9700",
  textAlign: "center",
}));
const NewsContainer = styled(Grid)(() => ({
  width: "80%",
  justifyContent: "space-between",
  margin: "0 auto",
}));
const Text24px = styled("p")(({ theme }) => ({
  color: "#69200A",
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
  },
  textAlign: "center",
}));
const BuyButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "20%",
  left: "35%",
  fontWeight: "700",
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    padding: "0",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
    padding: "6px 34px",
  },
  border: "1px solid #B36E29",
  color: "#FFF",
  backgroundColor: "#FFB600",
  borderRadius: "24px",
  width: "30%",
}));
const Text64px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "56px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "64px",
  },
  fontWeight: "500",
  padding: "12px 0",
}));
const Imgitem = styled("img")(() => ({
  width: "30%",
}));
const Imgtext = styled("img")(() => ({
  width: "50%",
}));
// const TextProd = styled("h1")(({ theme }) => ({
//   [theme.breakpoints.up("xs")]: {
//     fontSize: "15px",
//     left: "15%",
//     bottom: "-10%",
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "30px",
//     left: "18%",
//     bottom: "-12%",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "40px"

//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "50px",
//     left: "15%"
//   },
//   fontWeight: "700",
//   position: 'absolute',
//   color: "#454545",
//   rotate: "8deg",
// }));
const TextProd = styled("h1")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    left: "15%",
    top: "55%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
    left: "15%",
    top: "46%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "50px",
  },
  fontWeight: "700",
  position: "absolute",
  color: "#454545",
  rotate: "8deg",
}));
const TextProd2 = styled("h1")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    right: "8%",
    top: "55%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
    right: "8%",
    top: "45%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "50px",
  },
  fontWeight: "700",
  position: "absolute",
  color: "#454545",
  rotate: "343deg",
}));
function News({}: Props) {
  interface YouTubeLiveProps {
    videoUrl: string;
  }

  const extractVideoId = (url: string) => {
    // Regex to match either 'watch' or 'live' video IDs from YouTube URLs
    const regex =
      /(?:youtube\.com\/(?:watch\?v=|live\/)|youtu\.be\/)([^"&?\/\s]{11})/i;
    const match = url.match(regex);
    return match ? match[1] : "";
  };

  const YouTubeLive: React.FC<YouTubeLiveProps> = ({ videoUrl }) => {
    const id = extractVideoId(videoUrl);
    const embedUrl = `https://www.youtube.com/embed/${id}`;


   
    return (
      <iframe
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
        src={embedUrl}
        title="YouTube Live Stream"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  const YouTubeLiveChat: React.FC<YouTubeLiveProps> = ({ videoUrl }) => {
    const id = extractVideoId(videoUrl);
    const chatUrl = `https://www.youtube.com/live_chat?v=${id}&embed_domain=${window.location.hostname}`;

    return (
      <iframe
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
        src={chatUrl}
        title="YouTube Live Chat"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    );
  };

  const [URL, setURL] = useState("");
  const getLive = async () => {
    try {
      const api = new gauvainAPI();
      const data = await api.getURL({});
      setURL(data.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getLive();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  const navigate = useNavigate()

  return (
    <div style={{ backgroundColor: "#FFE757", overflow: "hidden" }}>
      <Text90px
        sx={{ color: "#FE89B5", margin: "0", padding: "24px 0" }}
        className="Franchise"
        data-aos="fade-up-left"
        data-aos-duration="1000"
      >
        LIVE
      </Text90px>
      {/* <img src={live} width={"100%"} /> */}
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFE757",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "95%",
            paddingBottom: "56.25%",
          }}
        >
          <YouTubeLive videoUrl={URL} />
        </div>
      </div>
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFE757",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "95%",
            paddingBottom: "56.25%",
          }}
        >
          <YouTubeLiveChat videoUrl={URL} />
        </div>
      </div>
      <Grid sx={{ position: "relative" }}>
        <Text90px
          sx={{ color: "#FE89B5", margin: "0", padding: "24px 0" }}
          className="Franchise"
          data-aos="fade-up-left"
          data-aos-duration="1000"
        >
          NEWS
        </Text90px>
      </Grid>
      <NewsContainer container>
        <Grid
          sx={{ width: { xs: "100%", sm: "45%" } }}
          data-aos="fade-up-right"
          data-aos-duration="1000"
        >
          <img
            src={chrismas}
            alt="chrismas"
            width={"100%"}
            style={{ borderRadius: "14px" }}
          />
          {/* <Text24px>
            Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet tortor vel.
          </Text24px> */}
        </Grid>
        <Grid
          sx={{ width: { xs: "100%", sm: "45%" } }}
          data-aos="fade-up-left"
          data-aos-duration="1000"
        >
          <img
            src={detailproduct}
            alt="chrismas"
            width={"100%"}
            style={{ borderRadius: "14px" }}
          />
          {/* <Text24px>
            Lorem ipsum dolor sit amet consectetur. Luctus nisl purus viverra quam nulla scelerisque. Placerat elementum amet tortor vel.
          </Text24px> */}
        </Grid>
      </NewsContainer>
      <div style={{ background: "#a6d1dd", overflow: "hidden" }}>
        <div style={{ position: "relative", zIndex: 1 }}>
          <div className="cloud-x2">
            <div className="cloud2"></div>
          </div>
          <div className="cloud-x1">
            <div className="cloud1"></div>
          </div>
        </div>

        <div
          style={{ position: "relative", maxWidth: "1240px", margin: "0 auto" }}
        >
          <Box
            sx={{
              background: "#a6d1dd",
              margin: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              maxWidth: "1444px",
            }}
          >
            <Imgtext
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ zIndex: "99" }}
              src={LogoX1}
            ></Imgtext>
            <Text64px
              className="Franchise"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              หอม อร่อย ทานง่าย
            </Text64px>
            <Text64px
              className="Franchise"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              อิ่มนานขึ้น หุ่นลีนกระชับ
            </Text64px>
            <Text64px
              className="Franchise"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <Imgtext src={EmoTitle}></Imgtext>
            </Text64px>
            <Imgitem
              data-aos="fade-up-right"
              data-aos-duration="1000"
              src={SnackBlack}
            ></Imgitem>
            <Imgitem
              data-aos="fade-up"
              data-aos-duration="1000"
              src={Emo}
              className="dukdug"
            ></Imgitem>
            <Imgitem
              data-aos="fade-up-left"
              data-aos-duration="1000"
              src={SnackYellow}
            ></Imgitem>
            <TextProd data-aos="fade-up-left" data-aos-duration="1000">
              รสกาแฟ <br />
              เอสเปรสโซ่
            </TextProd>
            <TextProd2 data-aos="fade-up-right" data-aos-duration="1000">
              รสช็อกโกแลต <br /> กล้วยหอม
            </TextProd2>
          </Box>
        </div>

        <Grid
          container
          data-aos="fade-up"
          data-aos-duration="1000"
          sx={{ position: "relative" }}
        >
          <img src={CloudFooter} style={{ width: "100%" }} />
          <BuyButton variant="contained" 
            onClick={()=>navigate('/checkout')}
          >สั่งซื้อ</BuyButton>
        </Grid>
      </div>
      {/* youtube */}
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFE757",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingBottom: "56.25%",
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/s232qHjL2Bk"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <Grid>
        <Swiper
          className="sample-slider"
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 3,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          speed={4000}
          grabCursor={false}
          slidesPerView={1}
        >
          <SwiperSlide>
            <img src={New1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={New2} />
          </SwiperSlide>
        </Swiper>
      </Grid>
      <Grid>
        <Swiper
          className="sample-slider"
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 3,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          speed={5000}
          grabCursor={false}
          slidesPerView={1}
        >
          <SwiperSlide>
            <img src={New3} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={New4} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={New5} />
          </SwiperSlide>
        </Swiper>
      </Grid>
    </div>
  );
}

export default News;
