import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { gauvainAPI } from "../../api/gauvain";
import Cookies from "universal-cookie";

import emo from "../../assets/images/emo.png";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 400 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: { xs: 2, sm: 4 },
  borderRadius: "8px",
  display:"flex",
  justifyContent:"space-between"
};

function Popup({ handleClickOpen }: { handleClickOpen: any }) {
  const [open, setOpen] = useState(false);

  const cookies = new Cookies();
  const token = cookies.get("access_token");
  const handleClose = () => setOpen(false);

  const fetchQuota = async () => {
    const api = new gauvainAPI();
    const response = await api.checkReview(token);

    if (response.data?.message === "quota") {
      setTimeout(() => {
        setOpen(true);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchQuota();
  }, []);

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="popup-title"
        aria-describedby="popup-description"
      >
        <Box sx={style}>
          <Box >
            <Typography
              id="popup-title"
              variant="h6"
              component="h2"
              gutterBottom
            >
              แจ้งเตือน
            </Typography>
            <Typography id="popup-description" sx={{ mb: 2 }}>
              กรุณาไปรีวิวสินค้าที่คุณได้สั่งซื้อ
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClose();
                handleClickOpen();
              }}
            >
              รีวิว
            </Button>
          </Box>
          <Box>
            <img src={emo} width={80}/>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Popup;
