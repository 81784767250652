import React, { ReactNode } from "react";
import { useAdmin } from "./context/AdminContextProvider";
import { Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


interface ProtechRouteProps {
  children: ReactNode;
}

export default function ProtechRoute({ children }: ProtechRouteProps) {
  const { isAdmin, loading } = useAdmin();

  if (loading) return <CircularIndeterminate />;
  return isAdmin ? <>{children}</> : <Navigate to="/login" />;
}





 function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex', justifyContent:'center', alignItems:"center", width:"100%" ,height:"100vh" }}>
      <CircularProgress />
    </Box>
  );
}
