import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExcelJS from "exceljs";

export default function TemplateExcelDashboard({ data }: { data: any }) {
  const transformData = data?.map((el: any) => {
    const transformedProducts = Object.keys(el.products).reduce(
      (acc: any, key: any) => {
        const product = el.products[key];
        const code = key === "1" ? "BC500" : "CF500";
        acc[key] = {
          ...product,
          code: code,
        };
        return acc;
      },
      {}
    );

    return {
      ...el,
      products: transformedProducts,
    };
  });

  const handleExportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Summary");

    worksheet.mergeCells("A1:D1");
    worksheet.getCell("A1").value = "ข้อมูลรายการขาย";
    worksheet.getCell("A1").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheet.getCell("A1").font = { size: 22, bold: true };

    const headers = ["รหัสสินค้า", "จำนวนชิ้น", "ยอดขาย", "วันที่"];
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = { bold: true, underline: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFCC" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    transformData.forEach((item: any) => {
      Object.keys(item.products).forEach((key) => {
        const product = item.products[key];
        const row = worksheet.addRow([
          product.code,
          product.quantity,
          product.total,
          `${item?.day ? item.day + "-" : ""}${
            item?.month ? item.month + "-" : ""
          }${item?.year || ""}`,
        ]);

        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });
    });

    headers.forEach((_, index) => {
      worksheet.getColumn(index + 1).width = 20;
    });

    const fileName = new Date().getTime();
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `สรุปยอดขาย_${fileName}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <Box p={4}>
      <Typography variant="h5" align="center" fontWeight="bold">
        สรุปยอดขาย
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Asset Registration Table">
          <TableHead>
            <TableRow>
              {["รหัสสินค้า", "จำนวนชิ้น", "ยอดขาย", "วันที่"].map(
                (header, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      border: 1,
                      textDecoration: "underline",
                    }}
                  >
                    {header}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {transformData?.map((item: any, index: any) =>
              Object.keys(item.products).map((key) => (
                <TableRow key={`${index}-${key}`}>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item.products[key].code}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item.products[key].quantity}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item.products[key].total}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item.day && item.day + "-"}
                    {item.month}-{item.year}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="success" onClick={handleExportExcel}>
          Download Excel
        </Button>
      </Box>
    </Box>
  );
}
