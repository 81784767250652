import { Grid, Typography, Box, IconButton } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ModalAddress from "./ModalAddress";
import ModalAddAddress from "./ModalAddAddress";
import { useState } from "react";

const Express = (props:any) => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isOpenModalAddAddress, setIsOpenModalAddAddress] = useState<boolean>(false);

    return (
        <Grid container pt={1}>
            <ModalAddress isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} setIsOpenModalAddAddress={setIsOpenModalAddAddress} setCurrentAddress={props.setCurrentAddress} />
            <ModalAddAddress isOpenModal={isOpenModalAddAddress} setIsOpenModal={setIsOpenModalAddAddress} setIsOpenModalAddress={setIsOpenModal} />
            <Grid item xs={12} sm={12} md={12} lg={12} sx={
                {
                    color: 'black',
                }
            } mb={3}>
                <Typography fontSize={22} sx={
                    {
                        color: "black",
                        textShadow: "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
                        ml: 3,
                        mb: 2
                    }
                }>
                    รายการจัดส่ง
                </Typography>
                <Box sx={
                    {
                        backgroundColor: "white",
                        borderRadius: 3,
                        px: 3
                    }
                }>
                    <Grid container py={4} sx={
                        {
                            px: {
                                lg: 3,
                                md: 3,
                                sm: 0,
                                xs: 0
                            }
                        }
                    }>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={`flex`} alignItems={`center`} sx={
                            {
                                lg: {
                                    justifyContent: `center`,
                                    
                                },
                                md: {
                                    justifyContent: `center`,
                                },
                                sm: {
                                    justifyContent: `flex-start`,
                                },
                                xs: {
                                    justifyContent: `flex-start`,
                                }
                            }
                        }>
                            <AccessTimeIcon sx={
                                {
                                    color: "#ff7f00"
                                }
                            } />
                        </Grid>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                            <Typography color={"#656572"} sx={
                                {
                                    ml: {
                                        xs: 1.5,
                                        sm: 1.5,
                                        md: 0,
                                        lg: 0
                                    }
                                }
                            }>
                                ระยะเวลาจัดส่ง
                            </Typography>
                            <Typography fontSize={20} sx={
                                {
                                    ml: {
                                        xs: 1.5,
                                        sm: 1.5,
                                        md: 0,
                                        lg: 0
                                    }
                                }
                            }>
                                1 - 2 Days
                            </Typography>
                        </Grid>
                        <Grid item xs={12} my={2}></Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={`flex`} alignItems={`center`} sx={
                            {
                                lg: {
                                    justifyContent: `center`,
                                    
                                },
                                md: {
                                    justifyContent: `center`,
                                },
                                sm: {
                                    justifyContent: `flex-start`,
                                },
                                xs: {
                                    justifyContent: `flex-start`,
                                }
                            }
                        }>
                            <LocationOnIcon sx={
                                {
                                    color: "#ff7f00"
                                }
                            } />
                        </Grid>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} position={`relative`} maxWidth={`100%`}>
                                    <Box position={`absolute`} display={`flex`} justifyContent={`flex-end`} width={`100%`}>
                                        <Box sx={
                                            {
                                                mt: {
                                                    xs: -1.5,
                                                    sm: -1.5,
                                                    md: 1.5,
                                                    lg: 1.5
                                                }
                                            }
                                        }>
                                            <IconButton sx={
                                                {
                                                    color: "#666060",
                                                    minWidth: 'auto',
                                                    backgroundColor: "transparent",
                                                    borderRadius: 2,
                                                    transition: 'all .2s',
                                                    "&:hover": {
                                                        backgroundColor: "rgba(0, 0, 0, .2)",
                                                        color: "#666060",
                                                    }
                                                }
                                            } onClick={() => {
                                                setIsOpenModal(true);
                                            }}>
                                                <KeyboardArrowRightIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Typography color={"#656572"} sx={
                                {
                                    ml: {
                                        xs: 1.5,
                                        sm: 1.5,
                                        md: 0,
                                        lg: 0
                                    }
                                }
                            }>
                                สถานที่จัดส่ง
                            </Typography>
                            <Typography fontSize={20} sx={
                                {
                                    ml: {
                                        xs: 1.5,
                                        sm: 1.5,
                                        md: 0,
                                        lg: 0
                                    }
                                }
                            }>
                                {
                                    props.currentAddres !== null ? props.currentAddres : "กรุณาเลือกที่อยู่ก่อน"
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Express;