import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LogoX1 from "../assets/images/logo_x1.png";
import SnackBlack from "../assets/images/snack_black.png";
import SnackYellow from "../assets/images/snack_yellow.png";
import CloudFooter from "../assets/images/cloud_footer.png";
import Emo from "../assets/images/emo.png";
import EmoTitle from "../assets/images/emo_title.png";
import { Box, Button, Container, Grid, Rating, Typography, styled } from '@mui/material';
import { BannerLayer, ParallaxBanner, useParallax } from 'react-scroll-parallax';
import Banner from './Banner';
import video1 from '../assets/images/video1.png'
import video2 from '../assets/images/video2.png'
import video3 from '../assets/images/video3.png'
import video4 from '../assets/images/video4.png'
import video5 from '../assets/images/video5.png'
// import Cup from '../assets/images/cup.jpg'
import Product from '../assets/images/ProductDetail.jpg'
import Product1 from '../assets/images/ProductDetail1.jpg'
import Product2 from '../assets/images/ProductDetail2.jpg'
import Product3 from '../assets/images/ProductDetail3.jpg'
import Product4 from '../assets/images/ProductDetail4.jpg'
import Product5 from '../assets/images/ProductDetail5.jpg'
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AOS from 'aos';
import 'aos/dist/aos.css';
import snack from '../assets/images/snack_black90deg.png'
import snack2 from '../assets/images/snack2.png'
import bean from '../assets/images/smallbean.png'
import beanlong from '../assets/images/beanbig.png'
import star from '../assets/images/star.png'
import ProductAbout from '../assets/images/detailproduct.jpg'
import facebook from '../assets/images/facebook.png'
import tiktk from '../assets/images/tiktok.png'
import ig from '../assets/images/ig.png'
import youtube from '../assets/images/youtube.png'
import reviewvideo from '../assets/images/reviewvideo.png'
import cup from '../assets/images/cup.jpg'
import tree from '../assets/images/tree.png'
import Langmai from '../assets/images/langmai.png'
import Video from './../assets/video/734265698.369275.mp4';
import Video1 from './../assets/video/footer/1.mp4';
import Video2 from './../assets/video/footer/2.mp4';
import Video3 from './../assets/video/footer/3.mp4';
import Video4 from './../assets/video/footer/4.mp4';
import Video5 from './../assets/video/footer/5.mp4';
import Video6 from './../assets/video/footer/6.mp4';
import Video7 from './../assets/video/footer/7.mp4';
import Video8 from './../assets/video/footer/8.mp4';
import Video9 from './../assets/video/footer/9.mp4';
import Video10 from './../assets/video/footer/10.mp4';
import Video11 from './../assets/video/footer/11.mp4';
import Video12 from './../assets/video/footer/vdo12.mp4';
import Video13 from './../assets/video/footer/13.mp4';

import InstagramVideo from './../assets/video/ig.mp4';


// NEW IMAGE
import FirstNewImage from './../assets/new/first.jpg';
import SecondNewImage from './../assets/new/second.png';
import ThirdNewImage from './../assets/new/thrid.png';

// NEW VIDEO
import FirstNewVideo from './../assets/new/first.mp4';
import SecondNewVideo from './../assets/new/second.mp4';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './../assets/css/review_swiper.css';

import { gauvainAPI } from '../api/gauvain';
import Review from '../layouts/Review';
import InsertReview from '../components/review/InsertReview';
import Popup from '../components/review/Popup';




const BuyButton = styled(Button)(({ theme }) => ({
    position: "absolute",
    top: "20%",
    left: "35%",
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px",
        padding: "0",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "32px",
        padding: "6px 34px",

    },
    border: "1px solid #B36E29",
    color: "#FFF",
    backgroundColor: "#FFB600",
    borderRadius: "24px",
    width: "30%"
}));
const Text64px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "24px",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "30px",

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "34px",

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "56px",

    },
    [theme.breakpoints.up("xl")]: {
        fontSize: "64px",

    },
    fontWeight: "500",
    padding: "12px 0"
}));
const Imgitem = styled("img")(({ theme }) => ({
    width: "30%"
}));
const Imgtext = styled("img")(({ theme }) => ({
    width: "50%"
}));
const ItemContainer = styled(Grid)(({ theme }) => ({
    width: "80%",
    margin: "0 auto",
    justifyContent: "space-around"
}));
const ShopContainer = styled(Grid)(({ theme }) => ({
    width: "100%",
    position: "relative",
    padding: "32px 0"
}));
const ReviewContainer = styled(Grid)(({ theme }) => ({
    width: "100%",
    position: "relative",
    padding: "32px 0",
    backgroundColor: "#FFE757",
    overflow: "hidden"
}));
const AboutContainer = styled(Grid)(({ theme }) => ({
    width: "100%",
    position: "relative",
    margin: "0 auto",
    [theme.breakpoints.up("xs")]: {
        flexDirection: "column-reverse"
    },
    [theme.breakpoints.up("sm")]: {
        flexDirection: "row"
    },
}));
const Text90px = styled("p")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "32px",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "40px",

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "50px",

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "60px",

    },
    [theme.breakpoints.up("xl")]: {
        fontSize: "90px",

    },
    fontWeight: "500",
    padding: "12px 0",
    color: "#FF9700",
    textAlign: "center"
}));
const Card = styled(Grid)(({ theme }) => ({
    border: "1px solid #DADADA",
    borderRadius: "12px",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    overflow: "hidden",
    zIndex: "1",
    margin: "24px 0",
    [theme.breakpoints.up("xs")]: {
        height: "338px",
        width: "247px",

    },
    [theme.breakpoints.up("sm")]: {
        height: "365px",
        width: "247px",

    },
    [theme.breakpoints.up("md")]: {
        height: "465px",
        width: "287px",

    },
    [theme.breakpoints.up("lg")]: {
        height: "565px",
        width: "387px",

    },
    [theme.breakpoints.up("xl")]: {
        height: "665px",
        width: "487px",

    },
}));
const ProdImg = styled("img")(({ theme }) => ({
    width: "80%",
    zIndex: "1"
}));
const Beanimg = styled("img")(({ theme }) => ({
    width: "18%",
    position: "absolute",
    left: "0",
    top: "50%",
    zIndex: "0",

}));
const Beanimg3 = styled("img")(({ theme }) => ({
    width: "15%",
    position: "absolute",
    left: "0",
    top: "15%",
    zIndex: "0",

}));
const Beanimg1 = styled("img")(({ theme }) => ({
    width: "29%",
    position: "absolute",
    right: "0",
    top: "-3%",
    rotate: "80deg",

}));
const Beanimg2 = styled("img")(({ theme }) => ({
    width: "19%",
    position: "absolute",
    right: "-2%",
    top: "29%",
    rotate: "100deg",

}));
const Beanimglong = styled("img")(({ theme }) => ({
    width: "35%",
    position: "absolute",
    right: "-5%",
    top: "60%",
    rotate: "265deg",

}));
const StarBottom = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("xs")]:{
        width:"10%"
    },
    [theme.breakpoints.up("sm")]:{
        width: "5%",

    },
    position: "absolute",
    left: "5%",
    bottom: "5%",
    zIndex: "0",

}));
const StarTop = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("xs")]:{
        width:"10%"
    },
    [theme.breakpoints.up("sm")]:{
        width: "5%",

    },
    position: "absolute",
    top: "0",
    zIndex: "0",
    right: "8%"

}));
const NameProd = styled("p")(({ theme }) => ({
    color: "#56331F",
    margin: "0",
    [theme.breakpoints.up("xs")]: {
        fontSize: "18px"

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "24px"

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "26px"

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "32px"
    },
}));
const Text20px = styled("p")(({ theme }) => ({
    color: "#ED57F0",
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "14px"

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
}));
const Text24px = styled("p")(({ theme }) => ({
    color: "#B36E29",
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px"

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "20px"

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
    textAlign: "center"
}));
const CardReview = styled(Grid)(({ theme }) => ({
    border: "4px solid #ED57F0",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1",
    margin: "24px 0",
    backgroundColor: "#FFF",
    padding: "12px 0",
    width: "100%",
    height: '100%'
    // [theme.breakpoints.up("xs")]: {
    //     width: "80%",
    // },
    // [theme.breakpoints.up("sm")]: {
    //     width: "30%",
    // },

}));
const BeanimgReview = styled("img")(({ theme }) => ({
    width: "12%",
    position: "absolute",
    rotate: "100deg",
    bottom: "0",
    left: "-5%"
}));
const BeanfullimgReview = styled("img")(({ theme }) => ({
    width: "30%",
    position: "absolute",
    rotate: "150deg",
    top: "0",
    right: "-15%"

}));
const ProductAboutImg = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "30%",
    },
}));
const AboutUs = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "70%",
    },
    alignItems: "center",
    padding: "24px 0"

}));
const Social = styled("img")(({ theme }) => ({
    width: "5%",
    marginTop: "6px"
}));
const ImgIcon = styled("img")(({ theme }) => ({
    backgroundColor: "#FFF",
    borderRadius: "50%",
    width: "100%",
    cursor: "pointer"
}));
const ReviewVideoContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: "#FFB600",
    justifyContent: "space-around",
    padding: "8% 0"
}));
const ReviewVideoImg = styled("img")(({ theme }) => ({
    width: "100%"
}));
const ReviewVideoCard = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "82%",

    },
    [theme.breakpoints.up("sm")]: {
        width: "22%",
    },
}));
const H1 = styled("h1")(({ theme }) => ({

    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
        fontSize: "18px"

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "24px"

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "26px"

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "32px"
    },
}));
// const TextProd = styled("h1")(({ theme }) => ({
//     [theme.breakpoints.up("xs")]: {
//         fontSize: "15px",
//         left: "15%",
//         bottom: "-10%",
//     },
//     [theme.breakpoints.up("sm")]: {
//         fontSize: "30px",
//         left: "18%",
//         bottom: "-12%",
//     },
//     [theme.breakpoints.up("md")]: {
//         fontSize: "40px",

//     },
//     [theme.breakpoints.up("lg")]: {
//         fontSize: "50px",
//         left: "15%",

//     },
//     fontWeight: "700",
//     position: 'absolute',
//     color: "#454545",
//     rotate: "8deg",
// }));
const TextProd = styled("h1")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "15px",
        left: "15%",
        top: "55%",  
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "30px",
        left: "15%",
        top: "46%",  
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "50px",
    },
    fontWeight: "700",
    position: 'absolute',
    color: "#454545",
    rotate: "8deg",
}));



const TextProd2 = styled("h1")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "15px",
        right: "8%",
        top: "55%",
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "30px",
        right: "8%",
        top: "45%",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "40px"

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "50px"
    },
    fontWeight: "700",
    position: 'absolute',
    color: "#454545",
    rotate: "343deg",
}));
const Cup = styled("img")((theme) => ({
    width: "50%",
    zIndex: "1",
}));
const TreeLeft = styled("img")((theme) => ({
    width: "30%",
    zIndex: "2",
    position: "absolute",
    left: "-15%",
    bottom: "-5%",
    rotate: "45deg"
}));
const TreeRigth = styled("img")((theme) => ({
    width: "30%",
    zIndex: "2",
    position: "absolute",
    right: "-12%",
    bottom: "11%",
    rotate: "320deg"
}));
const Lungmai = styled("img")((theme) => ({
    width: "25%",
    zIndex: "2",
    position: "absolute",
    right: "-5%",
    bottom: "-9%",
}));
export default function Index() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState<number | null>(5);
    const [value1, setValue1] = React.useState<number | null>(5);
    const [value2, setValue2] = React.useState<number | null>(5);

    const [reviews, setReviews] = useState([])

    const [productList, setProductList] = useState<any>([]);


    const fetchReview = async() => {
        try {
            const api = new gauvainAPI()
            const response = await api.allReview()
            setReviews(response?.data)
        } catch(err) {
            console.log(err)
        }
    }
    useEffect(() => {
        (async () => {
            try {
                const api = new gauvainAPI();
                const allProductsTest: any = await api.getAllProducts();

                if (allProductsTest.data.status_code !== undefined && allProductsTest.data.status_code === 200) {
                    const temp_productList: any = [];

                    await Promise.all(allProductsTest.data.products.map((product: any) => {
                        let temp_product = product;
                        temp_product.quantity = 0;

                        temp_productList.push(temp_product);
                    }));

                    setProductList(temp_productList);
                }
            } catch (err) {
                console.log(err);
            }
        })()

        fetchReview()
    }, []);

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <div style={{ overflow: "hidden" }}>
            <Banner></Banner>
            <div style={{ background: '#afdeee', overflow: 'hidden' }}>

                <div style={{ position: 'relative', zIndex: 1 }}>
                    <div className="cloud-x2">
                        <div className="cloud2"></div>
                    </div>
                    <div className="cloud-x1">
                        <div className="cloud1"></div>
                    </div>
                </div>

                <div style={{ position: 'relative', maxWidth: "1240px", margin: "0 auto" }}>

                    <Box sx={{
                        background: '#afdeee',
                        margin: 'auto',
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        maxWidth: "1440px"
                    }}>
                        <Imgtext data-aos="zoom-in" data-aos-duration="2000" style={{ zIndex: "99" }} src={LogoX1}></Imgtext>
                        <Text64px className='Franchise' data-aos="zoom-in" data-aos-duration="1000" >หอม อร่อย ทานง่าย</Text64px>
                        <Text64px className='Franchise' data-aos="zoom-in" data-aos-duration="1000" >อิ่มนานขึ้น หุ่นลีนกระชับ</Text64px>
                        <Text64px className='Franchise' data-aos="zoom-in" data-aos-duration="1000" >
                            <Imgtext src={EmoTitle} ></Imgtext>
                        </Text64px>
                        
                        <Imgitem data-aos="fade-up-right" data-aos-duration="1000" src={SnackBlack} ></Imgitem>
                        <Imgitem data-aos="fade-up" data-aos-duration="1000" src={Emo} className='dukdug' ></Imgitem>
                        <Imgitem data-aos="fade-up-left" data-aos-duration="1000" src={SnackYellow}></Imgitem>
                        <TextProd data-aos="fade-up-left" data-aos-duration="1000">
                            รสกาแฟ <br />
                            เอสเปรสโซ่
                        </TextProd>
                        <TextProd2 data-aos="fade-up-right" data-aos-duration="1000">
                            รสช็อกโกแลต <br /> กล้วยหอม
                        </TextProd2>
                    </Box>

                </div>
                <Grid container data-aos="fade-up" data-aos-duration="1000" sx={{ position: "relative" }}>
                    <img src={CloudFooter} style={{ width: '100%' }} />
                    <BuyButton variant="contained" onClick={() => navigate('/shop')}>
                        สั่งซื้อ
                    </BuyButton>
                </Grid>
            </div>


            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <video controls style={{ width: "100%", marginBottom: '-6px' }} autoPlay muted>
                        <source src={Video13} type="video/mp4" />
                    </video>
                </Grid>
               
            </Grid>



            {/* youtube */}
            <div className='container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFE757' }}>
                <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
                    <iframe
                    src="https://www.youtube.com/embed/ChzGmuNbT8M"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    ></iframe>
                </div>
            </div>


            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <video controls style={{ width: "100%", marginBottom: '-6px' }} autoPlay muted>
                        <source src={FirstNewVideo} type="video/mp4" />
                    </video>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <video controls style={{ width: "100%", marginBottom: '-6px' }} autoPlay muted>
                        <source src={InstagramVideo} type="video/mp4" />
                    </video>
                </Grid>
            </Grid>
            <Grid container>
                <img src={Product1} style={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <img src={Product} style={{ width: "100%" }} />
            </Grid>

            <Grid container height={{ xs: "320px", sm: "500px", md: "750px" }}>
                {/* <img src={video2} style={{ width: "100%" }} /> */}
                <ReactPlayer
                    controls
                    playIcon={<PlayArrowIcon />}
                    url='https://www.youtube.com/watch?v=SxSUVhfyrD0'
                    width={'100%'}
                    height={"100%"}
                />
            </Grid>
            <Grid container>
                <img src={Product2} style={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <img src={Product3} style={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <img src={Product4} style={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <img src={Product5} style={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <img src={FirstNewImage} style={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <img src={SecondNewImage} style={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <img src={ThirdNewImage} style={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <video controls style={{ width: "100%", marginBottom: '-6px' }} autoPlay muted>
                        <source src={SecondNewVideo} type="video/mp4" />
                    </video>
                </Grid>
            </Grid>
            <ShopContainer className='BGPaper'>
                <Text90px className='Franchise' data-aos="fade-up" data-aos-duration="1000">
                    More to Discover
                </Text90px>
                <ItemContainer container >
                    {
                        productList.length > 0 && productList.map((product: any, index: number) => {
                            return (
                                <Card container className='Card' data-aos="fade-right" data-aos-duration="1000">
                                    <ProdImg src={product.image.replace(`{api_path}`, process.env.REACT_APP_API_URL)} />
                                    <NameProd>{product.name}</NameProd>
                                    <Button variant="contained" sx={{ width: "80%", margin: "0 auto", borderRadius: "24px", color: "#FFF" }} onClick={() => {
                                        window.scrollTo(0, 0);
                                        navigate('/checkout');
                                    }}>
                                        สั่งซื้อ
                                    </Button>
                                    <Beanimg className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
                                    <Beanimg1 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
                                    <Beanimglong className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={beanlong} />
                                    <Beanimg2 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
                                    <Beanimg3 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />

                                    <div style={{
                                        position: "absolute",
                                        top: "0",
                                        height: "50%",
                                        width: "100%",
                                        backgroundColor: product.background_color !== undefined && product.background_color !== null ? product.background_color : "#8a5834",
                                        zIndex: "-1"
                                    }}></div>

                                    <div style={{
                                        position: "absolute",
                                        bottom: "0",
                                        height: "50%",
                                        width: "100%",
                                        backgroundColor: "#FFFFFF",
                                        zIndex: "-1"

                                    }}></div>

                                </Card>
                            )
                        })
                    }

                    {/* <Card container className='Card' data-aos="fade-left" data-aos-duration="1000">
                        <ProdImg src={snack2} />
                        <NameProd >รสช็อกโกแลตกล้วยหอม</NameProd>
                        <Button variant="contained" sx={{ width: "80%", margin: "0 auto", borderRadius: "24px", color: "#FFF" }}>
                            สั่งซื้อ
                        </Button>
                        <Beanimg className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
                        <Beanimg1 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
                        <Beanimglong className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={beanlong} />
                        <Beanimg2 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />
                        <Beanimg3 className='Beanimg' sx={{ transition: "0.5s", opacity: "0" }} src={bean} />

                        <div style={{
                            position: "absolute",
                            top: "0",
                            height: "50%",
                            width: "100%",
                            backgroundColor: "#FFE448",
                            zIndex: "-1"
                        }}></div>

                        <div style={{
                            position: "absolute",
                            bottom: "0",
                            height: "50%",
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            zIndex: "-1"

                        }}></div>
                    </Card> */}


                </ItemContainer>
                <StarBottom src={star} className='rotatestar'  />
                <StarTop src={star} className='rotatestar'  />
            </ShopContainer>
            <Grid container data-aos="fade-up" data-aos-duration="1000" height={{ xs: "320px", sm: "500px", md: "750px" }}>
                {/* <img src={video3} style={{ width: "100%" }}  /> */}

                <ReactPlayer
                     controls
                    playIcon={<PlayArrowIcon />}
                    url='https://www.youtube.com/watch?v=N6LLAxceEO4&t=68s'
                    width={'100%'}
                    height={"100%"}

                />
            </Grid>
            <Grid container data-aos="fade-up" height={{ xs: "320px", sm: "500px", md: "750px" }}>
                {/* <img src={video4} style={{ width: "100%" }} data-aos="fade-right" data-aos-duration="1000" /> */}
                <ReactPlayer
                    controls
                    playIcon={<PlayArrowIcon />}
                    url='https://www.youtube.com/watch?v=9ccTP-nT6n0&t=12s'
                    width={'100%'}
                    height={"100%"}

                />
            </Grid>
            <Grid container height={{ xs: "320px", sm: "500px", md: "750px" }}>
                {/* <img src={video5} style={{ width: "100%" }} data-aos="fade-left" data-aos-duration="1000" /> */}
                <ReactPlayer
                    controls
                    playIcon={<PlayArrowIcon />}
                    url='https://www.youtube.com/watch?v=ua1tuD9H2jQ'
                    width={'100%'}
                    height={"100%"}
                />
            </Grid>
            <Grid container height={{ xs: "320px", sm: "500px", md: "750px" }}>
                {/* <img src={video5} style={{ width: "100%" }} data-aos="fade-left" data-aos-duration="1000" /> */}
                <ReactPlayer
                    controls
                    playIcon={<PlayArrowIcon />}
                    url='https://www.youtube.com/watch?v=qhkFBODStlI'
                    width={'100%'}
                    height={"100%"}
                />
            </Grid>
            <Grid container sx={{ backgroundColor: "#afdeee" }}>
                <Grid container justifyContent={"center"} sx={{ position: "relative" }}>
                    <Cup src={cup} data-aos="fade-up" data-aos-duration="4000" />
                    <Grid sx={{ position: "absolute", bottom: "0", backgroundColor: "#231f20", width: "100%", height: "21%", zIndex: "0" }}>
                    </Grid>
                    <TreeLeft src={tree} data-aos="fade-up-right" data-aos-duration="1000" />
                    <TreeRigth src={tree} data-aos="fade-up-left" data-aos-duration="1000" />
                    <Lungmai src={Langmai} data-aos="fade-up-left" data-aos-duration="1000" />
                </Grid>
            </Grid>
            <ReviewContainer>
                <Text90px sx={{ color: "#C900DA", margin: "0" }} className='Franchise' data-aos="fade-up" data-aos-duration="1000">
                    REVIEW
                </Text90px>
                <Grid container justifyContent={"space-around"} maxWidth={"1444px"} data-aos="fade-left" data-aos-duration="1000" margin={"24px auto"}>
                    <Swiper
                        // pagination={{
                        //     dynamicBullets: true,
                        // }}
                        loop={true}
                        autoplay={{
                            delay: 3,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                        }}
                        speed={3000}
                        modules={[Autoplay]}
                        className="mySwiper"
                        slidesPerView={3}
                        spaceBetween={20}
                        breakpoints={{
                            0: {
                                slidesPerView: 2,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            }
                        }}
                    >
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                    L**********n
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        รสชาติอร่อยทานง่าย
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        L**********n
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        ได้สินค้าแล้วลองชิม รสชาติดีทานง่ายไว้อุดหนุนอีกนะ
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        นิภาธร
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        อร่อยละลายง่าย
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        nuthsan4
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        รสชาติอร่อยกินแล้วมีแรงดีค่ะซื้อซ้ำมาซ้ำครั้งนี้ครั้งที่ 3 แล้ว รสช็อกโกแลตกล้วยก็อร่อยค่ะซื้อทั้ง 2 รสเลย
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        j*******7
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        อร่อยมาดค่ะ
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        farida
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        สินค้าถึงแล้วแต่ยังไม่ชิม
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        Noijang Att
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        อร่อยค่ะไม่หวานมาก
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        Pranod Saenmunkong
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        กลิ่นหอม กินง่าย ถ้าชอบรสเข้มข้นอาจจะเติมน้ำน้อยลงนิดหน่อย ราคาดีเลยเมื่อเทียบกับเจ้าอื่น มีแถมแก้วให้ด้วย...
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        dyhe1y465zep
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        ดี
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        Wilai
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        อร่อยดีคจ
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                        jaruwanmungkung47
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        ชอบ
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                    🐮
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        d
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>
                        <SwiperSlide>
                            <CardReview container spacing={0}>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <NameProd sx={{ textAlign: "center" }}>
                                    🐮
                                    </NameProd>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text20px>
                                        Thailand, Bangkok
                                    </Text20px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Text24px>
                                        d
                                    </Text24px>
                                </Grid>
                                <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                    <Rating
                                        name="simple-controlled"
                                        value={value2}
                                        onChange={(event, newValue) => {
                                            setValue2(newValue);
                                        }}
                                    />
                                </Grid>
                            </CardReview>
                        </SwiperSlide>

                        {/* add */}

                        {reviews?.map((rw:any)=>(
                        <SwiperSlide key={rw.id} >
                            <Review name={rw?.name} text={rw?.text} rate={rw?.rate}/>
                        </SwiperSlide>

                       ))  } 


                        {/* end add */}
                    </Swiper>
                </Grid>
                <BeanimgReview src={bean} data-aos="fade-left" data-aos-duration="1000" />
                <BeanfullimgReview src={beanlong} data-aos="fade-right" data-aos-duration="1000" />
                    <InsertReview fetchReview={fetchReview}/>


            </ReviewContainer>
            <AboutContainer container>
                <AboutUs container className='BGPaper'>
                    <Container>
                        <Text90px sx={{ color: "#FE89B5", margin: "0" }} className='Franchise' data-aos="fade-up" data-aos-duration="1000">
                            ABOUT US
                        </Text90px>
                        <Text24px data-aos="fade-up" sx={{ textAlign: 'left' }} data-aos-duration="1000">
                            Gauvain เครื่องดื่มโปรตีนถั่วลันเตาสีทองจากประเทศอเมริกา ที่มาพร้อมคุณสมบัติพิเศษมากมาย มีรายงานผลการศึกษาวิจัยถึงองค์ประกอบทางเคมีของถั่วลันเตา พบว่ามีสารสำคัญที่ออกฤทธิ์หลายชนิด อาทิเช่น Phytoestrogen, Zeaxanthin, lutein, lecithin, cabalamin, coumestrol และ purine เป็นต้น
                        </Text24px>
                        <Text24px data-aos="fade-up" sx={{ textAlign: 'left' }} data-aos-duration="1000">
                            นอกจากนี้ถั่วลันเตายังมีคุณค่าทางโภชนาการดังนี้  มีพลังงาน คาร์โบไฮเดรต น้ำตาล เส้นไย ไขมัน โปรตีน วิตามินเอ เบต้าแคโรทีน ลูทีน ซีแซนทีน วิตามินบี1 วิตามินบี2 วิตามินบี3 วิตามินบี6 วิตามินบี9 วิตามินซี วิตามินอี วิตามินเค ธาตุแคลเซียม ธาตุเหล็ก แมกนีเซียม แมงกานีส ฟอสฟอรัส โพแทสเซียม โซเดียม สังกะสี โปรตีนสูงช่วยให้คุณดูแลสร้างภูมิคุ้มกัน เส้นใยอาหารสูง ขับถ่ายคล่อง ไขมันต่ำ โซเดียมต่ำ และมีประสิทธิภาพผลลัพธ์ที่สูง . . .
                        </Text24px>
                        <Link to="/aboutus" style={{ textDecoration: 'none' }}>
                            <Text24px data-aos="fade-up" data-aos-duration="1000" sx={{ color: '#FE89B5', textAlign: 'left' }}>
                                อ่านเพิ่มเติม
                            </Text24px>
                        </Link>
                        <NameProd sx={{ color: "#FFB600" }}>
                            CONTACT US
                        </NameProd>
                        <Grid container gap={"1%"}>
                            {/* <Link to={`https://www.facebook.com/profile.php?id=61553343980477&locale=th_TH`} style={{ width: "100%", height: `100%` }}>
                                <Social src={facebook}  style={{ width: "5%", marginTop: '6px' }} />
                            </Link>
                            
                            <Social src={tiktk} />
                            <Social src={ig} />
                            <Social src={youtube} /> */}
                            <a target="_blank" href="https://www.facebook.com/profile.php?id=61553343980477&locale=th_TH" style={{ width: "14%",margin:"0 3px" }}>
                                <ImgIcon src={facebook} />
                            </a>
                            <a target="_blank" href="https://www.tiktok.com/@gauvain_thailand?_t=8lsHoFgrqs5&_r=1" style={{ width: "14%",margin:"0 3px" }}>
                                <ImgIcon src={tiktk} />
                            </a>
                            <a target="_blank" href="https://rb.gy/boug2q" style={{ width: "14%",margin:"0 3px" }}>
                                <ImgIcon src={ig} />
                            </a>
                            <a target="_blank" href="https://www.youtube.com/@Gauvain_official" style={{ width: "14%",margin:"0 3px" }}>
                                <ImgIcon src={youtube} />
                            </a>
                        </Grid>
                    </Container>
                </AboutUs>
                <ProductAboutImg src={ProductAbout} data-aos="fade-left" data-aos-duration="1000" />
            </AboutContainer>
            <ReviewVideoContainer container>
                <ReviewVideoCard data-aos="fade-up" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <p style={{ textAlign: "center" }}>
                        FOR A GOOD TIME FOLLOW
                    </p>
                    <H1 style={{ textAlign: "center", backgroundColor: "#000", color: "#fff" }}>
                        @GAUVAIN
                    </H1>
                </ReviewVideoCard>
                <ReviewVideoCard sx={{ marginTop: "10%", display: { xs: "block", sm: "block" } }} data-aos="fade-down" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    {/* <ReviewVideoImg
                        controls
                        playIcon={<PlayArrowIcon />}
                        url='https://www.tiktok.com/@gauvain.thailand/video/7348626999654583554?_r=1&_t=8iAk82sTjwQ'
                        width={'100%'}
                        height={"100%"}
                    /> */}
                </ReviewVideoCard>
                <ReviewVideoCard sx={{ display: { xs: "block", sm: "block" } }} data-aos="fade-up" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video2} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </ReviewVideoCard>

                <ReviewVideoCard sx={{ display: { xs: "block", sm: "block" } }} data-aos="fade-down" data-aos-duration="1300">
                    <p style={{ textAlign: "center" }}>
                        FOR A GOOD TIME FOLLOW
                    </p>
                    <H1 style={{ textAlign: "center", backgroundColor: "#000", color: "#fff" }}>
                        @GAUVAIN
                    </H1>
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video3} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </ReviewVideoCard>
            </ReviewVideoContainer>
            <ReviewVideoContainer container>
                <ReviewVideoCard data-aos="fade-up" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video4} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <p style={{ textAlign: "center" }}>
                        FOR A GOOD TIME FOLLOW
                    </p>
                    <H1 style={{ textAlign: "center", backgroundColor: "#000", color: "#fff" }}>
                        @GAUVAIN
                    </H1>
                </ReviewVideoCard>
                <ReviewVideoCard sx={{ marginTop: "10%", display: { xs: "block", sm: "block" } }} data-aos="fade-down" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video5} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    {/* <ReviewVideoImg
                        controls
                        playIcon={<PlayArrowIcon />}
                        url='https://www.tiktok.com/@gauvain.thailand/video/7348626999654583554?_r=1&_t=8iAk82sTjwQ'
                        width={'100%'}
                        height={"100%"}
                    /> */}
                </ReviewVideoCard>
                <ReviewVideoCard sx={{ display: { xs: "block", sm: "block" } }} data-aos="fade-up" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video6} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </ReviewVideoCard>

                <ReviewVideoCard sx={{ display: { xs: "block", sm: "block" } }} data-aos="fade-down" data-aos-duration="1300">
                    <p style={{ textAlign: "center" }}>
                        FOR A GOOD TIME FOLLOW
                    </p>
                    <H1 style={{ textAlign: "center", backgroundColor: "#000", color: "#fff" }}>
                        @GAUVAIN
                    </H1>
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video7} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </ReviewVideoCard>
            </ReviewVideoContainer>
            <ReviewVideoContainer container>
                <ReviewVideoCard data-aos="fade-up" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video8} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <p style={{ textAlign: "center" }}>
                        FOR A GOOD TIME FOLLOW
                    </p>
                    <H1 style={{ textAlign: "center", backgroundColor: "#000", color: "#fff" }}>
                        @GAUVAIN
                    </H1>
                </ReviewVideoCard>
                <ReviewVideoCard sx={{ marginTop: "10%", display: { xs: "block", sm: "block" } }} data-aos="fade-down" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video9} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    {/* <ReviewVideoImg
                        controls
                        playIcon={<PlayArrowIcon />}
                        url='https://www.tiktok.com/@gauvain.thailand/video/7348626999654583554?_r=1&_t=8iAk82sTjwQ'
                        width={'100%'}
                        height={"100%"}
                    /> */}
                </ReviewVideoCard>
                <ReviewVideoCard sx={{ display: { xs: "block", sm: "block" } }} data-aos="fade-up" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video10} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </ReviewVideoCard>

                <ReviewVideoCard sx={{ display: { xs: "block", sm: "block" } }} data-aos="fade-down" data-aos-duration="1300">
                    <p style={{ textAlign: "center" }}>
                        FOR A GOOD TIME FOLLOW
                    </p>
                    <H1 style={{ textAlign: "center", backgroundColor: "#000", color: "#fff" }}>
                        @GAUVAIN
                    </H1>
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video11} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </ReviewVideoCard>
            </ReviewVideoContainer>
            


            {/* เพิ่ม */}
            <ReviewVideoContainer container>
                <ReviewVideoCard data-aos="fade-up" data-aos-duration="1300">
                    {/* <ReviewVideoImg src={reviewvideo} /> */}
                    <video controls style={{ width: "100%" }}>
                        <source src={Video12} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <p style={{ textAlign: "center" }}>
                        FOR A GOOD TIME FOLLOW
                    </p>
                    <H1 style={{ textAlign: "center", backgroundColor: "#000", color: "#fff" }}>
                        @GAUVAIN
                    </H1>
                </ReviewVideoCard>
                
              
               

            </ReviewVideoContainer>
        </div >
    )
}