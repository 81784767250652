

import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  ReactNode,
} from "react";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../../../api/gauvain";

interface AdminContextType {
  isAdmin: boolean;
  fetchMe: () => Promise<void>;
  loading: boolean;
  saleData: any;
  fetchDataByMonth: (month: any, year: any) => Promise<any>;
  saleDataMonth: any;
  fetchOrders: () => Promise<void>;
  allOrders: any;
  fetchData: (year: number) => Promise<any>;
  fetchAllMembers: () => Promise<void>;
  allMembers: any;
  fetchDataByQuarter:any
  saleDataQuarter:any
  fetchDataByBetween:any
  saleDataBetween:any
  fetchShipping:any
  shipping:any
}

const AdminContext = createContext<AdminContextType | undefined>(undefined);

function AdminProvider({ children }: { children: ReactNode }) {
  const [isAdmin, setIsAdmin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [saleData, setSaleData] = useState([]);
  const [saleDataMonth, setSaleDataMonth] = useState();
  const [saleDataQuarter, setSaleDataQuarter] = useState();
  const [saleDataBetween, setSaleDataBetween] = useState();
  const [allOrders, setAllOrders] = useState([]);
  const [allMembers, setAllMembers] = useState([]);

  const [shipping, setShipping] = useState([]);
  const cookies = new Cookies();
  const api = new gauvainAPI();
  const token = cookies.get("access_token");

  const fetchMe = async () => {
    try {
      const response = await api.me(token);
      if (response?.data?.user?.role !== "admin") {
        setIsAdmin(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchData = async (year: number) => {
    try {
      const response = await api.dashboard(year);
      setSaleData(response.data.data);
      return response.data?.data
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDataByMonth = async (month: any, year: any) => {
    try {
      const response = await api.dashboardMonth({ month, year });
      setSaleDataMonth(response.data?.data);
      return response.data?.data
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDataByQuarter= async (quarter: any, year: any) => {
    try {
      const response = await api.dashboardQuarter({ quarter, year });
      setSaleDataQuarter(response.data?.data);
      return response.data?.data
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDataByBetween = async (startMonth: any, endMonth:any,year: any,endYear:any) => {
    try {

      const response = await api.dashboardBetween({ startMonth,endMonth, year,endYear });
      setSaleDataBetween(response.data?.data);
      return response.data?.data
    } catch (err) {
      console.error(err);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await api.order();
      setAllOrders(response.data.info);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAllMembers = async () => {
    try {
      const response = await api.getAllMembers(token);
      setAllMembers(response.data.allMembers);
    } catch (err) {
      console.error(err);
    }
  };

  
  const fetchShipping = async () => {
    try {
      const response = await api.getShipping(token);
      setShipping(response.data)
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await fetchMe();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <AdminContext.Provider
      value={{
        isAdmin,
        fetchMe,
        loading,
        saleData,
        fetchDataByMonth,
        saleDataMonth,
        fetchOrders,
        allOrders,
        fetchData,
        fetchAllMembers,
        allMembers,
        fetchDataByQuarter,
        saleDataQuarter,
        fetchDataByBetween,
        saleDataBetween,
        fetchShipping,
        shipping
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be used within an AdminProvider");
  }
  return context;
};

export { AdminProvider, useAdmin };
