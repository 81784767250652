import { Button, Grid, Typography, styled } from '@mui/material'
import React, { useEffect } from 'react'
import bg from '../../assets/images/bgProdDetail.png'
import snack from '../../assets/images/0ccb33d77590a63746bdaafae92018bc.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
type Props = {}
const Text64px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "24px",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "30px",

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "34px",

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "56px",

    },
    [theme.breakpoints.up("xl")]: {
        fontSize: "64px",

    },
    fontWeight: "500",
    padding: "12px 0",
    zIndex: "0"
}));
const BuyButton = styled(Button)(({ theme }) => ({
    fontWeight: "700",
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px",
        padding: "0",

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
        padding: "6px 0px",

    },
    border: "1px solid #B36E29",
    color: "#FFF",
    backgroundColor: "#FFB600",
    borderRadius: "24px",
    width: "50%"
}));
const Snack = styled("img")(({ theme }) => ({
    width: "45%",
}));

function BannerDetailBanana({ }: Props) {
    useEffect(()=>{
        AOS.init()
    },[])
    const navigate = useNavigate()
    return (
        <Grid container sx={
            {
                width: "100%",
                backgroundImage: `url(${bg})`,
                position: "relative",
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "150% 100%", sm: "100%" },
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                padding:"32px 0"
            }}>
            <Snack src={snack} className='dukdug' data-aos="fade-up-right" data-aos-duration="1500" />
            <Grid width={{xs:"45%",sm:"33%"}} container justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
                <Text64px sx={{ color: "#A78241",textAlign:"center" }}>
                    รสช็อกโกแลต
                    <br />
                    กล้วยหอม
                </Text64px>
                <BuyButton onClick={()=> navigate("/checkout")} variant="contained" data-aos="fade-up-right" data-aos-duration="1500">สั่งซ์้อ</BuyButton>
            </Grid>
        </Grid>
    )
}

export default BannerDetailBanana