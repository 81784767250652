import React, { useState } from "react";
import { Box, Button, styled, TextField,Typography, Grid, MenuItem, FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select } from "@mui/material";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../../../api/gauvain";
import { useAdmin } from "../context/AdminContextProvider";

const MainContainer = styled("main")(({ theme }) => ({
  width: "90%",
  margin: "0 auto",
  padding: "16px",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "8px",
  },
}));

export default function AccountDetail({ item, back }:{item:any,back:any}) {
  const { fetchAllMembers } = useAdmin();
  const cookies = new Cookies();
  const api = new gauvainAPI();
  const token = cookies.get("access_token");
  const [input, setInput] = useState(item);
  
  const [open, setOpen] = useState(false);

  const handleChange = (e:any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      await api.updateRoleMemberOnly(token, item.id, { role: input.role });
      fetchAllMembers();
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เรียบร้อยแล้ว",
        confirmButtonText: `ตกลง`,
        icon: `success`,
      });
     
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เกิดข้อผิดพลาด",
        confirmButtonText: `ตกลง`,
        icon: `error`,
      });
     
    }
  };

  return (
    <MainContainer>
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          variant="outlined"
          onClick={back}
          sx={{
            fontSize: "1rem",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "#1565c0",
              color: "white",
            },
          }}
        >
          Back
        </Button>
        <Typography variant="h6" sx={{ color: "#333", fontWeight: "bold" }}>
          ข้อมูลผู้ใช้
        </Typography>
      </Box>
  
      <Box
        sx={{
          border: "1px solid #d1d1d1",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="ชื่อ"
              variant="standard"
              value={input.name}
              onChange={handleChange}
              name="name"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{
                fontSize: "1rem",
                "& .MuiInputBase-root": {
                  transition: "border-color 0.3s",
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "2px solid #2979ff",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              variant="standard"
              value={input.email}
              onChange={handleChange}
              name="email"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{
                fontSize: "1rem",
                "& .MuiInputBase-root": {
                  transition: "border-color 0.3s",
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "2px solid #2979ff",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="ที่อยู่"
              variant="standard"
              value={input.address || "ไม่มีข้อมูล"}
              onChange={handleChange}
              name="address"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{
                fontSize: "1rem",
                "& .MuiInputBase-root": {
                  transition: "border-color 0.3s",
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "2px solid #2979ff",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="เบอร์โทรศัพท์"
              variant="standard"
              value={input.phone || "ไม่มีข้อมูล"}
              onChange={handleChange}
              name="phone"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{
                fontSize: "1rem",
                "& .MuiInputBase-root": {
                  transition: "border-color 0.3s",
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "2px solid #2979ff",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              value={input.role}
              onChange={handleChange}
              name="role"
              displayEmpty
              inputProps={{ "aria-label": "Role" }}
              sx={{
                fontSize: "1rem",
                "& .MuiSelect-select": {
                  transition: "color 0.3s",
                },
              }}
            >
              <MenuItem value={"user"}>User</MenuItem>
              <MenuItem value={"admin"}>Admin</MenuItem>
            </Select>
          </Grid>
        </Grid>
  
        <Box marginTop={4} display="flex" justifyContent="flex-end" gap={2}>
          <Button
            variant="outlined"
            onClick={() => {
              setInput(item);
            }}
            sx={{
              fontSize: "1rem",
              borderRadius: 2,
              transition: "color 0.3s, border-color 0.3s",
              "&:hover": {
                color: "#ff1744",
                borderColor: "#ff1744",
              },
            }}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
              fontSize: "1rem",
              borderRadius: 2,
              boxShadow: 2,
              transition: "background-color 0.3s, box-shadow 0.3s",
              "&:hover": {
                backgroundColor: "#1c54b2",
                boxShadow: 4,
              },
            }}
          >
            แก้ใขข้อมูล
          </Button>
        </Box>
      </Box>
    </Box>
  
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"ยืนยันการแก้ใขข้อมูล?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          หากคุณต้องการยืนยันการแก้ใขกดปุ่ม "ยืนยัน" หากไม่ต้องการกดปุ่ม "ยกเลิก".
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="error"
          sx={{ fontSize: "1rem", transition: "color 0.3s" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={() => { handleSubmit(); handleClose(); }}
          variant="contained"
          sx={{
            fontSize: "1rem",
            boxShadow: 2,
            bgcolor: "#2979ff",
            transition: "background-color 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#1565c0",
              boxShadow: 4,
            },
          }}
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  </MainContainer>
  
  );
}
