// import React, { useEffect } from 'react'

// import ExcelJS from "exceljs";
// import {
//   Box,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Button,
//   Paper,
// } from "@mui/material";
// import { useAdmin } from '../context/AdminContextProvider';

// export default function ReportAccount() {
//     const { fetchAllMembers, allMembers } = useAdmin();

//     useEffect(()=>{
//         fetchAllMembers()
//     },[])

//     const handleExportExcel = () => {
//         const workbook = new ExcelJS.Workbook();
//         const worksheet = workbook.addWorksheet("Summary");
    
//         // สร้างส่วนหัว
//         worksheet.mergeCells("A1:T1");
//         worksheet.getCell("A1").value = "ข้อมูลรายการขาย";
//         worksheet.getCell("A1").alignment = {
//           horizontal: "center",
//           vertical: "middle",
//         };
//         worksheet.getCell("A1").font = { size: 22, bold: true };
    
//         // สร้างส่วนหัวของตาราง
//         const headers = [
//             "ชื่อ",
//             "email",
//             "Role",
//             "สร้างเมื่อ",
//             "ที่อยู่",
//             "เบอร์โทรศัพท์",
//         ];
    
//         worksheet.addRow(headers);
//         worksheet.getRow(3).eachCell((cell) => {
//           cell.alignment = { vertical: "middle", horizontal: "center" };
//           cell.font = { bold: true };
//           cell.fill = {
//             type: "pattern",
//             pattern: "solid",
//             fgColor: { argb: "FFFFCC" },
//           };
//           cell.border = {
//             top: { style: "thin" },
//             left: { style: "thin" },
//             bottom: { style: "thin" },
//             right: { style: "thin" },
//           };
//         });
    
//         // เพิ่มข้อมูลในตาราง
//         allMembers.forEach((item: any) => {
//         //   const transformedOrder = transformOrderData(item.order);
//         item.forEach((orderItem: any) => {
//             worksheet.addRow([
//               "Gauvain", // คอลัมน์แรกเป็นค่าคงที่ 'Gauvain'
//               new Date(item.createdAt).toLocaleString(), // วันที่สร้างรายการ
//               "", // คอลัมน์ว่าง
//               "", // คอลัมน์ว่าง
//               "", // คอลัมน์ว่าง
//               "", // คอลัมน์ว่าง
//               "", // คอลัมน์ว่าง
//               "", // คอลัมน์ว่าง
//               "", // คอลัมน์ว่าง
//               item?.name, // ชื่อผู้รับ
//               item?.phone, // เบอร์โทรผู้รับ
//               item?.address, // ที่อยู่ผู้รับ
//               item?.total, // ราคารวม
//               "", // คอลัมน์ว่าง
//               "", // คอลัมน์ว่าง
//               "คลังหลัก", // คลังสินค้า
//               item?.status_payment ? "จ่ายแล้ว" : "ยังไม่จ่าย", // สถานะการจ่ายเงิน
//               item?.status_payment ? "stripe" : "เก็บปลายทาง", // วิธีการชำระเงิน
//               orderItem.product_code, // รหัสสินค้า
//               orderItem.quantity, // จำนวน
//               orderItem.total, // ราคารวม
//               item?.status_payment ? new Date(item.createdAt).toLocaleString() : "", // วันที่ชำระเงิน
//               "", // คอลัมน์ว่าง
//               item?.intent_id, // รหัส Intent
//             ]);
//           });
//         });
    
//         // กำหนดความกว้างของคอลัมน์
//         headers.forEach((_, index) => {
//           worksheet.getColumn(index + 1).width = 35;
//         });
    
//         // บันทึกไฟล์
//         const fileName = new Date().getTime();
//         workbook.xlsx.writeBuffer().then((data) => {
//           const blob = new Blob([data], {
//             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//           });
//           const url = window.URL.createObjectURL(blob);
//           const anchor = document.createElement("a");
//           anchor.href = url;
//           anchor.download = `order_${fileName}.xlsx`;
//           anchor.click();
//           window.URL.revokeObjectURL(url);
//         });
//       };
    
//       const headers = [
//         "ชื่อ",
//         "email",
//         "Role",
//         "สร้างเมื่อ",
//         "ที่อยู่",
//         "เบอร์โทรศัพท์",
//       ];
    
//       return (
//         <Box p={4}>
//           <Typography variant="h5" align="center" fontWeight="bold">
//             รายการขาย
//           </Typography>
    
//           <TableContainer component={Paper}>
//             <Table aria-label="Asset Registration Table">
//               <TableHead>
//                 <TableRow>
//                   {headers.map((header, index) => (
//                     <TableCell
//                       key={index}
//                       align="center"
//                       sx={{
//                         fontWeight: "bold",
//                         border: 1,
//                         textDecoration: "underline",
//                         minWidth: 240,
//                       }}
//                     >
//                       {header}
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {allMembers?.map((item: any, index: any) => {
//                    (
//                     <TableRow key={`${index}-${orderIndex}`}>
//                       <TableCell align="center" sx={{ border: 1 }}>
//                        {item.name}
//                       </TableCell>
//                       <TableCell align="center" sx={{ border: 1 }}>
//                       {item.email}
//                       </TableCell>
//                       <TableCell align="center" sx={{ border: 1 }}>
//                         {item.role}
//                       </TableCell>
//                       <TableCell align="center" sx={{ border: 1 }}>
//                         {new Date(item.createdAt).toLocaleString()}
//                       </TableCell>
//                       <TableCell align="center" sx={{ border: 1 }}>
//                         {item?.address}
//                       </TableCell>
//                       <TableCell align="center" sx={{ border: 1 }}>
//                       {item?.phone}
//                       </TableCell>
            
//                     </TableRow>
//                   ));
//                 })}
//               </TableBody>
//             </Table>
//           </TableContainer>
    
//           <Box textAlign="center" marginTop={4}>
//             <Button variant="contained" color="primary" onClick={handleExportExcel}>
//               ดาวน์โหลด Excel
//             </Button>
//           </Box>
//         </Box>
//       );
// }
import React, { useEffect } from 'react';
import ExcelJS from 'exceljs';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from '@mui/material';
import { useAdmin } from '../context/AdminContextProvider';

export default function ReportAccount() {
  const { fetchAllMembers, allMembers } = useAdmin();

  useEffect(() => {
    fetchAllMembers();
  }, []);

  const handleExportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Summary');

    // สร้างส่วนหัว
    worksheet.mergeCells('A1:F1');
    worksheet.getCell('A1').value = 'ข้อมูลสมาชิก';
    worksheet.getCell('A1').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.getCell('A1').font = { size: 22, bold: true };

    // สร้างส่วนหัวของตาราง
    const headers = ['ชื่อ', 'email', 'Role', 'สร้างเมื่อ', 'ที่อยู่', 'เบอร์โทรศัพท์'];
    worksheet.addRow(headers);
    worksheet.getRow(3).eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFCC' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    // เพิ่มข้อมูลในตาราง
    allMembers.forEach((item: any) => {
      worksheet.addRow([
        item.name,
        item.email,
        item.role,
        new Date(item.createdAt).toLocaleString(),
        item?.address,
        item?.phone,
      ]);
    });

    // กำหนดความกว้างของคอลัมน์
    headers.forEach((_, index) => {
      worksheet.getColumn(index + 1).width = 35;
    });

    // บันทึกไฟล์
    const fileName = new Date().getTime();
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `members_${fileName}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const headers = ['ชื่อ', 'email', 'Role', 'สร้างเมื่อ', 'ที่อยู่', 'เบอร์โทรศัพท์'];

  return (
    <Box p={4}>
      <Typography variant="h5" align="center" fontWeight="bold">
        รายการสมาชิก
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Member Table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align="center"
                  sx={{
                    fontWeight: 'bold',
                    border: 1,
                    textDecoration: 'underline',
                    minWidth: 240,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allMembers?.map((item: any, index: any) => (
              <TableRow key={index}>
                <TableCell align="center" sx={{ border: 1 }}>
                  {item.name}
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  {item.email}
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  {item.role}
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  {new Date(item.createdAt).toLocaleString()}
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  {item?.address}
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  {item?.phone}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box textAlign="center" marginTop={4}>
        <Button variant="contained" color="primary" onClick={handleExportExcel}>
          ดาวน์โหลด Excel
        </Button>
      </Box>
    </Box>
  );
}

