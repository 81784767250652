import React, { useEffect, useState } from "react";
import { Box, Button, styled } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Grid,
} from "@mui/material";
import { useAdmin } from "./context/AdminContextProvider";
import AccountDetail from "./component/AccountDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { gauvainAPI } from "../../api/gauvain";
import Swal from "sweetalert2";

const MainContainer = styled("main")({
  width: "98%",
  padding: "16px",
  margin: "0 auto",
  boxSizing: "border-box",

  "@media (max-width: 600px)": {
    padding: "8px",
    width: "100%",
  },
});

export default function LiveWeb() {
  interface YouTubeLiveProps {
    videoUrl: string;
  }
  const [URL, setURL] = useState("");
  const [URL2Show, setURL2Show] = useState("");
  const [cold, setCold] = useState(false);

  const extractVideoId = (url: string) => {
    // Regex to match either 'watch' or 'live' video IDs from YouTube URLs
    const regex =
      /(?:youtube\.com\/(?:watch\?v=|live\/)|youtu\.be\/)([^"&?\/\s]{11})/i;
    const match = url.match(regex);
    return match ? match[1] : "";
  };

  const changeURL = () => {
    Swal.fire("TEST LIVE");
    setURL2Show(URL);
  };

  const Live = async () => {
    try {
      const api = new gauvainAPI();
      const data = await api.changeURL({
        URL: URL,
      });
      if (data.data.data.status_code == 200) {
        Swal.fire("LIVE!!");
        getLive();
      } else {
        Swal.fire("ERROR");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getLive = async () => {
    try {
      const api = new gauvainAPI();
      const data = await api.getURL({});
      setURL(data.data.data);
      setURL2Show(data.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  if (cold === false) {
    setCold(true);
    getLive();
  }

  const YouTubeLive: React.FC<YouTubeLiveProps> = ({ videoUrl }) => {
    // Extract the video ID from the YouTube URL
    const id = extractVideoId(videoUrl);
    const embedUrl = `https://www.youtube.com/embed/${id}`;

    return (
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={embedUrl}
        title="YouTube Live Stream"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  const YouTubeLiveChat: React.FC<YouTubeLiveProps> = ({ videoUrl }) => {
    // Extract the video ID from the YouTube URL
    const id = extractVideoId(videoUrl);
    const chatUrl = `https://www.youtube.com/live_chat?v=${id}&embed_domain=${window.location.hostname}`;

    return (
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={chatUrl}
        title="YouTube Live Chat"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <MainContainer>
      <>
        <h3 style={{ padding: "4px", display: "flex", alignItems: "center" }}>
          LIVE
          <input
            type="text"
            onChange={(e) => setURL(e.target.value)}
            value={URL}
            placeholder="Link LIVE"
            style={{
              marginLeft: "10px",
              paddingInline: "10px",
              width: "70%",
              outline: "none",
              fontSize: 20,
              borderRadius: 10,
            }}
          />
          <button
            type="button"
            onClick={Live}
            style={{
              fontSize: "20px",
              background: "black",
              color: "white",
              paddingInline: "10px",
              borderRadius: 10,
              marginLeft: "5px",
            }}
          >
            LIVE !
          </button>
          <button
            type="button"
            onClick={changeURL}
            style={{
              fontSize: "20px",
              background: "black",
              color: "white",
              paddingInline: "10px",
              borderRadius: 10,
              marginLeft: "5px",
            }}
          >
            TEST
          </button>
        </h3>

        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFE757",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%",
            }}
          >
            <YouTubeLive videoUrl={URL2Show} />
          </div>
        </div>
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFE757",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%",
            }}
          >
            <YouTubeLiveChat videoUrl={URL2Show} />
          </div>
        </div>
      </>
    </MainContainer>
  );
}
