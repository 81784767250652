import React, { useEffect, useState } from "react";
import { Box, Button, styled } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

import Typography from "@mui/material/Typography";

import { useAdmin } from "./context/AdminContextProvider";

import ProductDetail from "./component/ProductDetail";
import AddProduct from "./component/AddProduct";
import AddDiscount from "./component/AddDiscount";

const MainContainer = styled("main")({
  width: "98%",
  padding: "16px",
  margin: "0 auto",
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    padding: "8px",
    width: "100%",
  },
});

const StyledTableContainer = styled<any>(TableContainer)({
  overflowX: "auto",
  minWidth: "700px",
  "@media (max-width: 600px)": {
    minWidth: "100%",
  },
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

export default function Products() {
  const { fetchOrders, allOrders } = useAdmin();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [togglePage, setTogglePagge] = useState(false);
  const [itemDetail, setItemDetail] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const handlePageOpen = () => setTogglePagge(true);
  const handlePageClose = () => setTogglePagge(false);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedRows = allOrders?.products?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };


  return (
    <MainContainer>
      {!togglePage && (
        <>
          <Box sx={{display:"flex" ,justifyContent:"space-between"}} >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingY={2}
              paddingX={4}
              marginY={2}
              sx={{
                maxWidth: 260,
                height: 20,
                fontSize: "1.8rem",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Typography>ตารางสินค้า</Typography>
            </Box>
            <Box sx={{ paddingTop: "10px",  }}>
              <Button variant="outlined" sx={{marginRight:4}} onClick={handleClickOpen}>
                เพิ่มสินค้า
              </Button>
              <Button variant="contained" onClick={handleClickOpen2}>
                Discount
              </Button>
            </Box>
          </Box>

          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableHead sx={{ bgcolor: "#d1d1d1" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                    width={140}
                  >
                    ชื่อสินค้า
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    ราคา
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    สร้างเมื่อ
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#235aad",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    รายละเอียด
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedRows?.map((order: any, index: number) => (
                  <TableRow
                    key={order.id}
                    sx={{
                      bgcolor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                      "&:hover": { bgcolor: "#f1f1f1" },
                    }}
                  >
                    <TableCell sx={{ textAlign: "center" }}>
                      {order.id}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {order.name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {order.price}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {new Date(order.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            paddingX: 2,
                            paddingY: 1,
                            cursor: "pointer",
                            width: 84,
                            borderRadius: 16,
                            bgcolor: "#235aad",
                            color: "white",
                            boxShadow: 1,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handlePageOpen();
                            setItemDetail(order);
                          }}
                        >
                          แสดง
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allOrders?.products?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledTableContainer>
        </>
      )}
      {togglePage && <ProductDetail item={itemDetail} back={handlePageClose} />}
      <AddProduct handleClose={handleClose} open={open} />
      <AddDiscount   handleClose={handleClose2} open={open2}/>
    </MainContainer>
  );
}
