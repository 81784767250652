import { Grid, TextField, styled } from '@mui/material';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import LOGO from '../../assets/images/LogoPNG.png'
import facebook from '../../assets/images/facebook.png'
import tiktok from '../../assets/images/tiktok.png'
import ig from '../../assets/images/ig.png'
import youtube from '../../assets/images/youtube.png'
import shoppee from '../../assets/images/shoppee.png'
import lazada from '../../assets/images/lazada.png'
import line from '../../assets/images/line.png'
import phone from '../../assets/images/phone.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Footer.css';

type Props = {}
const TopContainerFooter = styled(Grid)(({ theme }) => ({
    width: "100%",
    // scrollbarGutter:""
    justifyContent: "space-around",
    flexWrap: "nowrap",
    gap: "12px",
    backgroundColor: "#FFF"
}));
const Text20px = styled("p")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "14px"

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
    fontWeight: "800",
    textWrap: "nowrap",
    textAlign: "center"
}));
const MainContainerFooter = styled(Grid)(({ theme }) => ({
    width: "90%",
    margin: "0 auto",
    justifyContent: "space-between",
    maxWidth: "1440px"
}));
const SectionOne = styled(Grid)(({ theme }) => ({

    justifyContent: "space-between",
    [theme.breakpoints.up("xs")]: {
        flexDirection: "column-reverse",
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        width: "75%",
    },

}));
const SectionOnechildOne = styled(Grid)(({ theme }) => ({
    width: "45%",
    justifyContent: "space-between",
    flexWrap: "revert"
}));
const SectionOnechildTwo = styled(Grid)(({ theme }) => ({
    justifyContent: "space-between",
    flexWrap: "revert",
    marginTop: "12%",
    [theme.breakpoints.up("xs")]: {
        width: "80%",
        margin: "0 auto"
    },
    [theme.breakpoints.up("sm")]: {
        width: "53%",

    },

}));
const SectionOnechildTwoContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "28%",

    },
    [theme.breakpoints.up("sm")]: {
        width: "33%",
        marginTop: "20%"

    },
    [theme.breakpoints.up("md")]: {
        width: "28%",

    },
}));
const SectionTwo = styled(Grid)(({ theme }) => ({
    justifyContent: "space-between",
    [theme.breakpoints.up("xs")]: {
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "25%",
    },
}));
const Text16px = styled("p")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px"

    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"

    },
    [theme.breakpoints.up("md")]: {
        fontSize: "14px"

    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },
}));
const ImgIcon = styled("img")(({ theme }) => ({
    backgroundColor: "#FFF",
    borderRadius: "50%",
    width: "100%",
    cursor: "pointer"
}));
function Footer({ }: Props) {
    useEffect(() => {
        AOS.init()

    }, [])
    return (
        <footer style={{ overflow: "hidden", backgroundColor: "#FFF" }}>
            <Swiper
                className="sample-slider"
                modules={[Autoplay]}
                loop={true}
                autoplay={{
                    delay: 3,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                speed={3000}
                grabCursor={false}
                slidesPerView={3}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    600: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    }
                }}
            >
                <TopContainerFooter container>
                    <SwiperSlide>
                        <Text20px sx={{ color: '#FE89B5' }} >
                            PUSHING FLAVOR TO GAUVAIN.
                        </Text20px>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Text20px sx={{ color: '#F15C22' }} >
                            PUSHING FLAVOR TO GAUVAIN.
                        </Text20px>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Text20px sx={{ color: '#82C565' }}>
                            PUSHING FLAVOR TO GAUVAIN.
                        </Text20px>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Text20px sx={{ color: '#FFB600' }}>
                            PUSHING FLAVOR TO GAUVAIN.
                        </Text20px>
                    </SwiperSlide>

                </TopContainerFooter>
            </Swiper>
            <div style={{ backgroundColor: "#292526", padding: "34px 0" }}>
                <MainContainerFooter container>
                    <SectionOne container>
                        <SectionOnechildOne data-aos="fade-right" data-aos-duration="1500">
                            <Grid>
                                <img src={LOGO} width={"100%"} />
                            </Grid>
                            <Text16px sx={{ color: "#FFFFFF80" }} >
                                สถานที่ผลิต บริษัท ดีม่อน เฮลแคร์ จำกัด
                                9/533 หมู่ 14 ต.คลองหนึ่ง อ.คลองหลวง จ.ปทุมธานี
                            </Text16px>
                            <Text16px sx={{ color: "#FFFFFF80" }}>
                                จัดจำหน่ายโดย: บริษัท เลขที่ 2222 ขนมแลนด์ คอนเนคชั่น จำกัด
                                388 อาคาร อาคารเอ็กเซน ทาวเวอร์ ชั้นที่ 29 2901-2904 
                                ถนน สุขุมวิท แขวง คลองเตย เขต คลองเตย 
                                จังหวัด กรุงเทพมหานคร 10110
                            </Text16px>
                            {/* <Text16px sx={{ color: "#FFF" }}>
                                +61 3 8376 6284
                            </Text16px> */}
                            <Text16px sx={{ color: "#FFF" }}>
                                Gauvainthailand@gmail.com
                            </Text16px>
                        </SectionOnechildOne>
                        <SectionOnechildTwo container data-aos="fade-down" data-aos-duration="1500">
                            <SectionOnechildTwoContainer>
                                <Link to={`/shop`}>
                                    <Text20px sx={{ backgroundColor: "#FFB600", color: 'black', textDecoration: 'none' }}>
                                        SHOP
                                    </Text20px>
                                </Link>
                                <Link to={`/aboutus`}>
                                    <Text16px sx={{ color: "#FFF", display: { xs: "none", sm: "block" } }}>
                                        About Us
                                    </Text16px>
                                </Link>
                            </SectionOnechildTwoContainer >
                            <SectionOnechildTwoContainer>
                                <Link to={`/aboutus`}>
                                    <Text20px sx={{ backgroundColor: "#FFB600", color: 'black' }}>
                                        ABOUT US
                                    </Text20px>
                                </Link>
                                <Text16px sx={{ color: "#FFF", display: { xs: "none", sm: "block" } }}>
                                    GAUVAIN
                                </Text16px>
                            </SectionOnechildTwoContainer >
                            <SectionOnechildTwoContainer>
                                <Link to={`/contactus`}>
                                    <Text20px sx={{ backgroundColor: "#FFB600", color: 'black' }}>
                                        NEED HELP
                                    </Text20px>
                                </Link>
                                <Link to={`/contactus`}>
                                    <Text16px sx={{ color: "#FFF", display: { xs: "none", sm: "block" } }}>
                                        FAQ
                                    </Text16px>
                                </Link>
                                <Link to={`/contactus`}>
                                    <Text16px sx={{ color: "#FFF", display: { xs: "none", sm: "block" } }}>
                                        CONTACT US
                                    </Text16px>
                                </Link>
                            </SectionOnechildTwoContainer >
                        </SectionOnechildTwo>
                    </SectionOne>
                    <SectionTwo data-aos="fade-left" data-aos-duration="1500">
                        <Grid sx={{ marginTop: { xs: "0", sm: "25%" }, marginLeft: { xs: "0", sm: "12px" } }}>
                            <Text20px sx={{ color: "#FFF", textAlign: "left" }}>
                                Follow Gauvain
                            </Text20px>
                            <Text16px sx={{ color: "#FFF" }}>
                                Sign up for notes in your inbox
                            </Text16px>
                            <TextField
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                                sx={{ input: { color: "#FFF" } }}
                                label="Email"
                                variant="standard"
                            />
                            <Grid container gap={"6px"} margin={"6px 0"} >
                                <Grid container gap={"6px"} margin={"6px 0"}>
                                    <a target="_blank" href="https://rb.gy/boug2q" style={{ width: "12%",margin:"0 3px" }}>
                                        <ImgIcon src={ig} />
                                    </a>
                                    <a target="_blank" href="https://www.tiktok.com/@gauvain_thailand?_t=8lsHoFgrqs5&_r=1" style={{ width: "12%",margin:"0 3px" }}>
                                        <ImgIcon src={tiktok} />
                                    </a>
                                    <a target="_blank" href="https://bit.ly/49eXgFr" style={{ width: "12%",margin:"0 3px" }}>
                                        <ImgIcon src={shoppee} />
                                    </a>
                                    <a target="_blank" href="" style={{ width: "12%",margin:"0 3px" }}>
                                        <ImgIcon src={lazada} />
                                    </a>
                                </Grid>
                                <Grid container gap={"6px"} margin={"6px 0"}>
                                    <a target="_blank" href="https://www.facebook.com/profile.php?id=61553343980477&locale=th_TH" style={{ width: "12%",margin:"0 3px" }}>
                                        <ImgIcon src={facebook} />
                                    </a>
                                    <a target="_blank" href="https://www.youtube.com/@Gauvain_official" style={{ width: "12%",margin:"0 3px" }}>
                                        <ImgIcon src={youtube} />
                                    </a>
                                    <a target="_blank" href="https://line.me/ti/p/%40gauvainthailand" style={{ width: "12%",margin:"0 3px" }}>
                                        <ImgIcon src={line} />
                                    </a>
                                    <a target="_blank" href="" style={{ width: "12%",margin:"0 3px" }}>
                                        <ImgIcon src={phone} />
                                    </a>
                                </Grid>

                            </Grid>
                        </Grid>
                    </SectionTwo>
                </MainContainerFooter>
            </div>
            <Grid sx={{ backgroundColor: "#FFB600" }}>
                <Text16px sx={{ margin: "0", textAlign: "center", padding: "6px 0" }}>
                    © 2024 Gauvain. All rights reserved.
                </Text16px>
            </Grid>
        </footer>
    )
}

export default Footer