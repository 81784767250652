import axios, { AxiosInstance, AxiosResponse } from "axios";

export class gauvainAPI {
  private readonly baseURL: any;
  private readonly instance: AxiosInstance;
  public response: any;

  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL;
    this.response = {};
    this.instance = axios.create({
      baseURL: this.baseURL,
      timeout: 30000,
      timeoutErrorMessage: "Time out !",
    });
  }

  getBaseURL = () => {
    return this.baseURL;
  };

  get = async (path: string, config: any) => {
    return this.instance.get(path, config).then((res) => {
      return res;
    });
  };

  post = async (path: string, body: any, config: any) => {
    return this.instance.post(path, body, config).then((res) => {
      return res;
    });
  };

  delete = async (path: string, config: any) => {
    return this.instance.delete(path, config).then((res) => {
      return res;
    });
  };

  patch = async (path: string, body: any, config: any) => {
    return this.instance.patch(path, body, config).then((res) => {
      return res;
    });
  };

  getAllProducts = async () => {
    this.response = await this.instance.get(`/products/get`, {});

    return this.response;
  };

  getAllBank = async () => {
    this.response = await this.instance.get(`/bank/get`, {});

    return this.response;
  };

  register = async (data: any) => {
    this.response = await this.instance.post(
      `/members/register`,
      {
        name: data.name,
        password: data.password,
        email: data.email,
      },
      {}
    );

    return this.response;
  };

  login = async (data: any) => {
    this.response = await this.instance.post(
      `/members/login`,
      {
        email: data.email,
        password: data.password,
        remember: data.remember,
      },
      {}
    );

    return this.response;
  };

  getAddress = async (access_token: string) => {
    this.response = await this.instance.get(`/members/get/address`, {
      headers: {
        Authorization: access_token,
      },
    });

    return this.response;
  };

  addAddress = async (access_token: string, address: any, phone: string) => {
    const temp_address = `${address.name} ${address.address} ${address.alley} ${address.road} ${address.subdistrict} ${address.district} ${address.province} ${address.zipcode}`;

    this.response = await this.instance.post(
      `/members/add/address`,
      {
        address: temp_address,
        phone: phone,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );

    return this.response;
  };

  removeAddress = async (access_token: string, address_id: any) => {
    this.response = await this.instance.post(
      `/members/remove/address`,
      {
        id: address_id,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );

    return this.response;
  };

  createOrder = async (access_token: string, data: any) => {
    this.response = await this.instance.post(`/order/`, data, {
      headers: {
        Authorization: access_token,
      },
    });

    return this.response;
  };

  createOrderIsCOD = async (access_token: string, data: any) => {
    this.response = await this.instance.post(`/order/isCOD`, data, {
      headers: {
        Authorization: access_token,
      },
    });

    return this.response;
  };
  me = async (access_token: string) => {
    this.response = await this.instance.get(`/members/me`, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response;
  };

  dashboard = async (year: number) => {
    this.response = await this.instance.post(`/dashboard`, { year });
    return this.response;
  };

  dashboardMonth = async (data: any) => {
    this.response = await this.instance.post(`/dashboard/month`, {
      month: data.month,
      year: data.year,
    });
    return this.response;
  };

  dashboardQuarter = async (data: any) => {
    this.response = await this.instance.post(`/dashboard/quarter`, {
      quarter: data.quarter,
      year: data.year,
    });
    return this.response;
  };

  dashboardBetween = async (data: any) => {
    this.response = await this.instance.post(`/dashboard/between`, {
      startMonth: data.startMonth,
      endMonth: data.endMonth,
      year: data.year,
      endYear: data.endYear,
    });

    return this.response;
  };

  order = async () => {
    this.response = await this.instance.get("/order");
    return this.response;
  };

  getOrderData = async (data: any, access_token: string) => {
    this.response = await this.instance.post("/order/data", data, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response;
  };

  getAllMembers = async (access_token: string) => {
    this.response = await this.instance.get(`/members/`, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response;
  };

  updateRoleMemberOnly = async (access_token: string, id: any, data: any) => {
    this.response = await this.instance.patch(`/members/${id}`, data, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response;
  };

  updateOrder = async (access_token: string, data: any) => {
    this.response = await this.instance.patch(
      `/order/${data[0]?.id}`,
      data[0],
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return this.response;
  };

  updateDiscount = async (access_token: string, data: any) => {
    this.response = await this.instance.patch(
      `/products/discount/${data?.id}`,
      data,
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return this.response;
  };

  updateProducts = async (access_token: string, id: any, data: any) => {
    this.response = await this.instance.patch(`/products/${id}`, data, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response;
  };

  createProduct = async (access_token: string, data: any) => {
    this.response = await this.instance.post(`/products/create`, data, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response;
  };

  createPayment = async (access_token: string, data: any) => {
    this.response = await this.instance.post(
      `/transaction/create-checkout-session`,
      data,
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return this.response;
  };

  getStatus = async (access_token: string, data: any) => {
    this.response = await this.instance.post(
      `/transaction/session-status?session_id=${data.sessionId}`,
      { orderId: data.orderId },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return this.response;
  };

  getURL = async (data: any) => {
    this.response = await this.instance.get(`/dashboard/getURL`);
    return this.response;
  };

  changeURL = async (data: any) => {
    this.response = await this.instance.post(`/dashboard/changeURL`, data, {});
    return this.response;
  };

  allReview = async () => {
    this.response = await this.instance.get(`/review`);
    return this.response;
  };

  createQuota = async (access_token: string) => {
    await this.instance.post('/review/add-quota',{hello:"4444"}, {
      headers: {
        Authorization: access_token,
      },
    });
  };

  createReview = async (access_token: string, data: any) => {
   this.response = await this.instance.post(`/review/create`, data, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response
  };

  checkReview = async (access_token: string) => {
   this.response = await this.instance.get(`/review/check`, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response
  };

  getShipping = async(access_token: string) => {
    this.response = await this.instance.get(`/shiping`, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response
  };

  createShipping = async(access_token: string,data:any) => {
    this.response = await this.instance.post(`/shiping`,data, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response
  };
  updateShipping = async(access_token: string,id:number) => {
    this.response = await this.instance.patch(`/shiping/${id}`,{data:id}, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response
  };
 deleteShipping = async(access_token: string,id:number) => {
    this.response = await this.instance.delete(`/shiping/${id}`, {
      headers: {
        Authorization: access_token,
      },
    });
    return this.response
  };
  
  
}
