import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Navbar from "./layouts/Navbar";
import Header from "./layouts/Header";
import Navbar2 from "./layouts/navbar2/Navbar2";
import Footer from "./layouts/footer/Footer";

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Inter", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      light: "#FEB34F",
      main: "#FFB600",
    },
    secondary: {
      light: "#828282",
      main: "#181C2E",
      dark: "#000000",
    },
    warning: {
      main: "#FFB802",
    },
    success: {
      main: "#38DF67",
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: "#FEB34F",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#FEB34F",
        },
      },
    },
  },
});

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [open, setOpen] = React.useState<boolean>(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Navbar2 />
      <main>
        {/* <Container sx={
          {
            px: 0
          }
        }>
        </Container> */}

        <Outlet></Outlet>
      </main>
      <Footer />
    </ThemeProvider>
  );
}
