import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Swal from 'sweetalert2';
import { gauvainAPI } from "../../api/gauvain";

// MUI
import { Box, Divider, Modal, Typography, OutlinedInput, Grid, TextField, InputAdornment, Button, IconButton } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 1200,
    backgroundColor: 'white',
    borderRadius: 2,
    p: 3,
    maxHeight: '90dvh',
    overflow: 'hidden',
    overflowY: 'scroll'
};

const ModalAddress = (props:any) => {
    const cookies = new Cookies(null, { path: '/' });
    const [address, setAddress] = useState<any>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [loaded, setLoaded] = useState(false);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        setIsOpen(props.isOpenModal);
        reloadAddress();
    }, [props.isOpenModal]);

    const reloadAddress = async () => {
        setLoaded(false);

        try {
            const api = new gauvainAPI();
            const getAddress = await api.getAddress(cookies.get('access_token'));

            if(getAddress.data.status_code !== undefined && getAddress.data.status_code === 200)
            {
                setAddress(getAddress.data.address);
                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleClose = () => {
        props.setIsOpenModal(false);
        setLoaded(false);
    }

    const handleRemoveAddress = async (address_id:any) => {
        try {
            const api = new gauvainAPI();
            const removeAddress = await api.removeAddress(cookies.get('access_token'), address_id);

            if(removeAddress.data.status_code !== undefined && removeAddress.data.status_code === 200)
            {
                reloadAddress();
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {
                loaded ? <Box sx={
                    {
                        ...style,
                        color: 'black'
                    }
                }>
                    <Box display={`flex`}>
                        <Box width={`100%`} height={`100%`} display={`flex`} justifyContent={`flex-start`} alignItems={`center`} mb={1}>
                            <Typography variant="h5">
                                จัดการที่อยู่
                            </Typography>
                        </Box>
                        <Box width={`100%`} height={`100%`} display={`flex`} justifyContent={`flex-end`} alignItems={`center`} mb={1}>
                            <Button sx={
                                {
                                    backgroundColor: `#40baab`,
                                    color: `white`,
                                    px: 3,
                                    '&:hover': {
                                        backgroundColor: `#2e867c`
                                    }
                                }
                            } onClick={() => {
                                handleClose();
                                props.setIsOpenModalAddAddress(true);
                            }}>
                                เพิ่มที่อยู่ใหม่
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <Grid container mt={2} component={`form`} spacing={2}>
                        {
                            address.length > 0 ? address.map((data:any, index:number) => {
                                return (
                                    <>
                                        <Grid item xs={12} sm={12} md={10} lg={10}>
                                            <Typography fontWeight={`bold`}>
                                                { data.address }
                                            </Typography>
                                            <Typography>
                                                { data.phone }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <Box display={`flex`} justifyContent={`flex-end`} alignItems={`center`}>
                                                <IconButton sx={
                                                    {
                                                        mx: .5,
                                                        backgroundColor: '#3bac9f',
                                                        color: 'white',
                                                        borderRadius: 2,
                                                        '&:hover': {
                                                            backgroundColor: '#2e867c'
                                                        }
                                                    }
                                                } onClick={() => {
                                                    handleClose();
                                                    props.setCurrentAddress(`${data.address} (${data.phone})`);
                                                }}>
                                                    <CheckIcon />
                                                </IconButton>
                                                <IconButton sx={
                                                    {
                                                        mx: .5,
                                                        backgroundColor: '#FA4343',
                                                        color: 'white',
                                                        borderRadius: 2,
                                                        '&:hover': {
                                                            backgroundColor: '#c63535'
                                                        }
                                                    }
                                                } onClick={() => {
                                                    handleRemoveAddress(data.id);
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </>
                                )
                            }) : <Box width={`100%`}>
                                <Typography display={`flex`} justifyContent={`center`}>
                                    ไม่พบที่อยู่ของคุณ
                                </Typography>
                            </Box>
                        }
                    </Grid>
                </Box> : <Box sx={
                    {
                        ...style,
                        color: 'black'
                    }
                }>
                    <Typography display={`flex`} justifyContent={`center`}>
                        <CircularProgress />
                    </Typography>
                </Box>
            }
        </Modal>
    )
}

export default ModalAddress;