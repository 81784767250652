import React from "react";
import ExcelJS from "exceljs";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from "@mui/material";

export default function TemplateExcelOrder({ data }: { data: any }) {
  const transformOrderData = (order: string) => {
    const orderArray = JSON.parse(order);
    return orderArray.map((item: any) => {
      const product_code = item.product_id === 1 ? "CF500" : "BC500";
      return {
        product_code,
        quantity: item.quantity,
        total: item.total,
      };
    });
  };

  const handleExportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Summary");

    // Header
    worksheet.mergeCells("A1:T1");
    worksheet.getCell("A1").value = "ข้อมูลรายการขาย";
    worksheet.getCell("A1").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheet.getCell("A1").font = { size: 22, bold: true };

    // Table Headers
    const headers = [
      "ช่องทางการขาย",
      "วันที่ขาย",
      "รายได้จาก platform",
      "ค่าส่งที่เรียกเก็บจากลูกค้า",
      "มูลค่ารวมก่อนภาษี",
      "ภาษีมูลค่าเพิ่ม",
      "วันส่งสินค้า",
      "tracking no",
      "ช่องทางจัดส่ง",
      "ชื่อผู้รับ",
      "เบอร์โทรผู้รับ",
      "ที่อยู่จัดส่ง",
      "มูลค่า",
      "tag",
      "สถานะรายการ",
      "คลังสาขา",
      "สถานะชำระเงิน",
      "ช่องทางชำระเงิน",
      "รหัสสินค้า",
      "จำนวน",
      "จำนวนเงินที่ชำระ",
      "วันที่ชำระ",
      "รหัสการชำระ",
      "เลขอ้างอิง Stripe",
    ];

    worksheet.addRow(headers);
    worksheet.getRow(3).eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFCC" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Add data rows
    data.forEach((item: any) => {
      const transformedOrder = transformOrderData(item.order);
      const saleDate = new Date(item.createdAt).toLocaleString("th-TH", {
        timeZone: "Asia/Bangkok",
      });
      const paymentDate = item?.status_payment ? saleDate : "";

      transformedOrder.forEach((orderItem: any, index: number) => {
        // Ensure each order item is uniquely processed
        if (index === 0) {
          worksheet.addRow([
            "Gauvain",
            saleDate,
            "", // รายได้จาก platform
            "", // ค่าส่งที่เรียกเก็บจากลูกค้า
            "", // มูลค่ารวมก่อนภาษี
            "", // ภาษีมูลค่าเพิ่ม
            "", // วันส่งสินค้า
            "", // tracking no
            "", // ช่องทางจัดส่ง
            item?.name,
            item?.phone,
            item?.address,
            item?.total,
            "", // tag
            "", // สถานะรายการ
            "คลังหลัก",
            item?.status_payment ? "จ่ายแล้ว" : "ยังไม่จ่าย",
            item?.status_payment ? "stripe" : "เก็บปลายทาง",
            orderItem.product_code,
            orderItem.quantity,
            orderItem.total,
            paymentDate,
            "", // รหัสการชำระ
            item?.intent_id,
          ]);
        } else {
          worksheet.addRow([
            "", // ช่องทางการขาย
            "", // วันที่ขาย
            "", // รายได้จาก platform
            "", // ค่าส่งที่เรียกเก็บจากลูกค้า
            "", // มูลค่ารวมก่อนภาษี
            "", // ภาษีมูลค่าเพิ่ม
            "", // วันส่งสินค้า
            "", // tracking no
            "", // ช่องทางจัดส่ง
            "", // ชื่อผู้รับ
            "", // เบอร์โทรผู้รับ
            "", // ที่อยู่จัดส่ง
            "", // มูลค่า
            "", // tag
            "", // สถานะรายการ
            "", // คลังสาขา
            "", // สถานะชำระเงิน
            "", // ช่องทางชำระเงิน
            orderItem.product_code,
            orderItem.quantity,
            orderItem.total,
            "", // วันที่ชำระ
            "", // รหัสการชำระ
            "", // เลขอ้างอิง Stripe
          ]);
        }
      });
    });

    // Column width
    headers.forEach((_, index) => {
      worksheet.getColumn(index + 1).width = 35;
    });

    // Save file
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `order_${Date.now()}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const headers = [
    "ช่องทางการขาย",
    "วันที่ขาย",
    "รายได้จาก platform",
    "ค่าส่งที่เรียกเก็บจากลูกค้า",
    "มูลค่ารวมก่อนภาษี",
    "ภาษีมูลค่าเพิ่ม",
    "วันส่งสินค้า",
    "tracking no",
    "ช่องทางจัดส่ง",
    "ชื่อผู้รับ",
    "เบอร์โทรผู้รับ",
    "ที่อยู่จัดส่ง",
    "มูลค่า",
    "tag",
    "สถานะรายการ",
    "คลังสาขา",
    "สถานะชำระเงิน",
    "ช่องทางชำระเงิน",
    "รหัสสินค้า",
    "จำนวน",
    "จำนวนเงินที่ชำระ",
    "วันที่ชำระ",
    "รหัสการชำระ",
    "เลขอ้างอิง Stripe",
  ];

  return (
    <Box p={4}>
      <Typography variant="h5" align="center" fontWeight="bold">
        รายการขาย
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Order Table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    border: 1,
                    textDecoration: "underline",
                    minWidth: 240,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item: any, index: number) => {
              const transformedOrder = transformOrderData(item.order);
            
              const saleDate = new Date(item.createdAt).toLocaleString(
                "th-TH",
                {
                  timeZone: "Asia/Bangkok",
                }
              );
              const paymentDate = item?.status_payment ? saleDate : "";

              return (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ border: 1 }}>
                    Gauvain
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {saleDate}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item?.name}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item?.phone}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item?.address}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item?.total}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }}>
                    คลังหลัก
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item?.status_payment ? "จ่ายแล้ว" : "ยังไม่จ่าย"}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item?.status_payment
                      ? "stripe"
                      : item?.isCOD
                      ? "เก็บปลายทาง"
                      : null}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {transformedOrder
                      .map((orderItem: any) => orderItem.product_code)
                      .join(", ")}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {transformedOrder
                      .map((orderItem: any) => orderItem.quantity)
                      .join(", ")}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {transformedOrder
                      .map((orderItem: any) => orderItem.total)
                      .join(", ")}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }}>
                    {paymentDate}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 1 }} />
                  <TableCell align="center" sx={{ border: 1 }}>
                    {item?.intent_id}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box textAlign="center" marginTop={4}>
        <Button variant="contained" color="primary" onClick={handleExportExcel}>
          ดาวน์โหลด Excel
        </Button>
      </Box>
    </Box>
  );
}
