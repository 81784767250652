import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  styled,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../../../api/gauvain";

import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { useAdmin } from "../context/AdminContextProvider";

// Styled Components
const StyledTableContainer: any = styled(TableContainer)(({ theme }) => ({
  overflowX: "auto",
  minWidth: "500px",
  "@media (max-width: 600px)": {
    minWidth: "100%",
  },
}));

const StyledTable = styled(Table)({
  minWidth: 450,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiPaper-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(1),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: "80px",
  "&.active": {
    backgroundColor: "#235aad",
    color: "white",
    "&:hover": {
      backgroundColor: "#1e4b8a",
    },
  },
  "&.inactive": {
    borderColor: "#235aad",
    color: "#235aad",
    "&:hover": {
      backgroundColor: "rgba(35, 90, 173, 0.1)",
    },
  },
}));

export default function CreateExpress({
  handleClose,
  open,
}: {
  handleClose: any;
  open: any;
}) {
  const cookies = new Cookies();
  const api = new gauvainAPI();
  const token = cookies.get("access_token");

  const {fetchShipping} = useAdmin()

  const [input, setInput] = useState("");

  const onSubmit = async () => {
    try {
      await api.createShipping(token, { name: input });
      fetchShipping()
      Swal.fire({
        title: `แจ้งเตือน`,
        text: "เรียบร้อยแล้ว",
        confirmButtonText: `ตกลง`,
        icon: `success`,
      });
      setInput('')
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontSize: "1.25rem",
          fontWeight: "bold",
          color: "#333",
          backgroundColor: "#f7f7f7",
        }}
      >
        เพิ่มขนส่ง
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box display={"flex"} gap={2}>
          <TextField
            placeholder="ชื่อขนส่ง"
            size="small"
            value={input}
            onChange={(e: any) => setInput(e.target.value)}
          />
          <Button variant="contained" onClick={onSubmit}>เพิ่ม</Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            color: "#235aad",
            borderColor: "#235aad",
            "&:hover": {
              bgcolor: "rgba(35, 90, 173, 0.1)",
              borderColor: "#235aad",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
