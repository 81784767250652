import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { gauvainAPI } from "../../api/gauvain";

import CheckoutForm from "../payment/CheckoutForm";
import { useNavigate } from "react-router-dom";
import { usePayment } from "../../context/PaymentContextProvider";

const Payment = ({ buyProducts }: { buyProducts: any }) => {
  const { item } = usePayment();
  const { totalPrice } = usePayment();
  const [paymentList, setPaymentList] = useState<any>([]);
  const [selectedPaymentData, setSelectedPaymentData] = useState<any>({});
  const [selectedPaymentId, setSelectedPaymentId] = useState<any>(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      const temp_paymentList: any = [];
      const random_string = (length: number) => {
        let result = "";
        let counter = 0;

        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;

        while (counter < length) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
          counter += 1;
        }

        return result;
      };

      const api = new gauvainAPI();
      const bankData = await api.getAllBank();

      if (
        bankData.data.status_code !== undefined &&
        bankData.data.status_code === 200
      ) {
        await Promise.all(
          bankData.data.bank.map((pm: any, index: number) => {
            let temp_pm = pm;

            if (temp_pm.id === undefined) {
              pm.id = `${index + 1}${random_string(5)}${Math.floor(
                Math.random() * 9
              )}`;
            }

            if (index === 0) {
              setSelectedPaymentId(pm.id);
              setSelectedPaymentData(pm);
            }

            temp_paymentList.push(pm);
          })
        );

        setPaymentList(temp_paymentList);
      }
    })();
  }, []);

  const handleChangePayment = (payment_id: any) => {
    const findIndex = paymentList.findIndex(
      (element: any) => element.id === payment_id
    );
    if (findIndex === -1) {
      return;
    }

    setSelectedPaymentData(paymentList[findIndex]);
    setSelectedPaymentId(payment_id);
  };

  return (
    <Grid container pt={1}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          color: "black",
        }}
        mb={3}
      >
        <Typography
          fontSize={22}
          sx={{
            color: "black",
            textShadow:
              "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
            ml: 3,
            mb: 2,
          }}
        >
          วิธีการชำระเงิน
        </Typography>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: 3,
          }}
        >
          <Grid
            container
            py={4}
            sx={{
              px: {
                lg: 3,
                md: 3,
                sm: 0,
                xs: 0,
              },
            }}
          >
            {/* DESKTOP RESPONSIVE */}
            <Grid
              item
              md={12}
              lg={12}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            >
              {/* <Grid container>
                                {
                                    paymentList.length > 0 ? paymentList.map((payment:any, index:number) => {
                                        return (
                                            <Grid item xs={2} sm={2} md={2} lg={2} px={1} key={index}>
                                                <Button sx={
                                                    {
                                                        color: "white",
                                                        backgroundColor: selectedPaymentId === payment.id ? "#40baab" : "#b2b2b2",
                                                        "&:hover": {
                                                            backgroundColor: selectedPaymentId === payment.id ? "#2e867c" : "#7e7e7e",
                                                        }
                                                    }
                                                } fullWidth onClick={() => {
                                                    handleChangePayment(payment.id);
                                                }}>
                                                    {
                                                        payment.bank
                                                    }
                                                </Button>
                                            </Grid>
                                        )
                                    }) : null
                                }
                            </Grid> */}
            </Grid>
            {/* END RESKTOP RESPONSIVE */}

            {/* MOBILE RESPONSIVE */}
            {/* <Grid item xs={12} sm={12} sx={
                            {
                                display: {
                                    xs: 'block',
                                    sm: 'block',
                                    md: 'none',
                                    lg: 'none'
                                }
                            }
                        }>
                            <Grid container>
                                <Grid item xs={9} sm={8} px={3} mb={3}>
                                    {
                                        paymentList.length > 0 ? <Select value={selectedPaymentId} fullWidth sx={
                                            {
                                                backgroundColor: "#40baab",
                                                color: "white",
                                                
                                            }
                                        } variant="outlined" onChange={(e:any) => {
                                            handleChangePayment(e.target.value);
                                        }}>
                                            {
                                                paymentList.map((payment:any) => {
                                                    return (
                                                        <MenuItem value={`${payment.id}`} key={payment.id}>{payment.bank}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select> : null
                                    }
                                </Grid>
                            </Grid>
                            <Divider />
                        </Grid> */}
            {/* END MOBILE RESPONSIVE */}

            {/* {
                            selectedPaymentData.image_url !== undefined && selectedPaymentData.image_url !== null ? <>
                                <Grid item xs={12} sm={12} md={4} lg={4} mt={3}>
                                    {
                                        selectedPaymentData.bank !== undefined ? <>
                                            <Box display={`flex`} justifyContent={`center`}>
                                                <Typography sx={
                                                    {
                                                        display: {
                                                            lg: 'none',
                                                            md: 'none',
                                                            sm: 'block',
                                                            xs: 'block'
                                                        }
                                                    }
                                                } fontSize={20}>
                                                    {
                                                        selectedPaymentData.bank
                                                    }
                                                </Typography>
                                            </Box>
                                                <Box display={`flex`} justifyContent={`center`}>
                                                    <img src={selectedPaymentData.image_url} alt={selectedPaymentData.account_name} style={
                                                        {
                                                            maxWidth: "200px",
                                                            maxHeight: "200px"
                                                        }
                                                    } />
                                                </Box>
                                        </> : null
                                    }
                                </Grid>
                            </> : null
                        }  */}

            {/* <Grid item md={8} lg={8} mt={3} sx={
                                {
                                    display: {
                                        xs: 'none',
                                        sm: 'none',
                                        md: 'block',
                                        lg: 'block'
                                    }
                                }
                            }>
                                <Grid container>
                                    <Grid md={4} lg={4}>
                                        <Typography sx={{ color: "#727272" }} display={`flex`} justifyContent={`center`}>
                                            สาขา
                                        </Typography>
                                    </Grid>
                                    <Grid md={4} lg={4}>
                                        <Typography sx={{ color: "#727272" }} display={`flex`} justifyContent={`center`}>
                                            ชื่อบัญชี
                                        </Typography>
                                    </Grid>
                                    <Grid md={4} lg={4}>
                                        <Typography sx={{ color: "#727272" }} display={`flex`} justifyContent={`center`}>
                                            เลขที่บัญชี
                                        </Typography>
                                    </Grid>
                                    {
                                        selectedPaymentData.account_name !== undefined ? <>
                                            <Grid md={4} lg={4}>
                                                <Typography display={`flex`} justifyContent={`center`}>
                                                    {
                                                        selectedPaymentData.branch
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid md={4} lg={4}>
                                                <Typography display={`flex`} justifyContent={`center`}>
                                                    {
                                                        selectedPaymentData.account_name
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid md={4} lg={4}>
                                                <Typography display={`flex`} justifyContent={`center`}>
                                                    {
                                                        selectedPaymentData.account_number
                                                    }
                                                </Typography>
                                            </Grid>
                                        </> : null
                                    }
                                </Grid>
                            </Grid>
                             */}

            {/* MOBILE RESPONSIVE */}
            {/* <Grid xs={12} sm={12} mt={2} sx={
                            {
                                display: {
                                    xs: 'block',
                                    sm: 'block',
                                    md: 'none',
                                    lg: 'none'
                                }
                            }
                        }>

                            {
                                selectedPaymentData.name !== undefined ? <Grid container columnSpacing={1}>
                                    <Grid item xs={4} sm={4}>
                                        <Typography display={`flex`} justifyContent={`flex-end`} sx={
                                            {
                                                color: "#727272"
                                            }
                                        }>
                                            สาขา
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} sm={8}>
                                        <Typography display={`flex`} justifyContent={`center`} sx={
                                            {
                                                color: "#000000"
                                            }
                                        }>
                                            {
                                                selectedPaymentData.data.branch
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <Typography display={`flex`} justifyContent={`flex-end`} sx={
                                            {
                                                color: "#727272"
                                            }
                                        }>
                                            ชื่อบัญชี
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} sm={8}>
                                        <Typography display={`flex`} justifyContent={`center`} sx={
                                            {
                                                color: "#000000"
                                            }
                                        }>
                                            {
                                                selectedPaymentData.data.name
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <Typography display={`flex`} justifyContent={`flex-end`} sx={
                                            {
                                                color: "#727272"
                                            }
                                        }>
                                            เลขที่บัญชี
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} sm={8}>
                                        <Typography display={`flex`} justifyContent={`center`} sx={
                                            {
                                                color: "#000000"
                                            }
                                        }>
                                            {
                                                selectedPaymentData.data.number
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid> : null
                            }
                        </Grid> */}
            {/* END MOBILE RESPONSIVE */}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              flexDirection="column"
              gap={2}
              justifyContent="center"
              sx={{ padding: { xs: 2, sm: 3, md: 4, lg: 5 } }}
            >
              <Typography sx={{ fontSize: "0.875rem", color: "gray" }}>
                * จ่ายเงินทันที: สามารถชำระเงินผ่านบัตร Visa, Mastercard
                หรือช่องทางอื่นๆ รวมถึงการชำระผ่าน QR Code
              </Typography>
              <Typography sx={{ fontSize: "0.875rem", color: "gray" }}>
                * เก็บเงินปลายทาง: ลูกค้าจะชำระเงินเมื่อได้รับสินค้า
              </Typography>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                gap={4}
                justifyContent="center"
                alignItems="center"
                sx={{ padding: { xs: 2, sm: 3, md: 4, lg: 5 } }}
              >
                {totalPrice > 0 && (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 4,
                      color: "#FFF",
                      border: "solid 1px #b77737",
                      minWidth: { xs: 180, sm: 180, md: 220, lg: 220 },
                      "&:hover": {
                        border: "solid 1px #b77737",
                      },
                      padding: { xs: 1, sm: 1.5, md: 2 },
                    }}
                    onClick={() => buyProducts({ isCOD: false })}
                  >
                    <Typography
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        color: "black",
                        textShadow:
                          "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
                        height: 35,
                        minHeight: 35,
                        maxHeight: 35,
                      }}
                    >
                      จ่ายเงินทันที
                    </Typography>
                  </Button>
                )}

                {totalPrice > 0 && (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 4,
                      color: "#FFF",
                      border: "solid 1px #b77737",
                      bgcolor: "#2351a1",
                      minWidth: { xs: 180, sm: 180, md: 220, lg: 220 },
                      "&:hover": {
                        border: "solid 1px #b77737",
                        bgcolor: "#174491",
                      },
                      padding: { xs: 1, sm: 1.5, md: 2 },
                    }}
                    onClick={handleClickOpen}
                  >
                    <Typography
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        color: "black",
                        textShadow:
                          "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
                        height: 35,
                        minHeight: 35,
                        maxHeight: 35,
                      }}
                    >
                      เก็บเงินปลายทาง
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* confirm COD */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "12px",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "#f7f7f7",
            fontWeight: "bold",
            fontSize: "1.25rem",
            padding: "16px 24px",
          }}
        >
          ยืนยันการสั่งซื้อแบบเก็บเงินปลายทาง?
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "24px 32px" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "1.1rem", color: "#333" }}
          >
            รายการสินค้า
          </Typography>
          {item?.map((product: any, index: any) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",
                padding: "8px 0",
                borderBottom:
                  index < item.length - 1 ? "1px solid #ddd" : "none",
              }}
            >
              <Typography variant="body1" sx={{ color: "#555" }}>
                {product.name}
              </Typography>
              <Typography variant="body1" sx={{ color: "#555" }}>
                x {product.quantity}
              </Typography>
            </Box>
          ))}
          <Box
            sx={{
              borderTop: "1px solid #ddd",
              marginTop: "20px",
              paddingTop: "12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontSize: "1.1rem", color: "#000" }}
            >
              รวมยอด: {totalPrice?.toLocaleString()}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: "#f7f7f7", padding: "16px 24px" }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={handleClose}
            sx={{
              borderColor: "#f44336",
              color: "#f44336",
              "&:hover": { backgroundColor: "rgba(244, 67, 54, 0.1)" },
            }}
          >
            ยกเลิก
          </Button>
          <Button
            onClick={() => buyProducts({ isCOD: true })}
            sx={{
              backgroundColor: "#103da5",
              color: "#fff",
              fontWeight: "bold",
              padding: "6px 16px",
              "&:hover": { backgroundColor: "#0d358f" },
            }}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Payment;
