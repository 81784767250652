import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  styled,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { gauvainAPI } from "../../../api/gauvain";
import { useAdmin } from "../context/AdminContextProvider";

// Styled Components
const StyledTableContainer: any = styled(TableContainer)(({ theme }) => ({
  overflowX: "auto",
  minWidth: "500px",
  "@media (max-width: 600px)": {
    minWidth: "100%",
  },
}));

const StyledTable = styled(Table)({
  minWidth: 450,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiPaper-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(1),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: "80px",
  "&.active": {
    backgroundColor: "#235aad",
    color: "white",
    "&:hover": {
      backgroundColor: "#1e4b8a",
    },
  },
  "&.inactive": {
    borderColor: "#235aad",
    color: "#235aad",
    "&:hover": {
      backgroundColor: "rgba(35, 90, 173, 0.1)",
    },
  },
}));

const productPriceCF500 = [
  { quantity: 1, code: "CF500", price: 555 },
  { quantity: 2, code: "CF500", price: 1100 },
  { quantity: 3, code: "CF500", price: 1620 },
  { quantity: 4, code: "CF500", price: 2120 },
  { quantity: 5, code: "CF500", price: 2600 },
  { quantity: 6, code: "CF500", price: 3060 },
  { quantity: 7, code: "CF500", price: 3500 },
  { quantity: 8, code: "CF500", price: 3920 },
  { quantity: 9, code: "CF500", price: 4320 },
  { quantity: 10, code: "CF500", price: 4700 },
  { quantity: 30, code: "CF500", price: 13500 },
];
const productPriceBC500 = [
  { quantity: 1, code: "BC500", price: 555 },
  { quantity: 2, code: "BC500", price: 1100 },
  { quantity: 3, code: "BC500", price: 1620 },
  { quantity: 4, code: "BC500", price: 2120 },
  { quantity: 5, code: "BC500", price: 2600 },
  { quantity: 6, code: "BC500", price: 3060 },
  { quantity: 7, code: "BC500", price: 3500 },
  { quantity: 8, code: "BC500", price: 3920 },
  { quantity: 9, code: "BC500", price: 4320 },
  { quantity: 10, code: "BC500", price: 4700 },
  { quantity: 30, code: "BC500", price: 13500 },
];
export default function AddDiscount({
  handleClose,
  open,
}: {
  handleClose: any;
  open: any;
}) {
  const cookies = new Cookies();
  const api = new gauvainAPI();
  const token = cookies.get("access_token");
  const {
    fetchOrders,
    allOrders: { products },
  } = useAdmin();
  const [input, setInput] = useState({
    discount: products?.[0]?.discount || 0,
  });
  const [item, setItem] = useState(productPriceCF500);
  const [toggle, setToggle] = useState("BC500");

  const handleToggle = (code: any) => {
    const isBC500 = code === "BC500";
    setToggle(code);
    setItem(isBC500 ? productPriceBC500 : productPriceCF500);
    setInput({
      discount:
        code == "BC500"
          ? products?.[0]?.discount || 0
          : products?.[1]?.discount || 0,
    });
  };

  const handleSubmit = async () => {
    try {
      const id = toggle === "BC500" ? 1 : 2;
      await api.updateDiscount(token, { id, discount: input.discount });
      fetchOrders();
      Swal.fire("Success", "Discount updated successfully", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to update discount", "error");
    }
  };


  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontSize: "1.25rem",
          fontWeight: "bold",
          color: "#333",
          backgroundColor: "#f7f7f7",
        }}
      >
        Discount
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography sx={{ fontSize: "0.700rem" ,color:"gray" }}>
          * BC500 รสช็อกโกแลตกล้วยหอม
        </Typography>
        <Typography sx={{ fontSize: "0.700rem",color:"gray" }}>
          * CF500 รสกาแฟเอสเปรสโซ่
        </Typography>
        <Box sx={{marginTop:1}}>
          <Box
            sx={{
              border: "1px solid #b1b1b1",
              padding: 2,
              borderRadius: 4,
              marginBottom: 2,
              backgroundColor: "#fafafa",
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <StyledButton
              onClick={() => handleToggle("BC500")}
              className={toggle === "BC500" ? "active" : "inactive"}
            >
              BC500
            </StyledButton>
            <StyledButton
              onClick={() => handleToggle("CF500")}
              className={toggle === "CF500" ? "active" : "inactive"}
            >
              CF500
            </StyledButton>
            <TextField
              size="small"
              sx={{ width: "60px", marginRight: "12px" }}
              inputProps={{ style: { textAlign: "center" } }}
              placeholder="%"
              value={input.discount}
              onChange={(e) =>
                setInput({
                  ...input,
                  discount: parseFloat(e.target.value) || 0,
                })
              }
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                bgcolor: "#235aad",
                color: "white",
                "&:hover": {
                  bgcolor: "#1e4b8a",
                },
              }}
            >
              ยืนยัน
            </Button>
          </Box>
        </Box>

        <StyledTableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <StyledTable>
            <TableHead sx={{ bgcolor: "#d1d1d1" }}>
              <TableRow>
                {["จำนวน", "รหัสสินค้า", "ราคาที่ขาย", "ราคาพร้อมส่วนลด"].map(
                  (header) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: "#235aad",
                        textAlign: "center",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        wordWrap: "break-word",
                      }}
                    >
                      {header}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {item.map((pd, index) => (
                <TableRow
                  key={pd.quantity + pd.price}
                  sx={{
                    bgcolor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                    "&:hover": { bgcolor: "#f1f1f1" },
                  }}
                >
                  <TableCell
                    sx={{ textAlign: "center", wordWrap: "break-word" }}
                  >
                    {pd.quantity}
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", wordWrap: "break-word" }}
                  >
                    {pd.code}
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center", wordWrap: "break-word" }}
                  >
                    {pd.price.toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {(() => {
                      const discount = input.discount ?? 0;
                      const discountedPrice = Math.ceil(
                        (pd.price * (100 - discount)) / 100
                      );
                      return discountedPrice.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    })()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            color: "#235aad",
            borderColor: "#235aad",
            "&:hover": {
              bgcolor: "rgba(35, 90, 173, 0.1)",
              borderColor: "#235aad",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

